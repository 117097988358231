import {useMemo} from 'react';
import assert from 'assert-ts';
import {AgentSubType} from 'types';
import {AgentWorkSummary, DataLoadStatus} from 'api';
import {DeleteAgentFunc, SaveAgentFunc, useAgent} from 'services/data/agent';
import {AgentState} from 'services/data/agent/reducer/agentReducer';
import {AgentIdParam, NewAgentParam} from '../types';

export const useNewOrExistingAgent = ({
  agentId,
  agentType,
  suggestion,
}: NewAgentParam | AgentIdParam):
  | {
      /** Loaded agent, with limit works data */
      agent: AgentState;
      /** Loaded work summaries, possibly incremental (batched) */
      workSummaries?: DataLoadStatus<AgentWorkSummary[]>;
      agentType?: undefined;
      suggestion?: undefined;
      saveAgent: SaveAgentFunc;
      deleteAgent: DeleteAgentFunc;
    }
  | {
      agent?: undefined;
      workSummaries?: undefined;
      suggestion: string;
      agentType: AgentSubType;
      saveAgent: SaveAgentFunc;
      deleteAgent: DeleteAgentFunc;
    } => {
  const {
    agentEmptyWorks: existingAgent,
    workSummaries,
    saveAgent,
    deleteAgent,
  } = useAgent(agentId, 'full', 'mentionedIn');

  return useMemo(() => {
    if (agentId) {
      return {agent: existingAgent, workSummaries, saveAgent, deleteAgent};
    }

    return {
      agentType: assert(agentType, 'agentType is required'),
      workSummaries: undefined,
      suggestion: suggestion ?? '',
      saveAgent,
      deleteAgent,
    };
  }, [
    agentId,
    agentType,
    deleteAgent,
    existingAgent,
    saveAgent,
    suggestion,
    workSummaries,
  ]);
};
