import {DataFormPartConfiguration} from 'schema/types';
import {Part} from 'schemaDefinition/types';
import {useDataFormConfigurationContext} from '../contexts';

type Props = Partial<Pick<Part, 'type' | 'labelKey' | 'name'>>;

export const usePartConfiguration = (
  part: Props | undefined,
): DataFormPartConfiguration | undefined => {
  const {parts} = useDataFormConfigurationContext();

  if (!part || part.type === 'expand') {
    return undefined;
  }

  const partKey = part.labelKey ?? part.name;

  return partKey ? parts?.[partKey] : undefined;
};
