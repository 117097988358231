import {ReactElement, useCallback, useMemo} from 'react';
import {DataValue, Part} from 'schemaDefinition/types';
import {useTextValue} from 'schema/preview/hooks';
import {BaseFieldProps} from '../types';
import {formatNullValue} from '../../functions';
import {ValueLayout} from './ValueLayout';

type Props<TPart extends Part, TValue extends DataValue> = BaseFieldProps<
  TPart,
  TValue | TValue[]
> & {
  renderValue: (
    key: string | number,
    singleValue: TValue,
    part: TPart,
  ) => ReactElement;
};

export const MultiValue = <TPart extends Part, TValue extends DataValue>({
  part,
  value,
  layoutDirection,
  renderValue,
}: Props<TPart, TValue>) => {
  const values = useMemo(
    () => (Array.isArray(value) ? value : value ? [value] : []),
    [value],
  );
  const TextValue = useTextValue();

  const renderMultiValue = useCallback(() => {
    return (
      <>
        {values.length === 0 ? (
          <TextValue>{formatNullValue()}</TextValue>
        ) : (
          values.map((v, i) => renderValue(i, v, part))
        )}
      </>
    );
  }, [TextValue, part, renderValue, values]);

  return (
    <ValueLayout
      renderValue={renderMultiValue}
      part={part}
      layoutDirection={layoutDirection}
    />
  );
};
