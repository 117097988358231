import React, {useCallback, useMemo, useState} from 'react';
import {FieldError, PartInt} from 'schemaDefinition/types';
import {evaluateFieldRequired} from 'schemaDefinition/functions';
import {isNullish} from 'services/utils';
import {TextField} from 'components';
import {
  toFieldError,
  validateSingleIntValue,
} from 'schema/form/functions/validators';
import {BaseFieldProps} from './types';
import {useDataFormContext} from '../../contexts';

type Props = BaseFieldProps & {
  part: PartInt;
};

export const Int: React.FC<Props> = ({
  part,
  value,
  setFieldValue,
  valuePath,
  label,
  placeholder,
  width,
  maxWidth,
  flex,
  mode,
  showWhenReadonlyAndEmpty,
  localScope,
  globalScope,
  relatedScope,
}) => {
  const [visited, setVisited] = useState(false);
  const handleSetVisited = useCallback(() => setVisited(true), []);
  const {showErrors} = useDataFormContext();

  const handleIntChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newVal =
        e.target.value === ''
          ? null
          : isNullish(e.target.value)
            ? null
            : parseInt(e.target.value);
      setFieldValue(valuePath, newVal);
    },
    [valuePath, setFieldValue],
  );

  const required = useMemo(() => {
    return evaluateFieldRequired(
      part.required,
      valuePath,
      localScope,
      globalScope,
      relatedScope,
      value,
    );
  }, [globalScope, part.required, relatedScope, localScope, value, valuePath]);

  const error = useMemo((): FieldError => {
    if (showErrors || visited) {
      const valid = validateSingleIntValue(part, {
        value,
        valuePath,
        localScope,
        globalScope,
        relatedScope,
      }).valid;
      return toFieldError(valid);
    }
    return false;
  }, [
    globalScope,
    part,
    relatedScope,
    localScope,
    showErrors,
    value,
    valuePath,
    visited,
  ]);
  return (
    <TextField
      name={valuePath}
      label={label}
      placeholder={placeholder}
      width={width}
      maxWidth={maxWidth}
      flex={flex}
      readonly={mode === 'read-only'}
      showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
      type="number"
      required={required}
      value={value ?? ''}
      onChange={handleIntChange}
      onBlur={handleSetVisited}
      error={error}
    />
  );
};
