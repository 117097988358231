import {
  Part,
  Val,
  ValueRefType,
  ValueRefTypeGlobal,
  ValueRefTypeLocal,
  ValueRefTypeRelated,
} from '../types';
import {ValueVisitor} from './types';
import {isRef} from './isRef';
import {visitPartValues} from './visitSchemaValues';

export const getScopeDependencies = (
  part: Part | undefined,
): {local: boolean; global: boolean; related: boolean} => {
  if (!part) {
    return {
      local: false,
      global: false,
      related: false,
    };
  }
  let local = false;
  let global = false;
  let related = false;

  const partVisitor: ValueVisitor = (val: Val) => {
    if (isRef(val)) {
      const refType = val.$ref[0] as ValueRefType;
      switch (refType) {
        case ValueRefTypeLocal:
          local = true;
          break;
      }
    }
  };

  const relatedOrGlobalValueVisitor: ValueVisitor = (val: Val) => {
    if (isRef(val)) {
      const refType = val.$ref[0] as ValueRefType;
      switch (refType) {
        case ValueRefTypeGlobal:
          global = true;
          break;
        case ValueRefTypeRelated:
          related = true;
          break;
      }
    }
  };

  // TODO: consider explicit property on part schema if recursive is required
  /*
  const recursive =
    part.type === 'schema' &&
    // Recurse scope dependencies to be able to do aggregated validation
    (part.cardinality === 'multiple' || part.cardinality === '2');

   */
  visitPartValues(part, partVisitor, {scopes: [[]]}, 'single');
  visitPartValues(
    part,
    relatedOrGlobalValueVisitor,
    {scopes: [[]]},
    'recursive',
  );

  return {
    local,
    global,
    related,
  };
};
