import React, {useMemo} from 'react';
import {DataLoadStatus, ExternalSuggestions} from 'api/types';
import {formatDate, useLocalization} from 'localization';
import {useCodelist} from 'services/codeLists';
import {useAssertSavedManifestation} from 'services/data/metadata/hooks/useAssertSavedManifestation';
import {
  FlexBox,
  HtmlReadonly,
  Icon,
  ProductStatusIcon,
  useProductStatusTips,
} from 'components';
import {
  ManifestationSubPanelCard,
  ManifestationSubPanelItem,
  ManifestationSubPanelItemProps,
} from './ManifestationCard/components';
import {ManifestationPanelContainer} from './ManifestationPanelContainer';

type Props = {
  manifestationId: string;
  externalSuggestions: DataLoadStatus<ExternalSuggestions>;
  onClose: () => void;
};

export const InfoPanel: React.FC<Props> = ({
  manifestationId,
  externalSuggestions,
  onClose,
}) => {
  const {t} = useLocalization();
  const manifestation = useAssertSavedManifestation(manifestationId);
  const productStatusTips = useProductStatusTips();
  const publisherStatuses = useCodelist('PUBLISHER_STATUS');
  const distributorStatuses = useCodelist('DISTRIBUTOR_STATUS');

  const {metadata, suggestions, ddsData, marketingData} = useMemo(() => {
    const metadata: ManifestationSubPanelItemProps[] = [];
    if (manifestation.registered) {
      metadata.push({
        fieldName: 'registered',
        value: formatDate(manifestation.registered),
      });
    }

    const suggestions: ManifestationSubPanelItemProps[] = [];
    if (externalSuggestions.data?.intellectualLevel?.length) {
      suggestions.push({
        fieldName: 'intellectualLevel.suggestion',
        value: externalSuggestions.data?.intellectualLevel.join(', '),
      });
    }
    if (externalSuggestions.data?.seriesTitles?.length) {
      suggestions.push({
        fieldName: 'seriesTitles.suggestion',
        value: externalSuggestions.data?.seriesTitles.join(', '),
      });
    }
    if (externalSuggestions.data?.seriesNumbers?.length) {
      suggestions.push({
        fieldName: 'seriesNumbers.suggestion',
        value: externalSuggestions.data?.seriesNumbers.join(', '),
      });
    }

    const ddsData: ManifestationSubPanelItemProps[] = [];
    if (manifestation.ddsStatus?.length) {
      if (manifestation.ddsStatus.length === 1) {
        ddsData.push({
          fieldName: 'ddsStatus',
          value: t(
            `entity.manifestation.ddsStatus.${manifestation.ddsStatus[0].ddsPublishStatus}`,
          ),
        });
      } else {
        manifestation.ddsStatus.forEach(status => {
          ddsData.push({
            fieldName: 'ddsStatus',
            value: `${t(
              `entity.manifestation.ddsStatus.${status.ddsPublishStatus}`,
            )} (${status.productOwnerCode})`,
          });
        });
      }
    }

    const marketingData: ManifestationSubPanelItemProps[] = [];
    if (manifestation.products?.length) {
      manifestation.products.forEach(product => {
        marketingData.push({
          fieldName: 'productOwner',
          value: product.owner,
        });

        if (product.inSaleDate) {
          marketingData.push({
            fieldName: 'inSaleDate',
            value: formatDate(product.inSaleDate),
          });
        }

        const publisherStatus = product.publisherStatus
          ? publisherStatuses.codes.find(
              c => c.code === product.publisherStatus,
            )
          : undefined;
        if (publisherStatus) {
          marketingData.push({
            fieldName: 'publisherStatus',
            value: `${publisherStatus.code}: ${publisherStatus.value}`,
          });
        }

        if (product.publisherSalesDate) {
          marketingData.push({
            fieldName: 'publisherSalesDate',
            value: formatDate(product.publisherSalesDate),
          });
        }

        const distributorStatus = product.distributorStatus
          ? distributorStatuses.codes.find(
              c => c.code === product.distributorStatus,
            )
          : undefined;
        if (distributorStatus) {
          marketingData.push({
            fieldName: 'distributorStatus',
            value: `${distributorStatus.code}: ${distributorStatus.value}`,
          });
        }

        if (product.comingDate) {
          marketingData.push({
            fieldName: 'comingDate',
            value: formatDate(product.comingDate),
          });
        }

        if (product.status) {
          marketingData.push({
            fieldName: 'products.status',
            value: (
              <FlexBox horizontal left gap={0.5}>
                <ProductStatusIcon productStatus={product.status} />
                {productStatusTips[product.status]}
              </FlexBox>
            ),
          });
        }

        if (product.distributor) {
          marketingData.push({
            fieldName: 'products.distributor',
            value: product.distributor,
          });
        }

        if (product.hidden !== undefined) {
          const status = product.hidden
            ? t('form.global.type.bool.preview.true')
            : t('form.global.type.bool.preview.false');

          marketingData.push({
            fieldName: 'hidden',
            value: (
              <FlexBox horizontal left gap={0.5}>
                {product.hidden ? <Icon icon="Hidden" /> : null}
                {status}
              </FlexBox>
            ),
          });
        }

        if (product.measures?.length) {
          product.measures.forEach(m => {
            marketingData.push({
              fieldName: `products.measure.${m.type}`,
              value: `${m.value} ${
                m.unit
                  ? t(`form.global.field.products.measure.${m.unit}.label`)
                  : null
              }`,
            });
          });
        }
        if (product.descriptions) {
          product.descriptions.forEach(d => {
            marketingData.push({
              fieldName: `products.description.${
                ['nob', 'nor', 'eng'].includes(d.language) ? d.language : 'und'
              }`,
              value: <HtmlReadonly value={d.content} />,
            });
          });
        }
      });
    }

    return {
      metadata,
      suggestions,
      ddsData,
      marketingData,
    };
  }, [
    manifestation.registered,
    manifestation.ddsStatus,
    manifestation.products,
    externalSuggestions.data?.intellectualLevel,
    externalSuggestions.data?.seriesTitles,
    externalSuggestions.data?.seriesNumbers,
    t,
    publisherStatuses.codes,
    distributorStatuses.codes,
    productStatusTips,
  ]);

  return (
    <ManifestationPanelContainer
      title={t('page.metadata.manifestation.tools.info')}
      icon={'InfoCircle'}
      onClose={onClose}>
      <ManifestationSubPanelCard>
        {metadata.map(d => (
          <ManifestationSubPanelItem key={d.fieldName} {...d} />
        ))}
      </ManifestationSubPanelCard>
      <ManifestationSubPanelCard>
        {ddsData.map(d => (
          <ManifestationSubPanelItem key={d.fieldName} {...d} />
        ))}
      </ManifestationSubPanelCard>
      <ManifestationSubPanelCard>
        {ddsData.map(d => (
          <ManifestationSubPanelItem key={d.fieldName} {...d} />
        ))}
      </ManifestationSubPanelCard>

      <ManifestationSubPanelCard>
        {marketingData.map(m => (
          <ManifestationSubPanelItem key={m.fieldName} {...m} />
        ))}
      </ManifestationSubPanelCard>

      <ManifestationSubPanelCard>
        {suggestions.map(m => (
          <ManifestationSubPanelItem key={m.fieldName} {...m} />
        ))}
      </ManifestationSubPanelCard>
    </ManifestationPanelContainer>
  );
};
