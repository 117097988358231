import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {assert} from 'assert-ts';
import {CodeListMap} from 'api/types';
import {CodeListId} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {isLinkedRoleCodeListId} from 'schemaDefinition';
import {
  AllCodeListIdsScopeMap,
  LinkedRoleCodeListId,
} from 'schemaDefinition/types/linkTypes';
import {getLinkedRoleCodelist} from 'schemas/links/functions';
import {codelistsSelector} from '../selectors';

export function useCodelists(
  codelistIds: (CodeListId | LinkedRoleCodeListId)[],
): CodeListMap {
  const localization = useLocalization();
  const codelists = useSelector(codelistsSelector);
  return useMemo(
    () =>
      codelistIds.reduce<CodeListMap>((acc, id) => {
        if (AllCodeListIdsScopeMap[id] === 'local') {
          return acc;
        }

        if (isLinkedRoleCodeListId(id)) {
          const codelist = getLinkedRoleCodelist(id, localization);
          acc[id] = codelist;
          return acc;
        }

        const codelist = assert(
          codelists[id]?.data,
          `useCodelists: codelist ${id} not loaded`,
        );
        acc[id] = codelist;
        return acc;
      }, {}),
    [codelistIds, codelists, localization],
  );
}
