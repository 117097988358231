import {ManifestationV4} from 'api/types';
import {TypedSchemaModifier} from 'schemaDefinition/types';
import {GetDataBasedModifier} from 'services/data/metadata/types';
import {isNullish} from 'services/utils';
import {EditableBookGroupModifier} from '../manifestationSchema';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export const getManifestationBasedModifier: GetDataBasedModifier = data => {
  const savedManifestation = data as ManifestationV4;
  const modifiers: TypedSchemaModifier<ManifestationV4> = [];

  /**
   * If a saved manifestation already has bookgroup, it should not be editable
   */
  if (isNullish(savedManifestation.bookGroup)) {
    modifiers.push(EditableBookGroupModifier);
  }

  return modifiers.length > 0 ? modifiers : undefined;
};
