import {useMemo} from 'react';
import {Schema} from 'schemaDefinition/types';
import {ThesaurusId} from 'api';
import {visitSchemaParts} from 'schemaDefinition/functions';

export const useSchemaThesaurusIds = (
  schema: Schema | undefined,
): ThesaurusId[] => {
  return useMemo(() => {
    const thesaurusIds: ThesaurusId[] = [];

    if (!schema) {
      return [];
    }
    visitSchemaParts(schema, part => {
      switch (part.type) {
        case 'thesaurus': {
          if (!thesaurusIds.includes(part.thesaurusId)) {
            thesaurusIds.push(part.thesaurusId);
          }
          break;
        }
      }
    });

    return thesaurusIds;
  }, [schema]);
};
