import {useCallback} from 'react';
import {useLocalization} from 'localization';
import {Lock} from 'api';
import {acquireLock} from 'api/lock';
import {useGetTokens, useUser} from 'services/auth';
import {useSnacks} from 'components/snacks';
import {isLockedByOtherUser} from './isLockedByOtherUser';

type AcquireLock = (currentLock: Lock | undefined) => Promise<Lock | undefined>;
/**
 * Acquires lock held by other user
 */

export const useAcquireLockWithErrorHandling = (): AcquireLock => {
  const {t} = useLocalization();
  const user = useUser();
  const getTokens = useGetTokens();
  const {errorSnack} = useSnacks();

  return useCallback(
    (currentLock: Lock | undefined) => {
      if (currentLock && user && isLockedByOtherUser(currentLock, user)) {
        return acquireLock(currentLock.id, user.id, getTokens, false)
          .then(lock => {
            return lock;
          })
          .catch(() => {
            // If unlock fails, still allow editing with warning
            errorSnack(t('page.metadata.lock.message.lockFailed'));
            return undefined;
          });
      }

      return Promise.resolve(undefined);
    },
    [errorSnack, getTokens, t, user],
  );
};
