import React, {useCallback, useMemo} from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  SxProps,
  Theme,
  TypographyProps,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import {Code} from 'schemaDefinition/types';
import {ColorPalette, useImportantFont} from 'theme';
import {UXUnit} from 'theme/settings';
import {
  RadiobuttonCheckedIcon,
  RadiobuttonUncheckedIcon,
} from 'components/icons';
import {BaseFieldProps} from '../types';
import {Layout} from '../../layout';
import {FieldLabel} from '../FieldLabel';
import './CodeListField.scss';
import {getCodeLabel} from './functions';

type Props = BaseFieldProps & {
  name: string;
  id?: string;
  value?: string | null;
  options: Code[];
  showCode?: boolean;
  horizontal?: boolean;
  /**
   * Font variant to use for option labels, default label
   */
  variant?: 'button';
  onChange?: (value: string | null) => void;
  onDelete?: (value: string) => void;
  onBlur?: () => void;
};

const errorComponentProps = {
  typography: {
    color: ColorPalette.error,
  },
};

const warningComponentProps = {
  typography: {
    color: ColorPalette.warning,
  },
};

export const RadioButtonsField: React.FC<Props> = ({
  name,
  id,
  value,
  showCode,
  required = false,
  error,
  label,
  options,
  horizontal,
  variant,
  onChange,
  onBlur,
  flex,
  width,
  maxWidth,
}) => {
  console.log('RadioButtonsField', value);

  const sxGroup = useMemo(
    (): SxProps<Theme> | undefined =>
      horizontal
        ? {
            flexDirection: 'row',
            transform: `translateX(${UXUnit}px)`,
          }
        : undefined,
    [horizontal],
  );

  const sxCursorPointer: SxProps = {
    cursor: 'pointer',
  };
  const sxCursorNormal: SxProps = {
    cursor: 'auto',
  };

  const importantFont = useImportantFont(variant);
  const typographyProps = useMemo((): TypographyProps | undefined => {
    const errorProps =
      error === true
        ? errorComponentProps
        : error === 'warning'
          ? warningComponentProps
          : undefined;
    return errorProps && importantFont
      ? ({
          ...errorProps.typography,
          ...importantFont,
        } as TypographyProps)
      : importantFont
        ? (importantFont as TypographyProps)
        : errorProps;
  }, [error, importantFont]);

  const handleToggleValue = useCallback(
    (newValue: string) => {
      onChange && onChange(newValue === value ? null : newValue);
    },
    [onChange, value],
  );

  return (
    <Layout flex={flex} width={width} maxWidth={maxWidth}>
      {label && (
        <FieldLabel
          label={label}
          required={required}
          error={error}
          htmlFor={name}
        />
      )}
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        sx={sxGroup}
        value={value}
        onChange={(_, value) => handleToggleValue(value)}
        onBlur={onBlur}>
        {options.map(opt => (
          <FormControlLabel
            key={opt.code}
            value={opt.code}
            htmlFor={`${id}:${opt.code}`}
            sx={sxCursorNormal}
            control={
              <Radio
                icon={
                  error === true ? (
                    <RadiobuttonUncheckedIcon color="error" />
                  ) : error === 'warning' ? (
                    <RadiobuttonUncheckedIcon color="warning" />
                  ) : (
                    <RadiobuttonUncheckedIcon />
                  )
                }
                checkedIcon={<RadiobuttonCheckedIcon />}
                onKeyUp={e => {
                  if (e.code === 'Space') {
                    handleToggleValue(opt.code);
                    e.preventDefault();
                  }
                }}
              />
            }
            color={
              error === true
                ? 'error'
                : error === 'warning'
                  ? 'warning'
                  : undefined
            }
            label={
              <Typography
                variant={'body1'}
                onClick={() => handleToggleValue(opt.code)}
                sx={sxCursorPointer}
                {...typographyProps}>
                {getCodeLabel(opt, showCode)}
              </Typography>
            }
            componentsProps={{
              typography: typographyProps,
            }}
          />
        ))}
      </RadioGroup>
    </Layout>
  );
};
