import {ThesaurusNode} from 'api/types';
import {isSubcodeOf} from './isSubcodeOf';

export const getCategoryNode = (
  codeAnyCase: string,
  getDisplayCode: (node: ThesaurusNode) => string | undefined,
  thesaurusNode: ThesaurusNode,
  ancestors: ThesaurusNode[],
): [cateogoryRoot: ThesaurusNode, ancestors: ThesaurusNode[]] | undefined => {
  const code = codeAnyCase.toUpperCase();

  if (getDisplayCode(thesaurusNode)?.toUpperCase() === code) {
    return [thesaurusNode, ancestors];
  }

  for (const child of thesaurusNode.children ?? []) {
    const childCode = getDisplayCode(child)?.toUpperCase();
    if (childCode && isSubcodeOf(code, childCode)) {
      return getCategoryNode(code, getDisplayCode, child, [
        ...ancestors,
        thesaurusNode,
      ]);
    }
  }

  return undefined; // Not found
};
