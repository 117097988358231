import React from 'react';
import {Attempt} from 'services/receiveItems/types';
import {FlexBox, Icon} from 'components';

export const AttemptItem: React.FC<Attempt> = ({ean, error, reason}) => {
  return (
    <FlexBox horizontal alignItems={'flex-end'}>
      <Icon
        icon={error ? 'Close' : 'Check'}
        color={error ? 'error' : 'success'}
      />
      {ean} {reason ? <span>: {reason}</span> : null}
    </FlexBox>
  );
};
