import {assert} from 'assert-ts';
import {PartInt, PartLinkedLiterary, PartText} from 'schemaDefinition/types';
import {LinkedRoleCode} from 'schemaDefinition/types/linkTypes';

export const getLinkPropPartFromLinkedLiterary = (
  partLinkedLiterary: PartLinkedLiterary,
  linkedRoleCode: LinkedRoleCode,
): PartInt | PartText | undefined => {
  return {
    type: assert(
      linkedRoleCode.linkProperty?.type,
      'getLinkPropPartFromLinkedLiterary: type expected',
    ),
    name: assert(
      linkedRoleCode.linkProperty?.name,
      'getLinkPropPartFromLinkedLiterary: linkProperty.name expected',
      linkedRoleCode,
    ),
    required: partLinkedLiterary.linkPropRequired,
  };
};
