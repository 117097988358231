import {createContext, useCallback, useContext, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {GridValidRowModel} from '@mui/x-data-grid-pro';
import {assert} from 'assert-ts';
import {OverviewTask, TaskTypeAll} from 'api/types';
import {useLocalization} from 'localization';
import {HeadTitleParam} from 'services/head';
import {useTasksTodoLinks} from 'services/settings/hooks/useTasksTodoLinks';
import {OverviewTaskType} from '../types';
import {createTaskLink, getTaskLink} from '../functions';
import {useOverviewTasks} from '../hooks';

export type OverviewContextType = {
  loading: boolean;
  allTasks: OverviewTask[];
  filteredTasks: OverviewTask[];
  openManifestation: (task: OverviewTask) => void;
  taskTypes: OverviewTaskType[];
  selectedTaskType: TaskTypeAll;
  setSelectedTaskType: (taskType: TaskTypeAll) => void;
  searchText: string | undefined;
  setSearchText: (searchText: string) => void;
  loadingFailed: boolean;
  onRowsUpdate: (gridRows: GridValidRowModel[]) => void;
} & HeadTitleParam;

export const OverviewContext = createContext<OverviewContextType | undefined>(
  undefined,
);

export const useOverviewContextProviderValue = (): OverviewContextType => {
  const navigate = useNavigate();
  const {t} = useLocalization();
  const {
    allTasks,
    filteredTasks,
    taskTypes,
    selectedTaskType,
    setSelectedTaskType,
    searchText,
    setSearchText,
    loading,
    errorMsg,
  } = useOverviewTasks();

  const todoLinks = useTasksTodoLinks();
  const dispatch = useDispatch();

  const onRowsUpdate = useCallback(
    (gridRows: GridValidRowModel[]) => {
      const todoLinks = gridRows.map(g =>
        createTaskLink(g.isbn, g.workId, g.manifestationId, g.ean),
      );

      dispatch({
        type: 'SET_TASKS_TODOLINKS',
        payload: todoLinks,
      });
    },
    [dispatch],
  );

  const openManifestation = useCallback(
    (task: OverviewTask) => {
      const taskLink = getTaskLink(task);

      if (taskLink) {
        // Removes opened task from todolist.
        const index = todoLinks.findIndex(t => t === taskLink);
        if (index > -1) {
          todoLinks.splice(index, 1);

          dispatch({
            type: 'SET_TASKS_TODOLINKS',
            payload: [...todoLinks],
          });
        }
        navigate(taskLink);
      }
    },
    [dispatch, navigate, todoLinks],
  );

  return useMemo(
    () => ({
      title: t('appbar.overview'),
      loading,
      allTasks,
      filteredTasks,
      taskTypes,
      selectedTaskType,
      setSelectedTaskType,
      searchText,
      setSearchText,
      openManifestation,
      loadingFailed: !!errorMsg,
      onRowsUpdate,
    }),
    [
      t,
      loading,
      allTasks,
      filteredTasks,
      taskTypes,
      selectedTaskType,
      setSelectedTaskType,
      searchText,
      setSearchText,
      openManifestation,
      errorMsg,
      onRowsUpdate,
    ],
  );
};

export const useOverviewContext = (): OverviewContextType => {
  return assert(
    useContext(OverviewContext),
    'useOverviewContext: context expected',
  );
};
