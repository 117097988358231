import {assert} from 'assert-ts';
import {Concept} from 'types';
import {AgentWorkSummary, CodeListRef, RelationRoleDto} from 'api/types';
import {
  LinkedLiterary,
  VerifiedLinkedAgent,
  VerifiedLinkedCatalogPost,
  VerifiedLinkedLiterary,
} from 'schemaDefinition/types';
import {Schemas} from 'api/dto.generated';
import {
  isVerifiedLinkedAgent,
  isVerifiedLinkedCollection,
  isVerifiedLinkedWork,
} from 'schemaDefinition';
import {LinkRole, RoleEntityCode} from 'schemaDefinition/types/linkTypes';
import {isAgentSubType} from 'services/utils/functions';
import {isNullish} from 'services/utils/isNullish';
import {CatalogSchemas} from '../avro-catalog.generated';
import {mapAgentNameDto, mapToAgentNameDto} from './mapAgentNameDto';
import {
  mapTargetEntityTypeDto,
  mapToTargetEntityTypeDto,
} from './mapEntityType';
import {reverseMap} from './reverseMap';
import {sortLinks} from './sortLinks';
import {splitRoleEntityCode} from './splitEntityRoleEntityCode';

type WorkRelationsDto = Pick<
  Schemas.WorkBaseDto,
  'workRelations' | 'agentRelations' | 'seriesRelations'
>;

type WorkRelationsCatalogDto = Pick<
  CatalogSchemas.WorkRelationsDto,
  'seriesRelations'
>;

type AgentCorporationRelationsDto = Pick<
  Schemas.AgentCorporationDto,
  'agentCorporationRelations' | 'workRelations'
>;

type AgentPersonRelationsDto = Pick<
  Schemas.AgentPersonDto,
  'agentPersonRelations' | 'workRelations'
>;

export const mapWorkRelationsCatalogDto = (
  dto: WorkRelationsCatalogDto,
): VerifiedLinkedLiterary[] | undefined => {
  return mapWorkRelationsDto({
    workRelations: [],
    agentRelations: [],
    seriesRelations: dto.seriesRelations
      ? dto.seriesRelations.map(s => {
          return {
            entityId: assert(s.series?.id, 'Missing expected id', {dto}),
            role: 'PART_OF',
            type: assert(s.series?.type, 'Missing expected type', {
              dto,
            }) as Schemas.SeriesRelationDto['type'],
            title: s.series?.titles?.[0].value || '',
            numberInSeries: s.numberInSeries,
          };
        })
      : [],
  });
};

export const mapWorkRelationsDto = (
  dto: WorkRelationsDto,
): VerifiedLinkedLiterary[] | undefined => {
  const {workRelations = [], agentRelations = [], seriesRelations = []} = dto;

  const links =
    workRelations.length > 0 ||
    agentRelations.length > 0 ||
    seriesRelations.length > 0
      ? [
          ...seriesRelations.map(relDto => mapWorkToSeriesRelationDto(relDto)),
          ...workRelations.map(relDto => mapWorkToWorkRelationDto(relDto)),
          ...agentRelations.map(relDto =>
            mapWorkToAgentRelationDto(relDto, 'generalAgent'),
          ),
        ]
      : undefined;

  return sortLinks(links);
};

export const mapToWorkRelationsDto = (
  links: LinkedLiterary[] = [],
): WorkRelationsDto => {
  const workLinks = links.filter(isVerifiedLinkedWork);
  const collectionLinks = links.filter(isVerifiedLinkedCollection);
  const agentLinks = links.filter(isVerifiedLinkedAgent);

  const relations: WorkRelationsDto = {
    workRelations: workLinks.map(mapToWorkRelationDto),
    seriesRelations: collectionLinks.map(mapToSeriesRelationDto),
    agentRelations: agentLinks.map(mapToAgentRelationDto),
  };

  return relations;
};

type ManifestationRelationsDto = Pick<
  Schemas.ManifestationBaseDto,
  'seriesRelations'
>;
export const mapManifestationRelationsDto = (
  dto: ManifestationRelationsDto,
): LinkedLiterary[] | undefined => {
  const {seriesRelations = []} = dto;

  const links =
    seriesRelations.length > 0
      ? [...seriesRelations.map(relDto => mapWorkToSeriesRelationDto(relDto))]
      : [];

  return sortLinks(links);
};

export const mapToManifestationRelationsDto = (
  links: LinkedLiterary[] = [],
): ManifestationRelationsDto => {
  const collectionLinks = links as VerifiedLinkedCatalogPost[];

  const relations: ManifestationRelationsDto = {};

  if (collectionLinks.length > 0) {
    relations.seriesRelations = collectionLinks.map(mapToSeriesRelationDto);
  }

  return relations;
};

/**
 * AgentWorkRoleDto represents the opposite direction of the agents relation from a work.
 * Maps to AgentWorkSummary which is a partial summary of the work, used for the agent's work list.
 */
export const mapAgentWorksDto = (
  dtoArray: Schemas.AgentWorkRoleDto[] | undefined,
): AgentWorkSummary[] => {
  // Map AgentWorkRoleDto to partial summary (rest of work is loaded separately)
  return (dtoArray ?? []).map(dto => ({
    id: dto.entityId,
    roles: dto.roles,
    preferredTitle: [dto.workTitle],
    mainTitles: null, // Property required, but loaded separately
    created: -1, // Dummy value, property required but not used
    modified: -1, // Ditto
    type: CodeListRef.WORK_TYPE.Bok,
  }));
};

/**
 * AgentExpressionRoleDto represents the opposite direction of the agents relation from a expression.
 * Maps to AgentWorkSummary which is a partial summary of the work, used for the agent's work list.
 */
export const mapAgentExpressionsDto = (
  dtoArray: Schemas.AgentExpressionRoleDto[] | undefined,
): AgentWorkSummary[] => {
  // Map AgentExpressionRoleDto to partial summary (rest of work is loaded separately)
  return (dtoArray ?? []).map(dto => ({
    id: dto.workId,
    roles: dto.roles,
    mainTitles: null, // Property required, but loaded separately
    created: -1, // Dummy value, property required but not used
    modified: -1, // Ditto
    type: CodeListRef.WORK_TYPE.Bok,
  }));
};

export const mapAgentCorporationRelationsDto = (
  dto: AgentCorporationRelationsDto,
): LinkedLiterary[] | undefined => {
  const {agentCorporationRelations, workRelations} = dto;

  const links = [
    ...(agentCorporationRelations ?? []).map(relDto =>
      mapWorkToAgentRelationDto(relDto, 'concreteAgent'),
    ),
    ...(workRelations ?? []).map(relDto =>
      mapAgentWorkRelationDto(relDto, 'concreteAgent'),
    ),
  ];

  return sortLinks(links.length > 0 ? links : undefined);
};

export const mapToAgentCorporationRelationsDto = (
  links: LinkedLiterary[] = [],
): AgentCorporationRelationsDto => {
  const workLinks = links.filter(isVerifiedLinkedWork);
  const agentLinks = links.filter(isVerifiedLinkedAgent);

  const relations: AgentCorporationRelationsDto = {};

  if (workLinks.length > 0) {
    // TODO: Add when work relations are supported from Agent
    // relations.workRelations = workLinks.map(mapToWorkRelationDto);
  }

  if (agentLinks.length > 0) {
    relations.agentCorporationRelations = agentLinks.map(
      mapToAgentCorporationRelationDto,
    );
  }

  return relations;
};

export const mapAgentPersonRelationsDto = (
  dto: AgentPersonRelationsDto,
): LinkedLiterary[] | undefined => {
  const {agentPersonRelations, workRelations} = dto;

  const links = [
    ...(agentPersonRelations || []).map(relDto =>
      mapAgentPersonRelationDto(relDto, 'concreteAgent'),
    ),
    ...(workRelations ?? []).map(relDto =>
      mapAgentWorkRelationDto(relDto, 'concreteAgent'),
    ),
  ];

  return links.length > 0 ? sortLinks(links) : undefined;
};

export const mapToAgentPersonRelationsDto = (
  links: LinkedLiterary[] = [],
): AgentPersonRelationsDto => {
  const workLinks = links.filter(isVerifiedLinkedWork);
  const agentLinks = links.filter(isVerifiedLinkedAgent);

  const relations: AgentPersonRelationsDto = {};

  if (workLinks.length > 0) {
    // TODO: Add when work relations are supported from Agent
    // relations.workRelations = workLinks.map(mapToWorkRelationDto);
  }

  if (agentLinks.length > 0) {
    relations.agentPersonRelations = agentLinks.map(
      mapToAgentPersonRelationDto,
    );
  }

  return relations;
};

export const roleMap: {[key in RelationRoleDto]: LinkRole} = {
  PART_OF: 'partOf',
  PART_OF_COLLECTED_WORK: 'partOf',
  HAS_PART: 'contains',
  COLLECTED_WORK_FOR: 'contains',
  BASED_ON: 'basedOn',
  HAS_INSPIRED: 'hasInspired',
  MENTIONS: 'mentions',
  MENTIONED_IN: 'mentionedIn',
  RELATED: 'relatedTo',
  HAS_PSEUDONYM: 'hasPseudonym',
  PSEUDONYM_FOR: 'pseudonymFor',
};

type BaseRelationDto = Pick<
  | Schemas.WorkToWorkRelationDto
  | Schemas.AgentRelationDto
  | Schemas.SeriesRelationDto
  | Schemas.AgentPersonRelationDto
  | Schemas.AgentCorporationRelationDto,
  'entityId' | 'type' | 'role'
>;

type BaseLinkedLiterary = Pick<
  VerifiedLinkedCatalogPost | VerifiedLinkedAgent,
  'role'
> & {
  link: Pick<
    VerifiedLinkedCatalogPost['link'] | VerifiedLinkedAgent['link'],
    'entityId' | 'type' | 'linkStatus'
  >;
};

type TypeMapping = 'generalAgent' | 'concreteAgent';

export const mapBaseRelationDto = (
  dto: BaseRelationDto,
  typeMapping: TypeMapping = 'generalAgent',
): BaseLinkedLiterary => {
  const {role: dtoRole, type: dtoType, entityId} = dto;

  const role = roleMap[dtoRole];
  const targetType = mapTargetEntityTypeDto(dtoType);

  const linkRole: RoleEntityCode = `${role}.${
    // Handle generalized role for agent-links
    typeMapping === 'generalAgent' && isAgentSubType(targetType)
      ? Concept.agent
      : targetType
  }`;

  return {
    role: linkRole,
    link: {
      type: targetType,
      linkStatus: 'verified',
      entityId: assert(entityId, 'mapRelationDto: entityId expected'),
    },
  };
};

export const mapToBaseRelationDto = (
  baseLink: BaseLinkedLiterary,
  sourceType?: Concept,
): BaseRelationDto => {
  const {type: targetType, entityId} = baseLink.link;
  const {role} = splitRoleEntityCode(baseLink.role);

  const roleDto =
    // Specific handling PART_OF_COLLECTED_WORK and COLLECTED_WORK_FOR
    targetType === Concept.work &&
    sourceType == Concept.work &&
    (role === 'partOf' || role === 'contains')
      ? role === 'partOf'
        ? 'PART_OF_COLLECTED_WORK'
        : 'COLLECTED_WORK_FOR'
      : assert(
          reverseMap(role, roleMap),
          'mapToBaseRelationDto: unknown role',
          {role},
        );

  const targetTypeDto = mapToTargetEntityTypeDto(targetType);

  return {
    type: targetTypeDto,
    role: roleDto,
    entityId,
  };
};

export const mapWorkToSeriesRelationDto = ({
  title,
  numberInSeries,
  allTitles,
  ...dto
}: Schemas.SeriesRelationDto): VerifiedLinkedCatalogPost => {
  const {role, link} = mapBaseRelationDto(dto) as VerifiedLinkedCatalogPost;

  return {
    role,
    numberInSeries,
    link: {
      ...link,
      name: assert(title, 'mapSeriesRelationDto: title expected'),
      languages: allTitles?.map(t => t.language),
    },
  };
};

/** Maps work to work relation */
const mapWorkToWorkRelationDto = ({
  title,
  ...dto
}: Schemas.WorkToWorkRelationDto): VerifiedLinkedCatalogPost => {
  const {role, link} = mapBaseRelationDto(dto) as VerifiedLinkedCatalogPost;

  return {
    role,
    link: {
      ...link,
      name: assert(title, 'mapWorkRelationDto: title expected'),
    },
  };
};

const mapAgentPersonRelationDto = (
  {agentName, nationalId, ...dto}: Schemas.AgentPersonRelationDto,
  typeMapping: TypeMapping,
): VerifiedLinkedAgent => {
  const {role, link} = mapBaseRelationDto(
    dto,
    typeMapping,
  ) as VerifiedLinkedAgent;

  const relation: VerifiedLinkedAgent = {
    role,
    link: {
      ...link,
      nationalId,
      agentName: mapAgentNameDto(
        assert(agentName, 'mapAgentRelationDto: agentName expected'),
        undefined,
      ),
    },
  };

  return relation;
};

const mapWorkToAgentRelationDto = (
  {
    agentName,
    agentNationalId,
    nationalId,
    ...dto
  }: // Type fixing of inconsistent naming of nationalId
  | (Schemas.AgentRelationDto & {nationalId?: string})
    | (Schemas.AgentCorporationRelationDto & {agentNationalId?: string}),
  typeMapping: TypeMapping,
): VerifiedLinkedAgent => {
  const {role, link} = mapBaseRelationDto(
    dto,
    typeMapping,
  ) as VerifiedLinkedAgent;

  const relation: VerifiedLinkedAgent = {
    role,
    link: {
      ...link,
      agentName: mapAgentNameDto(
        assert(agentName, 'mapAgentRelationDto: agentName expected'),
        undefined,
      ),
      nationalId: agentNationalId ?? nationalId,
    },
  };

  return relation;
};

const mapAgentWorkRelationDto = (
  {entityId, ...dto}: Schemas.AgentWorkRelationDto,
  typeMapping: TypeMapping,
): VerifiedLinkedCatalogPost => {
  assert(
    dto.roles.length === 1 && dto.roles[0] === 'MENTIONED_IN',
    'mapAgentWorkRelationDto: only single role "MENTIONED_IN" is implemented',
    dto,
  );
  const {role, link} = mapBaseRelationDto(
    {type: 'WORK', entityId, role: 'MENTIONED_IN'},
    typeMapping,
  ) as VerifiedLinkedAgent;

  const relation: VerifiedLinkedCatalogPost = {
    role,
    link: {
      ...link,
      type: Concept.work,
      linkStatus: 'verified',
      name: assert(
        dto.workTitle,
        'mapAgentWorkRelationDto: workTitle expected',
      ),
    },
  };

  return relation;
};

const mapToSeriesRelationDto = (
  link: VerifiedLinkedCatalogPost,
): Schemas.SeriesRelationDto => {
  const {type, entityId, role} = mapToBaseRelationDto(link);

  const relation: Schemas.SeriesRelationDto = {
    entityId,
    type: type as Schemas.SeriesRelationDto['type'],
    role: role as Schemas.SeriesRelationDto['role'],
    title: link.link.name,
  };

  if (!isNullish(link.numberInSeries)) {
    relation.numberInSeries = link.numberInSeries;
  }

  return relation;
};

const mapToWorkRelationDto = (
  link: VerifiedLinkedCatalogPost,
): Schemas.WorkToWorkRelationDto => {
  const {role, type, entityId} = mapToBaseRelationDto(link, Concept.work);

  const relation: Schemas.WorkToWorkRelationDto = {
    entityId,
    type: type as Schemas.WorkToWorkRelationDto['type'],
    role: role as Schemas.WorkToWorkRelationDto['role'],
    title: link.link.name,
  };

  return relation;
};

const mapToAgentRelationDto = (
  link: VerifiedLinkedAgent,
): Schemas.AgentRelationDto => {
  const {role, type, entityId} = mapToBaseRelationDto(link);

  const relation: Schemas.AgentRelationDto = {
    entityId,
    type: type as Schemas.AgentRelationDto['type'],
    role: role as Schemas.AgentRelationDto['role'],
    agentName: mapToAgentNameDto(link.link.agentName),
    agentNationalId: link.link.nationalId,
  };

  return relation;
};

const mapToAgentPersonRelationDto = (
  link: VerifiedLinkedAgent,
): Schemas.AgentPersonRelationDto => {
  const {role, type, entityId} = mapToBaseRelationDto(link);

  const relation: Schemas.AgentPersonRelationDto = {
    entityId,
    type: type as Schemas.AgentPersonRelationDto['type'],
    role: role as Schemas.AgentPersonRelationDto['role'],
    agentName: mapToAgentNameDto(link.link.agentName),
    nationalId: link.link.nationalId,
  };

  return relation;
};

const mapToAgentCorporationRelationDto = (
  link: VerifiedLinkedAgent,
): Schemas.AgentCorporationRelationDto => {
  const {role, type, entityId} = mapToBaseRelationDto(link);

  const relation: Schemas.AgentCorporationRelationDto = {
    entityId,
    type: type as Schemas.AgentCorporationRelationDto['type'],
    role: role as Schemas.AgentCorporationRelationDto['role'],
    agentName: mapToAgentNameDto(link.link.agentName),
    nationalId: link.link.nationalId,
  };

  return relation;
};
