import {Concept} from 'types';
import {LinkType} from 'schemaDefinition/types/linkTypes';

export const entityLinkTypes: LinkType[] = [
  // Roles for a type will be all roles where the type is typeA or typeB
  {
    // Unummerert/nummerert serie
    typeA: Concept.work,
    roleA: 'partOf',
    typeB: Concept.series,
    roleB: 'contains',
    linkProperty: {
      name: 'numberInSeries',
      type: 'text',
    },
  },
  {
    // Læreverk
    typeA: Concept.work,
    roleA: 'partOf',
    typeB: Concept.educationalSeries,
    roleB: 'contains',
    linkProperty: {
      name: 'numberInSeries',
      type: 'text',
    },
  },
  {
    // Annen tilknytning
    typeA: Concept.work,
    roleA: 'partOf',
    typeB: Concept.otherConnection,
    roleB: 'contains',
    linkProperty: {
      name: 'numberInSeries',
      type: 'text',
    },
  },
  {
    // Samleverk
    typeA: Concept.work,
    roleA: 'partOf',
    typeB: Concept.work,
    roleB: 'contains',
  },
  {
    // Omtaler person/corporation/event/(publisher?)
    typeA: Concept.work,
    roleA: 'mentions',
    typeB: Concept.agent,
    roleB: 'mentionedIn',
    oneWayEdit: true,
  },
  {
    // Omtaler verk, t(work.mentions.work), t(work.mentionedIn.work)
    typeA: Concept.work,
    roleA: 'mentions',
    typeB: Concept.work,
    roleB: 'mentionedIn',
  },
  {
    // Basert på verk
    typeA: Concept.work,
    roleA: 'basedOn',
    typeB: Concept.work,
    roleB: 'hasInspired',
  },
  {
    // Forlagsserie
    typeA: Concept.manifestation,
    roleA: 'partOf',
    typeB: Concept.publisherSeries,
    roleB: 'contains',
    linkProperty: {
      name: 'numberInSeries',
      type: 'text',
    },
  },
  {
    // Korporasjon relatert til korporasjon
    typeA: Concept.corporation,
    roleA: 'relatedTo',
    typeB: Concept.corporation,
    roleB: 'relatedTo',
  },
  {
    // Pseudonym
    typeA: Concept.person,
    roleA: 'hasPseudonym',
    typeB: Concept.person,
    roleB: 'pseudonymFor',
  },
];

// => Example
// export const workLinkCodelist: LinkedRoleCodeList = {
//   id: 'work.linkedRole',
//   codes: [
//     {
//       code: 'partOf.work',
//       value: 'Del av verk',
//       targetEntity: Concept.work,
//       role: 'partOf',
//     },
//     {
//       code: 'contains.work',
//       value: 'Samleverk',
//       targetEntity: Concept.work,
//       role: 'contains',
//     },
//     {
//       code: 'mentions.agent',
//       value: 'Omtaler person',
//       targetEntity: Concept.agent,
//       role: 'mentions',
//     },
//     // {
//     //   code: 'agent.mentionedIn.work',
//     //   value: 'Omtales i verk',
//     // },
//     {
//       code: 'mentions.work',
//       value: 'Omtaler verk',
//       targetEntity: Concept.work,
//       role: 'mentions',
//     },
//     {
//       code: 'mentionedIn.work',
//       value: 'Omtales i verk',
//       targetEntity: Concept.work,
//       role: 'mentionedIn',
//     },
//     {
//       code: 'basedOn.work',
//       value: 'Basert på verk',
//       targetEntity: Concept.work,
//       role: 'basedOn',
//     },
//     {
//       code: 'hasInspired.work',
//       value: 'Basert på verk',
//       targetEntity: Concept.work,
//       role: 'hasInspired',
//     },
//     {
//       code: 'partOf.series',
//       value: 'Del av nummerert serie',
//       targetEntity: Concept.series,
//       role: 'partOf',
//       linkProperty: {
//         name: 'numberInSeries',
//         type: 'text',
//       },
//     },
//   ],
// };
