import {assert} from 'assert-ts';
import isEqual from 'lodash/isEqual';
import {DataValue, PartThesaurus} from 'schemaDefinition/types';
import {CategoryNodesWithAncestors} from 'services/thesaurus/types';
import {assertAsThesaurusValue} from 'schemaDefinition';
import {groupAndOrderByCategory} from 'services/thesaurus/functions/groupAndOrderByCategory';
import {ThesaurusMap} from '../types';

/**
 * Gets the actual thesaurus 'group' paths ('path#category' or 'path#nodetype') for each category/type that has changes,
 * i.e. terms in category/type in originalValue and changesValue are different.
 */
export const getActualThesaurusCategoryPaths = (
  part: PartThesaurus,
  path: string,
  originalValue: DataValue,
  changesValue: DataValue,
  thesauruses: ThesaurusMap | undefined,
): {
  paths: string[] | undefined;
  groupedOriginal: CategoryNodesWithAncestors[] | undefined;
  groupedChanges: CategoryNodesWithAncestors[] | undefined;
} => {
  const thesaurus = thesauruses?.[part.thesaurusId];
  if (
    !assert.soft(
      thesaurus,
      'getActualThesaurusCategoryPaths: Thesaurus not found',
      {part},
    )
  ) {
    return {
      paths: undefined,
      groupedOriginal: undefined,
      groupedChanges: undefined,
    };
  }

  const originalTerms = assertAsThesaurusValue(originalValue) ?? [];
  const changesTerms = assertAsThesaurusValue(changesValue) ?? [];

  const groupedOriginal = groupAndOrderByCategory(thesaurus, originalTerms);
  const groupedChanges = groupAndOrderByCategory(thesaurus, changesTerms);

  const allCategories = Array.from(
    new Set<string>([
      ...(groupedOriginal ?? []).map(go => go.categoryNode.id),
      ...(groupedChanges ?? []).map(gc => gc.categoryNode.id),
    ]),
  );
  const categoriesWithChanges = allCategories.filter(category => {
    const originalCategoryValue = groupedOriginal.find(
      go => go.categoryNode.id === category,
    );
    const changesCategoryValue = groupedChanges.find(
      gc => gc.categoryNode.id === category,
    );
    return !isEqual(originalCategoryValue, changesCategoryValue);
  });

  return {
    paths: categoriesWithChanges.map(category => `${path}#${category}`),
    groupedOriginal,
    groupedChanges,
  };
};
