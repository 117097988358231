import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {assert} from 'assert-ts';
import {DataValue, FieldError, PartCodeList} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {CodeListId} from 'api';
import {evaluateFieldRequired} from 'schemaDefinition/functions/evaluateFieldRequired';
import {useCodelist} from 'services/codeLists';
import {
  CodeListField,
  Layout,
  RadioButtonsField,
  SortableCodeListField,
  Spacer,
  Text,
} from 'components';
import {useUniqueFieldId} from 'components/fields/hooks/useUniqueFieldId';
import {BaseFieldProps} from './types';
import {useDataFormContext} from '../../contexts';
import {
  getErrorMessage,
  getSimpleCardinality,
  isMultiple,
} from '../../functions';
import {toFieldError, validateCodeList} from '../../functions/validators';

type Props = BaseFieldProps & {
  part: PartCodeList;
};

export const Select: React.FC<Props> = ({
  part,
  value,
  setFieldValue,
  valuePath,
  localScope,
  globalScope,
  relatedScope,
  label,
  placeholder,
  mode,
  showWhenReadonlyAndEmpty,
  width,
  maxWidth,
  flex,
}) => {
  const {codelistId, cardinality = 'single'} = part;
  const {tLoose} = useLocalization();
  const codelist = useCodelist(codelistId as CodeListId);
  const {codes: options} = codelist;
  const optionsWithCode = useMemo(
    () =>
      part.variant === 'buttons'
        ? options
        : options.map(opt => ({
            ...opt,
            label: `${opt.value} (${opt.code})`,
          })),
    [options, part.variant],
  );

  const handleSetFieldValue = useCallback(
    (value: DataValue) => setFieldValue(valuePath, value, part),
    [setFieldValue, valuePath, part],
  );

  const fieldId = useUniqueFieldId(valuePath);
  const [visited, setVisited] = useState(false);
  const handleSetVisited = useCallback(() => {
    setVisited(true);
  }, []);
  const {showErrors} = useDataFormContext();

  const required = useMemo(() => {
    return evaluateFieldRequired(
      part.required,
      valuePath,
      localScope,
      globalScope,
      relatedScope,
      value,
    );
  }, [globalScope, part.required, relatedScope, localScope, value, valuePath]);

  // If the value is disabled, reevaluate the error.
  //  Triggered when e.g. work type is changed,
  //  which updates disabled on manifestation codelist items (e.g. PRODUCT_FORM).
  useEffect(() => {
    if (value && codelist.codes.find(c => c.code === value)?.disabled) {
      setVisited(true);
    }
  }, [codelist.codes, value]);

  const {error, errorMessage} = useMemo((): {
    error: FieldError;
    errorMessage?: string;
  } => {
    if (showErrors || visited) {
      const validation = validateCodeList(part, {
        value,
        valuePath,
        localScope,
        globalScope,
        relatedScope,
        codelistMap: {
          [codelist.id]: codelist,
        },
      });

      return {
        error: toFieldError(validation.valid),
        errorMessage: getErrorMessage(validation, tLoose),
      };
    }
    return {error: false};
  }, [
    codelist,
    globalScope,
    part,
    relatedScope,
    localScope,
    showErrors,
    tLoose,
    value,
    valuePath,
    visited,
  ]);

  const codeValue = (value ?? (isMultiple(cardinality) ? [] : null)) as
    | string
    | string[]
    | null;

  if (part.variant === 'buttons' && cardinality === 'single') {
    assert(
      typeof codeValue === 'string' || codeValue === null,
      'Select: single value must be string or null',
      {value, part},
    );
    const singleCodeValue = codeValue as string | null;

    return (
      <RadioButtonsField
        id={fieldId}
        label={label}
        value={singleCodeValue}
        name={valuePath}
        options={optionsWithCode}
        showCode={part.showCode}
        required={required}
        error={error}
        onChange={handleSetFieldValue}
        onBlur={handleSetVisited}
        flex={flex}
        width={width}
        maxWidth={maxWidth}
      />
    );
  }

  return codelistId === 'COUNTRY' ? (
    isMultiple(cardinality) ? (
      <SortableCodeListField
        label={label}
        value={codeValue}
        cardinality={getSimpleCardinality(cardinality)}
        showCode={part.showCode}
        readonly={mode === 'read-only'}
        showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
        required={required}
        error={error}
        errorMessage={errorMessage}
        name={valuePath}
        id={fieldId}
        placeholder={placeholder}
        options={optionsWithCode}
        width={width}
        maxWidth={maxWidth}
        flex={flex}
        renderOptionContent={option => (
          <Layout horizontal>
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              alt=""
            />
            <Spacer width={1} />
            <Text variant="body2">{option.value}</Text>
          </Layout>
        )}
        onChange={handleSetFieldValue}
        onBlur={handleSetVisited}
      />
    ) : (
      <CodeListField
        label={label}
        value={codeValue}
        cardinality={getSimpleCardinality(cardinality)}
        showCode={part.showCode}
        readonly={mode === 'read-only'}
        showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
        required={required}
        error={error}
        errorMessage={errorMessage}
        name={valuePath}
        id={fieldId}
        placeholder={placeholder}
        options={optionsWithCode}
        width={width}
        maxWidth={maxWidth}
        flex={flex}
        renderOptionContent={option => (
          <Layout horizontal>
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              alt=""
            />
            <Spacer width={1} />
            <Text variant="body2" data-cy={`codelist-option-${option.code}`}>
              {option.value}
            </Text>
          </Layout>
        )}
        onChange={handleSetFieldValue}
        onBlur={handleSetVisited}
      />
    )
  ) : (
    <SortableCodeListField
      label={label}
      value={codeValue}
      cardinality={getSimpleCardinality(cardinality)}
      showCode={part.showCode}
      readonly={mode === 'read-only'}
      showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
      error={error}
      errorMessage={errorMessage}
      required={required}
      name={valuePath}
      id={fieldId}
      placeholder={placeholder}
      options={optionsWithCode}
      width={width}
      maxWidth={maxWidth}
      flex={flex}
      onChange={handleSetFieldValue}
      onBlur={handleSetVisited}
    />
  );
};
