import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AnyAction} from 'redux';
import {FCWithChildren} from 'types';
import {MasterLocale} from 'localization/types';
import {AppState} from 'store/types';
import {useLocalization} from 'localization';
import {
  CodeListId,
  CombinedCodeListId,
  CombinedCodeListIds,
  LoadStatus,
  LocalCodeListId,
  PartCodeLists,
} from 'api';
import {isLinkedRoleCodeListId} from 'schemaDefinition';
import {
  AllLocalCodeListIds,
  LinkedRoleCodeListId,
} from 'schemaDefinition/types/linkTypes';
import {useGetTokens} from 'services/auth';
import {useMockContext} from 'services/utils/contexts';
import {Scene} from 'components';
import {CodeListsState} from '../types';
import {combineCodeListAction} from '../functions/combineCodeListAction';
import {loadCodeListAction} from '../functions/loadCodeListAction';

type Props = {
  codeLists: (CodeListId | LinkedRoleCodeListId)[];
};

const getAggregateStatus = (
  codeListIds: (CodeListId | LinkedRoleCodeListId)[],
  state: CodeListsState,
): LoadStatus => {
  return codeListIds.reduce<LoadStatus>((acc, name) => {
    if (
      isLinkedRoleCodeListId(name) ||
      AllLocalCodeListIds.includes(name as LocalCodeListId)
    ) {
      return acc;
    }
    const codeListStatus = state[name]?.status ?? 'NotLoaded';
    return acc === 'Failed' || codeListStatus === 'Failed'
      ? 'Failed'
      : acc === 'Loaded' && codeListStatus === 'Loaded'
        ? 'Loaded'
        : 'Loading';
  }, 'Loaded');
};

const getNotLoaded = (
  codeListIds: (CodeListId | LinkedRoleCodeListId)[],
  state: CodeListsState,
): CodeListId[] => {
  return codeListIds.filter(
    name =>
      !isLinkedRoleCodeListId(name) &&
      (state[name]?.status ?? 'NotLoaded') === 'NotLoaded',
  ) as CodeListId[];
};

const getNotLoadedOrLoading = (
  codeListIds: (CodeListId | LinkedRoleCodeListId)[],
  state: CodeListsState,
): CodeListId[] => {
  return codeListIds.filter(
    name =>
      !isLinkedRoleCodeListId(name) &&
      ((state[name]?.status ?? 'NotLoaded') === 'NotLoaded' ||
        state[name]?.status === 'Loading'),
  ) as CodeListId[];
};

const codeListsStateSelector = (state: AppState) => state.codeLists;

/**
 * Will only render children after code lists are successfully loaded.
 * Shows spinner while loading, and an error message if any fails
 * @param param0
 * @returns
 */
export const CodeListsGate: FCWithChildren<Props> = ({
  codeLists: givenCodeLists,
  children,
}) => {
  const {codeLists, combinedCodeLists} = useMemo(() => {
    // Expand any combined code lists to their individual code lists
    const combinedCodeLists = givenCodeLists.filter(id =>
      CombinedCodeListIds.includes(id as CombinedCodeListId),
    ) as CombinedCodeListId[];
    const codeLists = givenCodeLists.filter(
      id => !CombinedCodeListIds.includes(id as CombinedCodeListId),
    ) as CodeListId[];
    combinedCodeLists.forEach(id => {
      const partIds = PartCodeLists[id];
      partIds.forEach(partId => {
        if (!codeLists.includes(partId)) {
          codeLists.push(partId);
        }
      });
    });

    return {
      codeLists,
      combinedCodeLists,
    };
  }, [givenCodeLists]);
  const getTokens = useGetTokens();
  const {locale} = useLocalization();
  const mock = useMockContext();
  const dispatch = useDispatch();
  const codeListsState = useSelector(codeListsStateSelector);
  const status = useMemo(
    () => getAggregateStatus(givenCodeLists, codeListsState),
    [codeListsState, givenCodeLists],
  );

  // Trigger loading of code lists not yet loaded
  useEffect(() => {
    const notLoaded = getNotLoaded(codeLists, codeListsState);
    notLoaded.forEach(id => {
      dispatch(
        loadCodeListAction(
          id,
          locale ?? MasterLocale,
          getTokens,
          mock && false,
        ) as unknown as AnyAction,
      );
    });
    // On mount only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Trigger setting combined codelists
  useEffect(() => {
    const loading = getNotLoadedOrLoading(codeLists, codeListsState);
    const notCombined = getNotLoaded(
      combinedCodeLists,
      codeListsState,
    ) as CombinedCodeListId[];
    if (loading.length === 0 && notCombined.length > 0) {
      notCombined.forEach(id => {
        dispatch(
          combineCodeListAction(id, PartCodeLists[id]) as unknown as AnyAction,
        );
      });
    }
  }, [codeLists, codeListsState, combinedCodeLists, dispatch]);

  return status === 'NotLoaded' ||
    status === 'Loading' ||
    status === 'Failed' ? (
    <Scene>
      <Scene.Header title="" />
      <Scene.Content
        loadStatus={status}
        error={status === 'Failed' ? 'Failed to load codelists' : undefined}
      />
    </Scene>
  ) : (
    <>{children}</>
  );
};
