import {useCallback, useEffect, useMemo, useReducer, useRef} from 'react';
import assert from 'assert-ts';
import {
  Agent,
  AgentWorkSummary,
  DataLoadStatus,
  HttpError,
  SaveAgentOptions,
  deleteAgent,
  getAgent,
  postAgent,
  putAgent,
} from 'api';
import {useGetTokens} from 'services/auth';
import {useWorkSummaries} from 'services/data/metadata/hooks/useWorkSummaries';
import {removeMentionedIn} from '../functions/removeMentionedIn';
import {
  AgentDefaultState,
  AgentState,
  agentReducer,
} from '../reducer/agentReducer';

export type SaveStatus = 'Saved' | 'Failed' | 'None';

export type SaveAgentFunc = (
  agent: Agent,
  options: SaveAgentOptions,
) => Promise<Agent>;

export type DeleteAgentFunc = (agent: Agent) => Promise<void>;

export const useAgent = (
  agentId?: string | null,
  /**
   * Return intermediate result as loaded state, i.e. agent without
   * work summary data loaded (just title and roles)
   */
  returnPartial: 'partial' | 'full' = 'full',
  filterLinks: 'mentionedIn' | undefined = undefined,
  mock?: boolean,
): {
  /** Agent enhanced with work summaries as they are loaded */
  agent: AgentState;
  /** Loaded agent, with "empty" works */
  agentEmptyWorks: AgentState;
  /** Loaded work summaries, possibly incremental (batched) */
  workSummaries: DataLoadStatus<AgentWorkSummary[]>;
  saveAgent: SaveAgentFunc;
  deleteAgent: DeleteAgentFunc;
} => {
  const getTokens = useGetTokens();
  const [agent, dispatch] = useReducer(agentReducer, AgentDefaultState);
  const agentIdRef = useRef(agentId);

  const workSummaries = useWorkSummaries(agent.data?.works);

  // Reset load when agentId changes
  useEffect(() => {
    if (agentId && agentId !== agentIdRef.current) {
      agentIdRef.current = agentId;
      dispatch({type: 'LOAD_AGENT', loadType: 'NOT_LOADED'});
    }
  });

  useEffect(() => {
    if (agent.status === 'NotLoaded') {
      if (agentId) {
        agentIdRef.current = agentId;
        dispatch({type: 'LOAD_AGENT', loadType: 'REQUEST'});

        getAgent(agentId, getTokens, mock)
          .then(data => {
            if (filterLinks === 'mentionedIn') {
              removeMentionedIn(data);
            }
            dispatch({
              type: 'LOAD_AGENT',
              loadType: 'SUCCESS',
              payload: {data},
            });
          })
          .catch((error: HttpError) =>
            dispatch({
              type: 'LOAD_AGENT',
              loadType: 'FAILURE',
              payload: {error},
            }),
          );
      }
    }
  }, [agent.status, agentId, filterLinks, getTokens, mock, returnPartial]);

  /** Save agent to backend and update reducer state if successful */
  const handleSaveAgent = useCallback<SaveAgentFunc>(
    (agent, option) => {
      const apiCall: Promise<Agent> = agentId
        ? putAgent(agent, option, getTokens, mock)
        : postAgent(agent, option, getTokens, mock);

      return apiCall.then(updatedAgent => {
        // TODO: Remove this call when metionedIn is supported in backend
        if (returnPartial === 'full') {
          removeMentionedIn(updatedAgent);
        }

        dispatch({
          type: 'SAVED_AGENT',
          payload: {data: updatedAgent},
        });
        return updatedAgent;
      });
    },
    [agentId, getTokens, mock, returnPartial],
  );

  /** Delete agent from backend and update reducer state if successful */
  const handleDeleteAgent = useCallback<DeleteAgentFunc>(
    agent => {
      return deleteAgent(
        assert(agent.id, 'Expected agent to have an id', {agent}),
        getTokens,
        mock,
      ).then(() => {
        dispatch({
          type: 'DELETED_AGENT',
        });
      });
    },
    [getTokens, mock],
  );

  return useMemo(() => {
    const summariesLoading = !['Loaded', 'Error'].includes(
      workSummaries.status,
    );

    // Merge agent work roles with work summary
    const mergedWorkSummaries =
      agent.status === 'Loaded' &&
      (workSummaries.status === 'Loaded' || workSummaries.status === 'Loading')
        ? (agent.data?.works ?? []).map(({id, roles, ...rest}) => ({
            id,
            roles,
            ...(workSummaries.data?.find(summary => summary.id === id) ?? rest),
          }))
        : agent.data?.works ?? [];

    return {
      agent: (agent.status === 'NotLoaded' ||
      agent.status === 'Loading' ||
      agent.status === 'Failed'
        ? agent
        : summariesLoading
          ? returnPartial === 'full'
            ? {
                ...agent,
                data: {
                  ...agent.data,
                  // Provide those loaded successfully so far, even if not complete
                  works: mergedWorkSummaries,
                },
                status: 'Loading',
              }
            : {
                status: 'Loaded',
                data: {
                  ...assert(agent.data),
                  // Provide those loaded successfully so far, even if not complete
                  works: mergedWorkSummaries,
                },
                partial: true,
              }
          : {
              status: 'Loaded',
              data: {
                ...assert(agent.data),
                // Provide those loaded successfully, some summaries may have failed
                works: mergedWorkSummaries,
              },
            }) as AgentState,
      agentEmptyWorks: agent,
      workSummaries: {...workSummaries, data: mergedWorkSummaries},
      saveAgent: handleSaveAgent,
      deleteAgent: handleDeleteAgent,
    };
  }, [workSummaries, agent, returnPartial, handleSaveAgent, handleDeleteAgent]);
};
