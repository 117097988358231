import {useCallback} from 'react';
import {LocalizationContextType} from 'localization/context/types';
import {DataValue, Part} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {formatNullValue} from 'schema/preview/functions';
import {useTextValue} from 'schema/preview/hooks';
import {BaseFieldProps} from '../types';
import {ValueLayout} from './ValueLayout';

type Props<TPart extends Part> = BaseFieldProps<TPart> & {
  formatter?: (
    value: DataValue,
    part: TPart,
    localization: LocalizationContextType,
  ) => string;
};

export const MultiLineValue = <TPart extends Part>({
  part,
  value,
  layoutDirection,
  formatter,
}: Props<TPart>) => {
  const localization = useLocalization();
  const TextValue = useTextValue();

  const renderValue = useCallback(() => {
    const values = Array.isArray(value) ? value : value ? [value] : [];
    const formattedValues =
      values.length === 0
        ? [formatNullValue()]
        : values.map(v =>
            formatter
              ? formatter(v, part, localization)
              : v?.toString() ?? formatNullValue(),
          );

    return (
      <>
        {formattedValues.map((v, i) => (
          <TextValue key={i}>{v}</TextValue>
        ))}
      </>
    );
  }, [TextValue, formatter, localization, part, value]);

  return (
    <ValueLayout
      renderValue={renderValue}
      part={part}
      layoutDirection={layoutDirection}
    />
  );
};
