import {
  LinkedRoleCode,
  LinkedRoleCodeList,
  RoleEntityCode,
} from 'schemaDefinition/types/linkTypes';

export const useLinkedRoleCode = (
  role: RoleEntityCode | undefined,
  codelist: LinkedRoleCodeList,
): LinkedRoleCode | undefined => {
  return role ? codelist.codes.find(c => c.code === role) : undefined;
};
