import {
  PartCodeListOrText,
  PartText,
  PartTextArea,
} from 'schemaDefinition/types';
import {evaluateFieldRequired} from 'schemaDefinition';
import {ValidationArgs, ValidationResult} from './types';
import {fail} from './fail';
import {validResult} from './validResult';
import {validateConditions} from './validateConditions';
import {validateValueType} from './validateValueType';
import {warning} from './warning';

export const validateText = (
  part: PartText | PartTextArea,
  args: ValidationArgs,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  return validateValueType(
    part,
    validateSingleTextValue,
    args,
    aggregateResult,
  );
};

export const validateSingleTextValue = (
  part: PartText | PartTextArea | PartCodeListOrText,
  args: ValidationArgs,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  if (!args.value && part.required) {
    const required = evaluateFieldRequired(
      part.required,
      args.valuePath,
      args.localScope,
      args.globalScope,
      args.relatedScope,
      args.value,
    );
    return (required === true ? fail : warning)(
      {
        ...args,
        part,
        validation: 'required',
        messageKey: 'required',
      },
      aggregateResult,
    );
  }

  if (
    args.value !== undefined &&
    args.value !== null &&
    typeof args.value !== 'string'
  ) {
    return fail(
      {
        ...args,
        part,
        validation: 'invalid type',
        messageKey: 'invalid type',
      },
      aggregateResult,
    );
  }

  if (part.validation) {
    return validateConditions(part.validation, part, args, aggregateResult);
  }

  return aggregateResult;
};
