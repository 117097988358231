import {useMemo} from 'react';
import {DataLoadStatus, Media} from 'api/types';
import {getMediaByEans, useLazyGetResource} from 'api';
import {useGetTokens} from 'services/auth';

export const useLazyManifestationMediaData = (
  eans: string[],
  doLoad: boolean,
  mock?: boolean,
): {
  media: DataLoadStatus<Media[]>;
} => {
  const token = useGetTokens();

  const media = useLazyGetResource(doLoad, () =>
    getMediaByEans(eans, token, mock),
  );

  return useMemo(
    () => ({
      media,
    }),
    [media],
  );
};
