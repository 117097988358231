import {useCallback, useMemo, useRef} from 'react';
import assert from 'assert-ts';
import {
  DataLoadStatus,
  WorkSummary,
  getWorks,
  useGetResourceListBatchedByIds,
} from 'api';
import {useGetTokens} from 'services/auth';
import {useCodelists} from 'services/codeLists';
import {mapWorkMetadataToWorkSummary} from '../functions/mapWorkMetadataToWorkSummary';
import {useMetadataCodelistIds} from './useMetadataCodelistIds';

export const useWorkSummaries = (
  idItems: Array<{id: string}> | undefined,
  batchSize = 20,
): DataLoadStatus<WorkSummary[]> => {
  const mock = false;
  const getTokens = useGetTokens();
  const idsRef = useRef<string[]>([]);
  const ids = useMemo(() => {
    const newIds = (idItems ?? []).map(item => item.id);
    if (
      idsRef.current.length !== newIds.length &&
      !newIds.every(id => idsRef.current.includes(id))
    ) {
      idsRef.current = newIds;
    }

    return idsRef.current;
  }, [idItems]);

  const handleGetWorks = useCallback(
    (ids: string[]) => getWorks(ids, getTokens, mock),
    [getTokens, mock],
  );

  const works = useGetResourceListBatchedByIds(
    idItems ? ids : undefined,
    batchSize,
    handleGetWorks,
  );

  const codelistIds = useMetadataCodelistIds();
  const codelists = useCodelists(codelistIds);

  return useMemo((): DataLoadStatus<WorkSummary[]> => {
    const data = works.data
      ? works.data.map(work => mapWorkMetadataToWorkSummary(work, codelists))
      : undefined;
    return works.status === 'NotLoaded' || works.status === 'Loading'
      ? {
          status: works.status,
          // Provide those loaded successfully so far, even if not complete
          data,
        }
      : works.status === 'Loaded'
        ? {
            status: 'Loaded',
            data: assert(data, 'useWorkSummaries: data expected'),
          }
        : {
            status: works.status,
            error: assert(works.error, 'useWorkSummaries: error expected'),
            // Provide those loaded successfully, even if some failed
            data,
          };
  }, [codelists, works.data, works.error, works.status]);
};
