import {DataValue, SingleOrArrayVal} from 'schemaDefinition/types';
import {EvaluationArgs} from './types';
import {evaluateVal} from './evaluateVal';

export const evaluateSingleOrArrayVal = (
  val: SingleOrArrayVal,
  args: EvaluationArgs,
): DataValue | DataValue[] => {
  if (Array.isArray(val)) {
    return val.map(v => evaluateVal(v, args));
  }
  return evaluateVal(val, args);
};
