import {assert} from 'assert-ts';
import {UserDto} from 'api';
import {LockState} from '../contexts/types';
import {LockValue} from './types';
import {getLockStateFromValue} from './getLockStatusFromValue';

export type LockTransitionProps = {
  lockValue: LockValue | undefined;
  lockState: LockState;
  thisUserLockId: string | undefined;
};

export type LockStateTransitionDependencies = {
  user: Pick<UserDto, 'id'> | null;
  postLock: () => void;
  showWarning?: (message: string) => void;
  showInfo?: (message: string) => void;
};

/** State transtion may trigger one action:
 * - postLock: post lock action
 * - warning: show warning message
 * - info: show info message
 */
type StateTransitionAction = {
  postLock?: boolean;
  warning?: string;
  info?: string;
};

/**
 * When lock state updated from backend,
 * - Ignore identical lock
 * - Initial: isLocked & byOther => LockedByOther
 * - Initial: isLocked & byThis => LockedByThis, (consider: show info: already locked by this user)
 * - Initial: isNotLocked => post lock/initial => LockedByThis
 * - LockedByOther: isLocked & byOther => LockedByOther
 * - LockedByOther: isLocked & byThis => LockedByThis (acquired lock in this tab or other tab: sideeffect of acquiring lock in other tab, show info: reload to refresh data)
 * - LockedByOther: isNotLocked => NotLocked (user should reload to get lock and fresh data)
 * - NotLocked: isLocked & byOther => LockedByOther
 * - NotLocked: isLocked & byThis => LockedByThis (other tab: sideeffect of locking in other tab, consider show info)
 * - NotLocked: isNotLocked => NotLocked
 * - LockedByThis & isLocked & byOther => LockedByOther (snackbar warning)
 * - LockedByThis & isLocked & byThis => ignore (sideeffect of locking)
 * - LockedByThis & isNotLocked => NotLocked (sideeffect of unlocking in other tab, InfoSnack: save and reload to get lock)
 */

const validTransitionsWithAction: {
  [current in LockState]: {
    [updated in LockState]?: StateTransitionAction | null;
  };
} = {
  [LockState.Initial]: {
    [LockState.LockedByOther]: null,
    [LockState.LockedByThis]: null,
    [LockState.NotLocked]: {postLock: true},
  },
  [LockState.LockedByOther]: {
    [LockState.LockedByOther]: null,
    [LockState.LockedByThis]: null,
    [LockState.NotLocked]: {
      info: 'page.metadata.lock.message.releasedReloadToLock',
    },
  },
  [LockState.NotLocked]: {
    [LockState.LockedByOther]: null,
    [LockState.LockedByThis]: null,
    [LockState.NotLocked]: null,
  },
  [LockState.LockedByThis]: {
    [LockState.LockedByOther]: {
      warning: 'page.metadata.lock.message.lockAcquiredByOtherUser',
    },
    [LockState.LockedByThis]: null,
    [LockState.NotLocked]: {
      warning: 'page.metadata.lock.message.lockWasReleasedByThisUser',
    },
  },
  [LockState.TemporarilyLocked]: {},
};

/**
 * Ignores identical updated lock, otherwise transitions lock state based on
 * current state and updated lock according to validTransitionsWithAction.
 */
export const lockWorkOnOpenStateTransition = ({
  current,
  updated,
  dependencies: _,
}: {
  current: LockTransitionProps;
  updated: LockValue;
  dependencies: LockStateTransitionDependencies;
}): LockTransitionProps => {
  if (
    // Ignore same data
    updated === current.lockValue ||
    JSON.stringify(updated) === JSON.stringify(current.lockValue)
  ) {
    return current;
  }

  const updatedState = getLockStateFromValue(updated, _.user);

  if (
    validTransitionsWithAction[current.lockState][updatedState] === undefined
  ) {
    assert.soft(
      false,
      `Invalid lock state transition: ${current.lockState} => ${updatedState}`,
    );
    return current;
  }

  const action = validTransitionsWithAction[current.lockState][updatedState];

  // console.log(
  //   `@@@@lockTransition: ${current.lockState} => ${updatedState}, action: ${JSON.stringify(action)}`,
  // );

  const newLockProps = {
    lockValue: updated,
    lockState: updatedState,
    thisUserLockId:
      updatedState === LockState.LockedByThis ? updated.lock?.id : undefined,
  };

  if (!action && JSON.stringify(newLockProps) === JSON.stringify(current)) {
    return current;
  }

  if (action?.warning) {
    _.showWarning?.(action.warning);
  }

  if (action?.info) {
    _.showInfo?.(action.info);
  }

  if (action?.postLock) {
    _.postLock();
  }

  return newLockProps;
};
