import {Concept} from 'types';
import {AgentPublisher} from 'api/types';
import {Schema, Separator} from 'schemaDefinition/types';

export const agentPublisherSchema: Schema<AgentPublisher> = {
  name: 'publisher',
  key: 'publisher',
  parts: [
    {...Separator('card', 'line'), titleKey: 'nameVariants'},
    {
      type: 'nameVariant',
      agentType: Concept.publisher,
      name: 'nameVariants',
      cardinality: 'multiple',
      required: true,
    },
    {...Separator('card', 'line'), titleKey: 'personMoreInfo'},
    [
      {
        type: 'text',
        name: 'place',
      },
      {
        type: 'codelist',
        name: 'country',
        codelistId: 'COUNTRY',
      },
    ],
    {
      type: 'textarea',
      name: 'externalComments',
      maxLength: 5000,
    },
    {...Separator('card', 'line'), titleKey: 'internalComments'},
    {
      type: 'textarea',
      name: 'internalComments',
      maxLength: 5000,
    },
  ],
};
