import {useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';
import {assert} from 'assert-ts';
import isEqual from 'lodash/isEqual';
import {CodeListMap} from 'api/types';
import {CodeListId} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {isLinkedRoleCodeListId} from 'schemaDefinition';
import {LinkedRoleCodeListId} from 'schemaDefinition/types/linkTypes';
import {getLinkedRoleCodelist} from 'schemas/links/functions';
import {CodelistRestrictor} from '../contexts';
import {codelistsSelector} from '../selectors';

export function useRestrictedCodelists(
  codelistRestrictor: CodelistRestrictor,
  codelistIds: (CodeListId | LinkedRoleCodeListId)[],
): CodeListMap {
  const localization = useLocalization();
  const codelists = useSelector(codelistsSelector);
  const cached = useRef<CodeListMap>();

  return useMemo(() => {
    const codelistMap = codelistIds.reduce<CodeListMap>((acc, id) => {
      if (isLinkedRoleCodeListId(id)) {
        const codelist = getLinkedRoleCodelist(id, localization);
        acc[id] = codelist;
        return acc;
      }

      const codelist = assert(
        codelists[id]?.data,
        `useRestrictedCodelists: codelist ${id} not loaded`,
      );
      acc[id] = codelistRestrictor(codelist);
      return acc;
    }, {});

    if (!isEqual(cached.current, codelistMap)) {
      cached.current = codelistMap;
    }

    return assert(cached.current);
  }, [codelistIds, codelistRestrictor, codelists, localization]);
}
