import React from 'react';
import {Concept} from 'types';
import {PreviewConfiguration} from 'schema/types';
import {ColorPalette} from 'theme';
import {ManifestationV4} from 'api';
import {useLazyManifestationMediaData, useLazyNotes} from 'services/data';
import {noOp} from 'services/utils';
import {Accordion, IconButton, Layout, Spacer} from 'components';
import {Preview} from 'schema';
import {useEntitySchema, usePreviewConfiguration} from 'schemas/hooks';
import {ManifestationStatus} from 'scenes/updateMetadata/components/ManifestationStatus';
import {ManifestationTitle} from 'scenes/updateMetadata/components/ManifestationTitle';

type ManifestationPreviewProps = {
  manifestation: ManifestationV4;
  isExpanded?: boolean;
  configuration?: Pick<
    PreviewConfiguration,
    'linking' | 'onNavigate' | 'layout' | 'relatedScope'
  >;
};

export const ManifestationPreview: React.FC<ManifestationPreviewProps> = ({
  manifestation,
  isExpanded,
  configuration,
}) => {
  const schema = useEntitySchema(Concept.manifestation);

  const eans = manifestation.ean ? [manifestation.ean] : [];
  const {media} = useLazyManifestationMediaData(eans, eans.length > 0);
  const {notes} = useLazyNotes(manifestation.id, true, 'manifestation');

  const mainPreviewConfig = usePreviewConfiguration(
    Concept.manifestation,
    configuration,
  );

  return (
    <Accordion colorx={ColorPalette.lightBeige} initialExpanded={isExpanded}>
      <Accordion.Header>
        <Layout horizontal flex={1} alignItems="space-between">
          <ManifestationTitle manifestation={manifestation} />

          <Layout horizontal paddingRight={2} adjustCenter adjustRight>
            <Spacer width={4} />
            {media.data && media.data.length > 0 ? (
              <>
                <IconButton
                  icon="Files"
                  onClick={noOp}
                  badgeCount={media.data.length}
                />
                <Spacer size={2} />
              </>
            ) : null}
            {notes.data && notes.data.length > 0 ? (
              <>
                <IconButton
                  icon="Comments"
                  onClick={noOp}
                  badgeCount={notes.data.length}
                />
                <Spacer size={2} />
              </>
            ) : null}
            <ManifestationStatus status={manifestation.status} />
          </Layout>
        </Layout>
      </Accordion.Header>
      <Accordion.Content>
        <Layout horizontal flex={1}>
          <Layout flex={2}>
            <Preview
              schema={schema}
              data={manifestation}
              configuration={mainPreviewConfig}
            />
          </Layout>
        </Layout>
      </Accordion.Content>
    </Accordion>
  );
};
