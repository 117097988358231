import {assert} from 'assert-ts';
import {valueAsNumber} from 'services/utils';
import {
  ComparisonCondition,
  ComparisonConditionV0,
  EqComparisonCondition,
  ExpressionCondition,
  GeComparisonCondition,
  GtComparisonCondition,
  LeComparisonCondition,
  LtComparisonCondition,
} from '../types';
import {EvaluationArgs} from './types';
import {evaluateVal} from './evaluateVal';
import {evaluateValWithDefault} from './evaluateValWithDefault';
import {isComparisonConditionV0} from './isConditionType';

/**
 * If any values cannot be converted to argType, then the condition is undefined.
 */
export const evaluateExpressionCondition = (
  condition: ExpressionCondition,
  args: EvaluationArgs,
): boolean =>
  //  | undefined
  {
    if (isComparisonConditionV0(condition)) {
      return evaluateComparisonConditionV0(condition, args);
    } else {
      return evaluateComparisonCondition(condition, args);
    }
  };

/**
 * @obsolete
 */
export const evaluateComparisonConditionV0 = (
  condition: ComparisonConditionV0,
  args: EvaluationArgs,
): boolean =>
  //  | undefined
  {
    const arg1 = evaluateValWithDefault(condition.arg1, args, args.value);
    const arg2 = evaluateVal(condition.arg2, args);

    const {op} = condition;

    // Allows eq operator for any type
    if (op === 'eq') {
      if (arg1 === arg2) return true;
      const num1 = valueAsNumber(arg1);
      const num2 = valueAsNumber(arg2);
      if (num1 !== undefined && num1 === num2) return true;

      return JSON.stringify(arg1) === JSON.stringify(arg2);
    }

    const numArg1 = valueAsNumber(arg1);
    const numArg2 = valueAsNumber(arg2);

    if (numArg1 === undefined || numArg2 === undefined) return false;

    switch (op) {
      case 'lt':
        return numArg1 < numArg2;
      case 'le':
        return numArg1 <= numArg2;
      case 'ge':
        return numArg1 >= numArg2;
      case 'gt':
        return numArg1 > numArg2;
    }

    assert.soft(false, 'evaluateExpressionCondition: unknown operator', {op});
    return false;
  };

export type UnionComparisonCondition = EqComparisonCondition &
  LtComparisonCondition &
  LeComparisonCondition &
  GeComparisonCondition &
  GtComparisonCondition;

export const evaluateComparisonCondition = (
  condition: ComparisonCondition,
  args: EvaluationArgs,
): boolean =>
  //  | undefined
  {
    const union = condition as UnionComparisonCondition;
    const values =
      union.$eq ?? union.$lt ?? union.$le ?? union.$ge ?? union.$gt;
    const arg1 = Array.isArray(values)
      ? evaluateVal(values[0], args)
      : args.value;

    const arg2 = evaluateVal(Array.isArray(values) ? values[1] : values, args);

    const op = union.$eq
      ? 'eq'
      : union.$lt
        ? 'lt'
        : union.$le
          ? 'le'
          : union.$ge
            ? 'ge'
            : 'gt';

    // Allows eq operator for any type
    if (op === 'eq') {
      if (arg1 === arg2) return true;
      const num1 = valueAsNumber(arg1);
      const num2 = valueAsNumber(arg2);
      if (num1 !== undefined && num1 === num2) return true;

      return JSON.stringify(arg1) === JSON.stringify(arg2);
    }

    const numArg1 = valueAsNumber(arg1);
    const numArg2 = valueAsNumber(arg2);

    if (numArg1 === undefined || numArg2 === undefined) return false;

    switch (op) {
      case 'lt':
        return numArg1 < numArg2;
      case 'le':
        return numArg1 <= numArg2;
      case 'ge':
        return numArg1 >= numArg2;
      case 'gt':
        return numArg1 > numArg2;
    }
  };
