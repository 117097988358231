// import {useParams} from 'react-router-dom';
import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {Concept} from 'types';
import {EditorMode} from 'editors/agent/types';
import {useLocalization} from 'localization';
import {Agent, SaveAgentOptions} from 'api';
import {SaveAgentFunc} from 'services/data/agent';
import {FlexBox, Scene} from 'components';
import {EditAgent} from 'editors/agent/components/EditAgent';
import {EditAgentWizard} from 'editors/agent/components/EditAgentWizard';
import {useSceneExtensionsContext} from 'sceneExtensions';
import {AgentTitle} from './components';
import {
  useAgentTitle,
  useAgentUrlParameters,
  useNewOrExistingAgent,
} from './hooks';

export const AgentEditSceneCore: React.FC = () => {
  const {t} = useLocalization();
  const navigate = useNavigate();
  const params = useAgentUrlParameters();
  const [mode, setMode] = React.useState<EditorMode>(
    params.agentId ? 'edit' : 'register',
  );

  const {agent, workSummaries, agentType, suggestion, saveAgent, deleteAgent} =
    useNewOrExistingAgent(params);

  const title = useAgentTitle(mode, agent, agentType);
  const {currentExtension} = useSceneExtensionsContext();

  const handleCancel = useCallback(() => {
    if (params.agentId) {
      navigate(`/agent/${params.agentId}`);
    } else {
      navigate('/agent');
    }
  }, [navigate, params.agentId]);

  const handleSaveNew = useCallback<SaveAgentFunc>(
    (agent: Agent, options: SaveAgentOptions) => {
      return saveAgent(agent, options).then(agent => {
        // Allow snacks to show before navigating
        setTimeout(() => {
          navigate(`/agent/${agent.id}`);
        }, 500);
        return agent;
      });
    },
    [navigate, saveAgent],
  );

  return (
    <Scene
      browserTitle={t('appbar.agents')}
      rightPanelConfig={currentExtension?.panelConfig}>
      <Scene.Content loadStatus={agent?.status} error={agent?.error?.message}>
        <AgentTitle title={title} type={agent?.data?.agentType} />
        <FlexBox sx={{maxWidth: 1200}}>
          {agent && agent.status === 'Loaded' ? (
            // Edit existing agent
            agent.data.agentType === Concept.publisher ? (
              <EditAgent
                originalAgent={agent.data}
                workSummaries={workSummaries?.data}
                onCancel={handleCancel}
                onSave={saveAgent}
              />
            ) : (
              <EditAgentWizard
                originalAgent={agent.data}
                workSummaries={workSummaries?.data}
                onModeChange={setMode}
                onCancel={handleCancel}
                onSave={saveAgent}
                onDelete={deleteAgent}
              />
            )
          ) : agentType ? (
            // Create new agent of given agentType
            agentType === Concept.publisher ? (
              <EditAgent
                agentType={agentType}
                suggestion={suggestion}
                onCancel={handleCancel}
                onSave={handleSaveNew}
              />
            ) : (
              <EditAgentWizard
                agentType={agentType}
                suggestion={suggestion}
                onModeChange={setMode}
                onCancel={handleCancel}
                onSave={handleSaveNew}
                onDelete={deleteAgent}
              />
            )
          ) : null}
        </FlexBox>
      </Scene.Content>
      {currentExtension ? (
        <Scene.RightPanel>
          {currentExtension.renderRightPanel()}
        </Scene.RightPanel>
      ) : null}
    </Scene>
  );
};
