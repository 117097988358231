import {assert} from 'assert-ts';
import {LocalizationContextType} from 'localization/context/types';
import {
  Data,
  DataSimpleValue,
  DataValue,
  PartBool,
  PartDate,
  PartInt,
  PartText,
  PartYearOrDate,
  PartYear,
} from 'schemaDefinition/types';
import {isDate, valueAsYearOrDate} from 'services/utils';
import {formatNullValue} from './formatNullValue';

export const formatScalarValue = (
  value: DataValue,
  part:
    | PartText
    | PartInt
    | PartBool
    | PartYear
    | PartDate
    | PartYearOrDate,
  localization: LocalizationContextType,
  delimiter?: string,
): string => {
  if (
    part.type !== 'bool' &&
    (value === undefined || value === null || value === '')
  ) {
    return formatNullValue();
  }

  if (Array.isArray(value)) {
    return value.length === 0
      ? formatNullValue()
      : value
          // Cannot figure out why type is wrong
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .filter(
            (val: DataSimpleValue | Data) =>
              val !== undefined && val !== null && val !== '',
          )
          .map((val: DataSimpleValue | Data) =>
            formatScalarValue(val, part, localization, delimiter),
          )
          .join(delimiter ?? ', ');
  }

  switch (part.type) {
    case 'date': {
      assert(
        typeof value === 'string' || isDate(value as unknown as Date),
        'formatScalarValue: string or Date expected for date part',
      );
      return localization.formatDate(value as string | Date);
    }
    case 'year': {
      assert(
        typeof value === 'number',
        'formatScalarValue: number expected for year part',
      );
      return localization.formatYear(value);
    }
    case 'yearOrDate': {
      const yearOrDateValue = assert(
        valueAsYearOrDate(value),
        'formatScalarValue: yearOrDate value expected for yearOrDate part',
        {value},
      );
      const approximatePrefix = yearOrDateValue.isApproximate
        ? localization.t('form.global.field.yearOrDate.preview.isApproximate') +
          ' '
        : '';

      const bce = yearOrDateValue.bce
        ? ` ${localization.t('form.global.field.yearOrDate.bce')}`.toLowerCase()
        : '';

      if (yearOrDateValue.date) {
        return (
          approximatePrefix +
          localization.formatDate(yearOrDateValue.date) +
          bce
        );
      }
      if (yearOrDateValue.year) {
        return (
          approximatePrefix +
          localization.formatYear(yearOrDateValue.year) +
          bce
        );
      }
      return formatNullValue();
    }
    case 'bool': {
      return value
        ? localization.t('form.global.type.bool.preview.true')
        : localization.t('form.global.type.bool.preview.false');
    }
    case 'int':
    case 'text':
      return (value ?? formatNullValue()).toString();
    default: {
      assert.soft(false, 'formatScalarValue: unknown part type', part);
      return 'Unknown part type' + JSON.stringify(part);
    }
  }
};
