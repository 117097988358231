import {UserDto} from 'api/types';
import {LockState} from '../contexts/types';
import {LockValue} from './types';
import {isLockedByOtherUser} from './isLockedByOtherUser';

export const getLockStateFromValue = (
  {lock}: LockValue,
  user: Pick<UserDto, 'id'> | null,
): LockState => {
  return lock
    ? isLockedByOtherUser(lock, user)
      ? LockState.LockedByOther
      : LockState.LockedByThis
    : LockState.NotLocked;
};
