import {AgentCorporation, AgentEvent, AgentPerson} from 'api/types';
import {Part} from 'schemaDefinition/types';

type BareAgent = Pick<
  AgentPerson | AgentCorporation | AgentEvent,
  'isni' | 'nationalId'
>;

export const agentBAREParts: Part<BareAgent>[] = [
  {
    type: 'text',
    name: 'isni',
  },
  {
    type: 'text',
    name: 'nationalId',
    readonly: true,
    prefix: 'icon:Verified',
    prefixOnEmpty: 'icon:None',
  },
];
