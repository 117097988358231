import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {FieldError, PartYearOrDate} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {evaluateFieldRequired} from 'schemaDefinition/functions';
import {isNullYearOrDate, isYear, valueAsYearOrDate} from 'services/utils';
import {dateToYear, yearToDate} from 'services/utils/functions/dateToDayJs';
import {Layout, Spacer} from 'components';
import {
  CheckboxField,
  DatePickerField,
  YearPickerField,
} from 'components/fields';
import {useUniqueFieldId} from 'components/fields/hooks/useUniqueFieldId';
import {toFieldError} from 'schema/form/functions/validators';
import {validateSingleYearOrDateValue} from 'schema/form/functions/validators/validateYearOrDate';
import {BaseFieldProps} from './types';
import {useDataFormContext} from '../../contexts';
import {makeYearOrDateValue} from './YearOrDatePicker/mappers';

type Props = BaseFieldProps & {
  part: PartYearOrDate;
};

export const YearOrDatePicker: React.FC<Props> = ({
  part,
  value,
  setFieldValue,
  valuePath,
  label,
  mode,
  width,
  maxWidth,
  flex,
  localScope,
  globalScope,
  relatedScope,
}) => {
  const {t} = useLocalization();
  const [asDate, setAsDate] = useState(() => !isYear(valueAsYearOrDate(value)));
  const [visited, setVisited] = useState(false);
  const handleSetVisited = useCallback(() => setVisited(true), []);

  const required = useMemo(() => {
    return evaluateFieldRequired(
      part.required,
      valuePath,
      localScope,
      globalScope,
      relatedScope,
      value,
    );
  }, [globalScope, part.required, relatedScope, localScope, value, valuePath]);

  const {showErrors} = useDataFormContext();
  const error = useMemo((): FieldError => {
    if (showErrors || visited) {
      const valid = validateSingleYearOrDateValue(part, {
        value,
        valuePath,
        localScope,
        globalScope,
        relatedScope,
      }).valid;
      return toFieldError(valid);
    }
    return false;
  }, [
    globalScope,
    part,
    relatedScope,
    localScope,
    showErrors,
    value,
    valuePath,
    visited,
  ]);

  // When  value changes from outside
  // => update asDate
  useEffect(() => {
    setAsDate(prev => {
      const yearOrDate = valueAsYearOrDate(value);
      return isNullYearOrDate(yearOrDate) ? prev : !isYear(yearOrDate);
    });
  }, [value]);

  const {year, date, bce, isApproximate} = useMemo(() => {
    const yearOrDate = valueAsYearOrDate(value);

    return {
      year: asDate ? null : yearOrDate?.year ?? null,
      date: asDate ? yearOrDate?.date ?? null : null,
      bce: yearOrDate?.bce ?? null,
      isApproximate: yearOrDate?.isApproximate ?? null,
    };
  }, [asDate, value]);

  const handleToggleDate = useCallback(
    (includeDate: boolean) => {
      setAsDate(includeDate);
      if (includeDate) {
        setFieldValue(
          valuePath,
          makeYearOrDateValue(
            null,
            yearToDate(year) ?? null,
            bce,
            isApproximate,
          ),
        );
      } else {
        setFieldValue(
          valuePath,
          makeYearOrDateValue(
            dateToYear(date) ?? null,
            null,
            bce,
            isApproximate,
          ),
        );
      }
    },
    [setFieldValue, valuePath, year, bce, isApproximate, date],
  );

  const handleToggleApproximate = useCallback(() => {
    setFieldValue(
      valuePath,
      makeYearOrDateValue(year, date, bce, !isApproximate),
    );
  }, [setFieldValue, valuePath, year, date, bce, isApproximate]);

  const handleToggleBce = useCallback(() => {
    setFieldValue(
      valuePath,
      makeYearOrDateValue(year, date, !bce, isApproximate),
    );
  }, [setFieldValue, valuePath, year, date, bce, isApproximate]);

  const includeDataFieldId = useUniqueFieldId(valuePath + '.includeDate');
  const bceFieldId = useUniqueFieldId(valuePath + '.bce');
  const isApproximateFieldId = useUniqueFieldId(valuePath + '.isApproximate');

  return (
    <Layout adjustLeft>
      {asDate ? (
        <DatePickerField
          name={valuePath}
          label={label}
          value={date}
          readonly={mode === 'read-only'}
          // Must always show field, otherwise strange look
          showWhenReadonlyAndEmpty={true}
          required={required}
          error={error}
          width={width}
          maxWidth={maxWidth}
          flex={flex}
          onChange={newDate => {
            setFieldValue(
              valuePath,
              makeYearOrDateValue(null, newDate, bce, isApproximate),
            );
          }}
          onBlur={handleSetVisited}
        />
      ) : (
        <YearPickerField
          name={valuePath}
          label={label}
          value={year}
          readonly={mode === 'read-only'}
          // Must always show field, otherwise strange look
          showWhenReadonlyAndEmpty={true}
          required={required}
          error={error}
          width={width}
          maxWidth={maxWidth}
          flex={flex}
          onChange={newYear => {
            setFieldValue(
              valuePath,
              makeYearOrDateValue(newYear, null, bce, isApproximate),
            );
          }}
          onBlur={handleSetVisited}
        />
      )}
      <Spacer size={0.5} />
      <CheckboxField
        fieldId={includeDataFieldId}
        name={valuePath + '.includeDate'}
        boxLabel={t('form.global.field.yearOrDate.includeDate')}
        value={asDate}
        onChange={handleToggleDate}
      />
      {part.includeBce ? (
        <CheckboxField
          fieldId={bceFieldId}
          name={valuePath + '.bce'}
          boxLabel={t('form.global.field.yearOrDate.bce')}
          value={bce}
          onChange={handleToggleBce}
        />
      ) : null}
      {part.includeApproximate ? (
        <CheckboxField
          fieldId={isApproximateFieldId}
          name={valuePath + '.isApproximate'}
          boxLabel={t('form.global.field.yearOrDate.isApproximate')}
          value={isApproximate}
          onChange={handleToggleApproximate}
        />
      ) : null}
    </Layout>
  );
};
