import {useCallback, useMemo, useRef, useState} from 'react';
import assert from 'assert-ts';
import {AgentSubType} from 'types';
import {Agent} from 'api/types';
import {CodeListId, Schema} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {LinkedRoleCodeListId} from 'schemaDefinition/types/linkTypes';
import {useCodelists} from 'services/codeLists';
import {areAgentsEqual} from 'services/data/agent';
import {getErrorMessage} from 'services/utils/getErrorMessage';
import {useSnacks} from 'components';
import {validate} from 'schema/form/functions/validators/validate';
import {useSchemaCodelistIds} from 'schema/hooks';
import {useAgentSchema} from 'schemas/hooks';
import {EditAgentProps, EditorMode} from '../types';
import {createEmptyAgent, getSavedAgentMessage} from '../functions';

type EditAgentState = {
  agentType: AgentSubType;
  schema: Schema;
  codelistIds: (CodeListId | LinkedRoleCodeListId)[];
  mode: EditorMode;
  // Initial value of agent when going to edit mode
  initialEditAgent: Agent;
  hasChanges: boolean;
  showErrors: boolean;
  onChanged: (agent: Agent) => void;
  // Consider support for confirm save with warnings
  save: () => Promise<void>;
};

export const useEditAgentState = ({
  agentType,
  suggestion,
  originalAgent,
  onSave,
}: EditAgentProps): EditAgentState => {
  const mode: EditorMode = originalAgent ? 'edit' : 'register';
  const resolvedType = assert(
    agentType ?? originalAgent?.agentType,
    'agentType is required',
  );
  const localization = useLocalization();
  const {t} = localization;
  const schema = useAgentSchema(resolvedType);
  const codelistIds = useSchemaCodelistIds(schema);
  const codelistMap = useCodelists(codelistIds);

  const [initialEditAgent, setInitialEditAgent] = useState(
    originalAgent ?? createEmptyAgent(resolvedType, suggestion),
  );

  const editedAgentRef = useRef<Agent>(initialEditAgent);
  const [hasChanged, setHasChanged] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const {successSnack, errorSnack} = useSnacks();

  const handleChanged = useCallback(
    (agent: Agent) => {
      editedAgentRef.current = agent;
      setHasChanged(!areAgentsEqual(initialEditAgent, editedAgentRef.current));
    },
    [initialEditAgent],
  );

  const handleSave = useCallback((): Promise<void> => {
    const validation = validate(
      editedAgentRef.current,
      undefined,
      schema,
      assert(codelistMap, 'useEditAgentWizard: codelistMap is required'),
    );

    if (validation.valid !== 'valid') {
      setShowErrors(true);
      return Promise.resolve();
    }

    return onSave(assert(editedAgentRef.current), 'bbOnly')
      .then(savedAgent => {
        const msg = getSavedAgentMessage(mode, t);
        successSnack(msg);
        setInitialEditAgent(savedAgent);
      })
      .catch(error => {
        const message = getErrorMessage(
          error,
          'page.agents.save.failed',
          localization,
        );
        errorSnack(message);
      });
  }, [
    schema,
    codelistMap,
    onSave,
    mode,
    t,
    successSnack,
    localization,
    errorSnack,
  ]);

  return useMemo(() => {
    return {
      agentType: resolvedType,
      schema,
      codelistIds,
      mode,
      // Initial value of agent when going to edit mode
      initialEditAgent,
      hasChanges: mode === 'register' || hasChanged,
      showErrors,
      onChanged: handleChanged,
      save: handleSave,
    };
  }, [
    resolvedType,
    schema,
    codelistIds,
    mode,
    initialEditAgent,
    hasChanged,
    showErrors,
    handleChanged,
    handleSave,
  ]);
};
