import {assert} from 'assert-ts';
import {Condition, Part, PartExpand, Schema, SeparatedParts} from '../types';
import {ValueContext, ValueVisitor} from './types';
import {isArg1AndArg2} from './isArg1AndArg2';
import {
  isAndCondition,
  isAndConditionV0,
  isBooleanCondition,
  isComparisonConditionV0,
  isEqComparisonCondition,
  isGeComparisonCondition,
  isGtComparisonCondition,
  isIncludesCondition,
  isLeComparisonCondition,
  isLengthCondition,
  isLtComparisonCondition,
  isNotCondition,
  isNotConditionV0,
  isOrCondition,
  isOrConditionV0,
  isRangeCondition,
  isRegexCondition,
} from './isConditionType';
import {isFieldRequiredConditional} from './isFieldRequiredConditional';

type Nesting = 'single' | 'recursive';

export const visitSchemaValues = (
  mutableSchema: Schema,
  visitor: ValueVisitor,
  context?: ValueContext,
) => {
  const ctx = context ?? {scopes: []};
  ctx.scopes.push([]);
  visitPartsValues(mutableSchema.parts, visitor, ctx, false);
  ctx.scopes.pop();
};

export const visitPartValues = (
  part: Part,
  visitor: ValueVisitor,
  context: ValueContext,
  nesting: Nesting = 'recursive',
) => {
  visitConditionValues(part.listValidation, visitor, context);

  if (isFieldRequiredConditional(part.required)) {
    visitConditionValues(part.required.required, visitor, context);
    visitConditionValues(part.required.should, visitor, context);
  }

  switch (part.type) {
    case 'bool':
    case 'html':
    case 'codelist|text':
    case 'nameVariant': {
      return;
    }
    case 'int':
    case 'text':
    case 'textarea':
    case 'year':
    case 'date':
    case 'yearOrDate':
    case 'codelist':
    case 'linkedAgent':
    case 'linkedLiterary':
    case 'thesaurus': {
      return visitConditionValues(part.validation, visitor, context);
    }
    case 'expand': {
      visitPartExpandValues(part, visitor, context, nesting);
      return;
    }
    case 'schema': {
      if (nesting === 'recursive') {
        visitSchemaValues(part, visitor, context);
      }
      return;
    }
  }

  assert(false, 'visitPart');
};

export const visitPartsValues = (
  parts: SeparatedParts = [],
  visitor: ValueVisitor,
  context: ValueContext,
  referableOutside: boolean,
) => {
  let ctx = context;
  parts.forEach(part => {
    if (Array.isArray(part)) {
      visitPartsValues(part, visitor, context, referableOutside);
    } else if (part.type !== 'separator') {
      ctx = extendContext(ctx, part, referableOutside);
      visitPartValues(part, visitor, ctx);
    }
  });
};

export const visitConditionValues = (
  condition: Condition | Condition[] | undefined,
  visitor: ValueVisitor,
  context: ValueContext,
) => {
  if (condition === undefined) return;
  // Array?
  if (Array.isArray(condition)) {
    condition.forEach(c => visitConditionValues(c, visitor, context));
    return;
  }

  if (isRangeCondition(condition)) {
    visit(condition, 'arg1', visitor, context);
    visit(condition, 'min', visitor, context);
    visit(condition, 'max', visitor, context);
    return;
  }

  if (isLengthCondition(condition)) {
    visit(condition, 'arg1', visitor, context);
    visit(condition, 'minLength', visitor, context);
    visit(condition, 'maxLength', visitor, context);
    return;
  }

  if (isComparisonConditionV0(condition)) {
    visit(condition, 'arg1', visitor, context);
    visit(condition, 'arg2', visitor, context);
    return;
  }

  if (isEqComparisonCondition(condition)) {
    visit(condition, '$eq', visitor, context);
    return;
  }

  if (isLtComparisonCondition(condition)) {
    visit(condition, '$lt', visitor, context);
    return;
  }

  if (isLeComparisonCondition(condition)) {
    visit(condition, '$le', visitor, context);
    return;
  }

  if (isGeComparisonCondition(condition)) {
    visit(condition, '$ge', visitor, context);
    return;
  }

  if (isGtComparisonCondition(condition)) {
    visit(condition, '$gt', visitor, context);
    return;
  }

  if (isBooleanCondition(condition) || isRegexCondition(condition)) {
    visit(condition, 'arg', visitor, context);
    return;
  }

  if (
    isNotConditionV0(condition) ||
    isOrConditionV0(condition) ||
    isAndConditionV0(condition)
  ) {
    visitConditionValues(condition.arg, visitor, context);
    return;
  }

  if (isNotCondition(condition)) {
    visitConditionValues(condition.$not, visitor, context);
    return;
  }

  if (isOrCondition(condition)) {
    visitConditionValues(condition.$or, visitor, context);
    return;
  }

  if (isAndCondition(condition)) {
    visitConditionValues(condition.$and, visitor, context);
    return;
  }

  if (isIncludesCondition(condition)) {
    if (isArg1AndArg2(condition.$includes)) {
      visit(condition.$includes, 'arg1', visitor, context);
      visit(condition.$includes, 'arg2', visitor, context);
    } else {
      visit(condition, '$includes', visitor, context);
    }
    return;
  }

  assert.soft(false, 'visitSchemaCondition: unknown condition', {condition});

  return false;
};

export const visitPartExpandValues = (
  expand: PartExpand,
  visitor: ValueVisitor,
  context: ValueContext,
  nesting: Nesting = 'recursive',
) => {
  expand.when.forEach(w => {
    visitConditionValues(w.condition, visitor, context);
    if (nesting === 'recursive') {
      visitPartsValues(w.parts, visitor, context, false);
    }
  });

  if (nesting === 'recursive') {
    visitPartsValues(expand.default, visitor, context, false);
  }
};

const extendContext = (
  context: ValueContext,
  part: Part,
  /** true: add to given context, false: create new and extend */
  referableOutside: boolean,
): ValueContext => {
  const {type, name} = part;
  if (type === 'schema' || !name) return context;

  const ctx = referableOutside
    ? context
    : (JSON.parse(JSON.stringify(context)) as ValueContext);
  ctx.scopes[ctx.scopes.length - 1].push(name as string);
  return ctx;
};

/**
 * Visits value(s) of objWithVals[key] and replaces with new value
 * objWithVals[key] may be a single value, an array of values or
 * a nested array of values. Hence, objWithVals may be either an
 * regular object or an array.
 * @param objWithVals
 * @param key property key or array index
 * @param visitor
 * @param context
 */
const visit = <T, TKey extends keyof T>(
  objWithVals: T,
  key: TKey,
  visitor: ValueVisitor,
  context: ValueContext,
) => {
  const val = objWithVals[key];
  if (val !== undefined) {
    if (Array.isArray(val)) {
      val.forEach((_, idx) => {
        visit(val, idx, visitor, context);
      });
    } else {
      visitor(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        val,
        newVal => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          objWithVals[key] = newVal;
        },
        context,
      );
    }
  }
};
