import React, {Fragment} from 'react';
import {assert} from 'assert-ts';
import {Data, Part} from 'schemaDefinition/types';
import {isMultiple} from 'schema/form/functions';
import {BasePartProps} from './types';
import {usePreviewConfigurationContext} from '../contexts';
import {useIsPartExcluded, usePartLayout, usePartRenderer} from '../hooks';
import {PreviewLinkedLiteralByRoles} from './PreviewLinkedLiteralByRoles';
import {PreviewLinkedValueByRoles} from './PreviewLinkedValueByRoles';
import {PreviewNameVariant} from './PreviewNameVariant';
import {PreviewPartExpand} from './PreviewPartExpand';
import {PreviewSimpleValue} from './PreviewSimpleValue';
import {PreviewTable} from './PreviewTable';
import {SingleValue} from './fields';
import {CodeListValue} from './fields/CodeListValue';
import {ThesaurusItemValue} from './fields/ThesaurusItemValue';
import {renderSchemaFragment} from './renderSchemaFragment';

export type PreviewPartProps = BasePartProps<Part | undefined>;

export const PreviewPart: React.FC<PreviewPartProps> = ({
  part,
  value,
  valuePath,
  scope,
  globalScope,
  relatedScope,
  layoutDirection,
}) => {
  const config = usePreviewConfigurationContext();
  const isExcluded = useIsPartExcluded(part);
  const PartRenderer = usePartRenderer(part);
  const layout = usePartLayout(part);

  if (isExcluded || !part) {
    return null;
  }

  if (PartRenderer) {
    return (
      <SingleValue
        part={part}
        layoutDirection={layoutDirection}
        renderValue={() => <PartRenderer part={part} value={value} />}
      />
    );
  }

  switch (part.type) {
    // Simple values: label and comma-separated values or lines of values
    case 'text':
    case 'textarea':
    case 'html':
    case 'int':
    case 'bool':
    case 'year':
    case 'date':
    case 'yearOrDate': {
      return (
        <PreviewSimpleValue
          part={part}
          value={value}
          valuePath={valuePath}
          scope={scope}
          globalScope={globalScope}
          relatedScope={relatedScope}
          layoutDirection={layoutDirection}
        />
      );
    }
    case 'linkedAgent': {
      return (
        <PreviewLinkedValueByRoles
          part={part}
          value={value}
          valuePath={valuePath}
          scope={scope}
          globalScope={globalScope}
          relatedScope={relatedScope}
          layoutDirection={layoutDirection}
        />
      );
    }
    case 'linkedLiterary': {
      return (
        <PreviewLinkedLiteralByRoles
          part={part}
          value={value}
          valuePath={valuePath}
          scope={scope}
          globalScope={globalScope}
          relatedScope={relatedScope}
          layoutDirection={layoutDirection}
        />
      );
    }
    // CodeList values: label and comma-separated values from codelist
    case 'codelist':
      return (
        <CodeListValue
          part={part}
          value={value}
          layoutDirection={layoutDirection}
        />
      );
    case 'nameVariant': {
      return (
        <PreviewNameVariant
          part={part}
          valuePath={valuePath}
          value={value}
          scope={scope}
          globalScope={globalScope}
          relatedScope={relatedScope}
          layoutDirection={layoutDirection}
        />
      );
    }
    case 'thesaurus': {
      return (
        <ThesaurusItemValue
          part={part}
          value={value}
          layoutDirection={layoutDirection}
        />
      );
    }
    case 'expand': {
      return (
        <PreviewPartExpand
          part={part}
          valuePath={valuePath}
          value={value as Data}
          scope={scope}
          globalScope={globalScope}
          relatedScope={relatedScope}
          layoutDirection={layoutDirection}
        />
      );
    }
    case 'schema': {
      return isMultiple(part.cardinality) ? (
        Array.isArray(value) ? (
          part.variant === 'table' ? (
            <PreviewTable
              part={part}
              valuePath={valuePath}
              value={value as Data[]}
              scope={scope}
              globalScope={globalScope}
              relatedScope={relatedScope}
            />
          ) : (
            <Fragment>
              {value.map((item, index) => (
                <Fragment key={index}>
                  {renderSchemaFragment(
                    (item ?? {}) as Data,
                    valuePath,
                    part.parts,
                    layout?.direction ?? 'vertical',
                    config,
                  )}
                </Fragment>
              ))}
            </Fragment>
          )
        ) : null
      ) : (
        <>
          {renderSchemaFragment(
            (value ?? {}) as Data,
            valuePath,
            part.parts,
            layout?.direction ?? 'vertical',
            config,
          )}
        </>
      );
    }
    case 'codelist|text': {
      assert.soft(false, `Preview: Not implemented: ${part.type}`);
      return null;
    }
    default: {
      assert(false, `Unknown part`, {part});
      return null;
    }
  }
};
