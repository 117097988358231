import {useMemo, useRef} from 'react';
import isEqual from 'lodash/isEqual';
import {Schema} from 'schemaDefinition/types';
import {CodeListId, LocalCodeListId} from 'api';
import {getPartCodelistId, visitSchemaParts} from 'schemaDefinition/functions';
import {
  AllLocalCodeListIds,
  LinkedRoleCodeListId,
} from 'schemaDefinition/types/linkTypes';

export const useSchemaCodelistIds = (
  schema?: Schema,
): (CodeListId | LinkedRoleCodeListId)[] => {
  const codelistsRef = useRef<(CodeListId | LinkedRoleCodeListId)[]>([]);

  const result = useMemo(() => {
    const codelists: (CodeListId | LinkedRoleCodeListId)[] = [];

    if (!schema) {
      return codelists;
    }

    visitSchemaParts(schema, part => {
      const codelistIds = getPartCodelistId(part);
      codelistIds?.forEach(codelistId => {
        if (
          codelistId &&
          !AllLocalCodeListIds.includes(codelistId as LocalCodeListId) &&
          !codelists.includes(codelistId)
        ) {
          codelists.push(codelistId);
        }
      });
    });

    if (!isEqual(codelists, codelistsRef.current)) {
      codelistsRef.current = codelists;
    }

    return codelistsRef.current;
  }, [schema]);

  return result;
};
