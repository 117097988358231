import {useCallback} from 'react';
import {useLocalization} from 'localization';
import {Lock} from 'api';
import {postLock} from 'api/lock';
import {useGetTokens} from 'services/auth';
import {useSnacks} from 'components/snacks';

type PostLock = () => Promise<Lock | undefined>;
/**
 * Posts lock for work and returns lock object or undefined if failed.
 */

export const usePostLockWithErrorHandling = (workId: string): PostLock => {
  const {t} = useLocalization();

  const getTokens = useGetTokens();
  const {warningSnack} = useSnacks();

  return useCallback((): Promise<Lock | undefined> => {
    return postLock(workId, 'work', getTokens, false)
      .then(lock => {
        return lock;
      })
      .catch(() => {
        // If lock fails, still allow editing with warning
        warningSnack(t('page.metadata.lock.message.lockFailed'));
        return undefined;
      });
  }, [getTokens, t, warningSnack, workId]);
};
