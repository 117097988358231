import {SxProps} from '@mui/material';
import {SearchMatch} from 'services/thesaurus/types';
import {ColorPalette} from 'theme';
import {useThesaurusEditStateContext} from '../contexts';
import {ThesaurusNodeButton} from './ThesaurusNodeButton';

type Props = {
  match: SearchMatch;
};

const sxNodeButton: SxProps = {
  borderRadius: 1,
  background: ColorPalette.lightBeige,
  py: 1,
  pl: 1,
  pr: 2,
};

const sxNodeContent: SxProps = {
  pl: 1,
};

export const SearchingTopLevelMatch: React.FC<Props> = ({match}) => {
  const {searchQueryText, gotoSearchMatch} = useThesaurusEditStateContext();

  const {hasTitleMatch, hasNoteMatch, hasSynonymMatch} = match;

  const showNoteMatch = hasNoteMatch && !(hasTitleMatch || hasSynonymMatch);
  const showSynonymMatch = hasSynonymMatch && !hasTitleMatch;
  const icon =
    showSynonymMatch || showNoteMatch
      ? showSynonymMatch
        ? 'DoubleChevronRight'
        : 'CommentsFilled'
      : undefined;

  return (
    <ThesaurusNodeButton
      node={match}
      prefixIcon={icon}
      searchQuery={searchQueryText}
      onGotoNode={() => gotoSearchMatch(match)}
      sx={sxNodeButton}
      innerSx={sxNodeContent}
    />
  );
};
