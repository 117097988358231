import React, {useMemo} from 'react';
import {
  DataValue,
  PartCodeList,
  PartCodeListOrText,
} from 'schemaDefinition/types';
import {useFormSchemaGlobalScopeContext} from 'schema/contexts';
import {BasePartSimpleProps} from './types';
import {useDataFormContext} from '../contexts';
import {
  useLabelAndPlaceholder,
  useLayout,
  useResolvedDataFormPartConfiguration,
  verifyGlobalScope,
  verifyLocalScope,
} from '../hooks';
import {Select} from './fields';

type DataFormMultiValueProps = BasePartSimpleProps & {
  part: PartCodeList | PartCodeListOrText;
  // error?: boolean;
  // helperText?: string;
};

export const DataFormMultiValue: React.FC<DataFormMultiValueProps> = ({
  part,
  useValue,
  valuePath,
  scopePath,
  usesGlobalScope,
  relatedScope,
  mode,
  noLabel,
}) => {
  const {id, setEditValue} = useDataFormContext();
  const {showWhenReadonlyAndEmpty} = useResolvedDataFormPartConfiguration(part);
  const value = useValue(valuePath, id, part.name) as DataValue;
  const localScope = verifyLocalScope(
    useValue(scopePath, id, `${part.name}.localScope`),
    scopePath,
  );
  const {valuePath: globalPath} = useFormSchemaGlobalScopeContext();
  const globalScope = verifyGlobalScope(
    useValue(
      usesGlobalScope ? globalPath : undefined,
      id,
      `${part.name}.globalScope`,
    ),
    usesGlobalScope,
  );
  const {label, placeholder} = useLabelAndPlaceholder(part, noLabel);
  const layout = useLayout(part);

  return useMemo(() => {
    switch (part.type) {
      case 'codelist': {
        return (
          <Select
            part={part}
            value={value}
            setFieldValue={setEditValue}
            valuePath={valuePath}
            label={label}
            placeholder={placeholder}
            localScope={localScope}
            globalScope={globalScope}
            relatedScope={relatedScope}
            mode={mode}
            showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
            {...layout}
          />
        );
      }
      case 'codelist|text': {
        // TODO: Support
        return null;
      }
      default:
        return null;
    }
  }, [
    part,
    value,
    setEditValue,
    valuePath,
    label,
    placeholder,
    localScope,
    globalScope,
    relatedScope,
    mode,
    showWhenReadonlyAndEmpty,
    layout,
  ]);
};
