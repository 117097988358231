import {ThesaurusNode} from 'api/types';
import {ThesaurusValue} from 'schemaDefinition/types';
import {SearchMatch} from 'services/thesaurus/types';
import {getCategoryNode} from './getCategoryNode';
import {getQueryParts} from './getQueryParts';
import {getSearchMatches} from './getSearchMatches';

export const searchThesaurus = (
  /** Search query, may start with 'code:' to limit search to categories starting with code */
  query: string,
  thesaurusValue: ThesaurusValue | undefined,
  getDisplayCode: (node: ThesaurusNode) => string | undefined,
  thesaurus: ThesaurusNode,
  ancestors: ThesaurusNode[] = [],
): SearchMatch[] => {
  const {codeQuery, textQuery} = getQueryParts(query);
  if (textQuery === undefined) {
    return [];
  }

  /** Category or whole thesaurus */
  const [thesaurusCategory, categoryAncestors] = codeQuery
    ? getCategoryNode(codeQuery, getDisplayCode, thesaurus, ancestors) ?? [
        thesaurus,
        ancestors,
      ]
    : [thesaurus, ancestors];

  const result = searchThesaurusCore(
    textQuery,
    thesaurusValue,
    getDisplayCode,
    thesaurusCategory,
    categoryAncestors,
  );
  // Remove duplicate nodes
  const unique: {[id: string]: SearchMatch} = {};
  result.forEach(match => {
    unique[match.node.id] = unique[match.node.id] ?? match;
  });
  return Object.values(unique);
};

export const searchThesaurusCore = (
  textQuery: string,
  thesaurusValue: ThesaurusValue | undefined,
  getDisplayCode: (node: ThesaurusNode) => string | undefined,
  thesaurus: ThesaurusNode,
  ancestors: ThesaurusNode[] = [],
): SearchMatch[] => {
  const itemMatches = getSearchMatches(
    textQuery,
    thesaurusValue,
    getDisplayCode,
    thesaurus,
    ancestors,
  );
  const subPath = [...ancestors, thesaurus];
  const childrenMatches =
    thesaurus.children
      ?.map(child =>
        searchThesaurusCore(
          textQuery,
          thesaurusValue,
          getDisplayCode,
          child,
          subPath,
        ),
      )
      .flatMap(childMatches => childMatches) ?? [];
  const result = [...itemMatches, ...childrenMatches];
  return result;
};
