import {Stack} from '@mui/material';
import {LayoutDirection, PreviewConfiguration} from 'schema/types';
import {Data, SeparatedParts} from 'schemaDefinition/types';
import {isEmptyValue} from 'services/utils';
import {Ruler} from 'components';
import {PreviewPartWrapper} from './PreviewPartWrapper';

/**
 * Renders a vertical list of schema parts/sublist of parts,
 * where sublists of parts renders horizontally
 */
export const renderSchemaFragment = (
  value: Data,
  valuePath: string,
  fragment: SeparatedParts,
  layoutDirection: LayoutDirection,
  config: PreviewConfiguration,
) => {
  return fragment.map((part, idx) => {
    if (!Array.isArray(part) && part.type === 'separator') {
      return part.preview === 'line' && idx > 0 && idx < fragment.length - 1 ? (
        <Ruler key={idx} spacing={1} indentation={1} />
      ) : null;
    }
    if (Array.isArray(part)) {
      return part.length > 0 ? (
        <Stack key={part[0].name} direction={'row'} spacing={2}>
          {renderSchemaFragment(
            value,
            valuePath,
            part,
            layoutDirection,
            config,
          )}
        </Stack>
      ) : null;
    } else {
      const partValue = part.name ? value[part.name] ?? null : value;
      if (isEmptyValue(partValue)) {
        return null;
      }
      const partValuePath = ((valuePath
        ? valuePath + '.' + part.name
        : part.name) ?? '') as string;
      return (
        <PreviewPartWrapper
          key={part.name ?? part.role}
          part={part}
          value={partValue}
          relatedScope={config.relatedScope}
          valuePath={partValuePath}
          layoutDirection={layoutDirection}
        />
      );
    }
  });
};
