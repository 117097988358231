import * as React from 'react';
import {SxProps} from '@mui/material';
import {FooterPropsOverrides} from '@mui/x-data-grid';
import {
  GridFooterContainer,
  gridPageSizeSelector,
  gridRowCountSelector,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import {PageSizeProps, PaginatorProps} from '../../paginator/types';
import {PageSizePicker} from '../../paginator';
import {usePaginator} from '../hooks/usePaginator';

declare module '@mui/x-data-grid' {
  interface FooterPropsOverrides {
    paginatorProps?: PaginatorProps;
    pageSizeProps?: PageSizeProps;
  }
}

const containerSx: SxProps = {
  flex: 1,
  justifyContent: 'end',
  p: 1,
};

export function CustomFooter({
  paginatorProps,
  pageSizeProps,
}: FooterPropsOverrides) {
  const apiRef = useGridApiContext();
  const pageSize = gridPageSizeSelector(apiRef);
  const total = gridRowCountSelector(apiRef);

  const paginator = usePaginator({paginatorProps, apiRef});

  return paginatorProps ? (
    <GridFooterContainer sx={containerSx}>
      {pageSizeProps ? <PageSizePicker {...pageSizeProps} /> : null}
      {paginator}
    </GridFooterContainer>
  ) : (
    <GridFooterContainer sx={containerSx}>
      {/* Hack for overview table */}
      {
        <PageSizePicker
          size={pageSize}
          pageSizes={[20, 50, 100, 200]}
          setSize={size => apiRef.current.setPageSize(size)}
          total={total}
          allowAll
        />
      }
      {paginator}
    </GridFooterContainer>
  );
}
