import {Part} from 'schemaDefinition/types';
import {usePreviewConfigurationContext} from '../contexts';

export const usePartRenderer = (part: Part | undefined) => {
  const config = usePreviewConfigurationContext();
  if (!part) {
    return undefined;
  }

  const partKey = part.labelKey ?? part.name;
  const PartRenderer = partKey
    ? config.partValueRenderers?.[partKey]
    : undefined;

  return PartRenderer;
};
