import {useSelector} from 'react-redux';

type SetValueAction<TKey extends string, T> = {
  type: `SET_${TKey}_VALUE`;
  payload: {
    value: T;
  };
};

type DeleteValueAction<TKey extends string> = {
  type: `DELETE_${TKey}_VALUE`;
};

type Action<TKey extends string, T> =
  | SetValueAction<TKey, T>
  | DeleteValueAction<TKey>;

type SimpleRedcuer<TKey extends string, T> = (
  state: T | undefined,
  action: Action<TKey, T>,
) => T;

export const valueReducerFactory = <T, TKey extends string>(
  key: TKey,
  defaultValue: T,
): [
  appStateKey: TKey,
  reducer: SimpleRedcuer<TKey, T>,
  setAction: (value: T) => SetValueAction<TKey, T>,
  deleteAction: () => DeleteValueAction<TKey>,
  hook: () => T,
] => {
  const reducer: SimpleRedcuer<TKey, T> = (
    state: T | undefined,
    action: Action<TKey, T>,
  ): T => {
    switch (action.type) {
      case `SET_${key}_VALUE`: {
        return (action as SetValueAction<TKey, T>).payload.value;
      }
      case `DELETE_${key}_VALUE`: {
        return defaultValue;
      }
    }

    return state ?? defaultValue;
  };

  const setAction = (value: T): SetValueAction<TKey, T> => {
    return {
      type: `SET_${key}_VALUE`,
      payload: {value},
    };
  };

  const deleteAction = (): DeleteValueAction<TKey> => {
    return {
      type: `DELETE_${key}_VALUE`,
    };
  };

  const selector = (state: {[key in TKey]: T}): T => {
    return state[key];
  };

  const useValue = () => useSelector(selector);

  return [key, reducer, setAction, deleteAction, useValue];
};
