import {assert} from 'assert-ts';
import {Concept, DerivedConcept} from 'types';
import {Schema} from 'schemaDefinition/types';
import {SchemaReference} from 'schemas/types';
import {workCollectionSchema} from 'schemas/schemas/workCollectionSchema';
import {workSummarySchema} from 'schemas/schemas/workSummarySchema';
import {
  agentCorporationSchema,
  agentEventSchemas,
  agentPersonSchema,
  agentPublisherSchema,
  expressionDataSchema,
  manifestationDataSchema,
  publisherSeriesSchema,
  workDataSchema,
} from '../schemas';

export const getSchema = (reference: SchemaReference): Schema => {
  switch (reference) {
    case Concept.work:
      return workDataSchema;
    case Concept.expression:
      return expressionDataSchema;
    case Concept.manifestation:
      return manifestationDataSchema;
    case DerivedConcept.workSummary:
      return workSummarySchema;
    default: {
      const {entity, subType} = reference;
      switch (entity) {
        case Concept.agent: {
          switch (subType) {
            case Concept.person:
              return agentPersonSchema;
            case Concept.publisher:
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              return agentPublisherSchema;
            case Concept.corporation:
              return agentCorporationSchema;
            case Concept.event:
              return agentEventSchemas;
            default:
              assert(
                false,
                `useSchema: Unknown subtype: ${subType} of ${entity}`,
              );
          }
          break;
        }
        case Concept.collection: {
          switch (subType) {
            case Concept.series:
            case Concept.educationalSeries:
            case Concept.otherConnection:
              return workCollectionSchema;
            case Concept.publisherSeries:
              return publisherSeriesSchema;
            default:
              assert(
                false,
                `useSchema: Unknown subtype: ${subType} of ${entity}`,
              );
          }
          break;
        }

        default: {
          assert(false, `getSchema: Unknown entity type: ${entity}`);
        }
      }
    }
  }
};
