import {useCallback} from 'react';
import {Media} from 'api/types';
import {useLocalization} from 'localization';
import {getMediaUrl} from 'api/media';
import {useGetTokens} from 'services/auth';
import {useSnacks} from 'components/snacks';

export const usePreviewFile = (
  file: Media,
  mock?: boolean,
): {
  preview: () => Promise<void>;
} => {
  const {t} = useLocalization();
  const token = useGetTokens();
  const {errorSnack} = useSnacks();

  const preview = useCallback(() => {
    return getMediaUrl(file.id, token, mock)
      .then(url => {
        window.open(url, '_blank');
      })
      .catch(() => {
        errorSnack(t('error.files.failedToOpen'));
      });
  }, [token, errorSnack, file.id, mock, t]);

  return {
    preview,
  };
};
