import {PreviewConfiguration} from 'schema/types';
import {SeparatedPart} from 'schemaDefinition/types';
import {isPartExcluded} from './isPartExcluded';

/**
 * Filters out parts excluded or not included in config
 */
export const getFilteredSchemaParts = (
  parts: SeparatedPart[],
  config: PreviewConfiguration,
): SeparatedPart[] => {
  const filteredParts = parts.reduce<SeparatedPart[]>((acc, part) => {
    if (Array.isArray(part)) {
      const filteredRowParts = part.filter(p => !isPartExcluded(p, config));
      if (filteredRowParts.length > 0) {
        acc.push(filteredRowParts);
      }
      return acc;
    }

    if (part.type === 'separator' || !isPartExcluded(part, config)) {
      acc.push(part);
    }

    return acc;
  }, []);

  return filteredParts;
};
