import React, {
  createRef,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {ThesaurusAction} from 'types';
import {ThesaurusFieldConfig} from 'sceneExtensions/extensions/thesaurusEdit/types';
import {SetSourceValue} from 'sceneExtensions/types';
import {
  ShowThesaurusCode,
  ThesaurusId,
  ThesaurusValue as ThesaurusValueType,
} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {HeaderHeight} from 'theme/settings';
import {ThesaurusNodeType} from 'api';
import {useThesaurus, useTrimmedThesaurus} from 'services/thesaurus';
import {getThesaurusMaps} from 'services/thesaurus/functions/getThesaurusMaps';
import {
  FlexBox,
  Scrollable,
  SearchInput,
  Spacer,
  TooltipContent,
  TooltipText,
} from 'components';
import {SceneTitle} from 'components/scene/SceneTitle';
import {CategoryContainer} from './components/CategoryContainer';
import {SearchingTopLevelsContainer} from './components/SearchingTopLevelsContainer';
import {ThesaurusToolbar} from './components/ThesaurusToolbar';
import {TopLevelsContainer} from './components/TopLevelsContainer';
import {
  ThesaurusEditStateContext,
  useThesaurusEditStateContextProviderValue,
} from './contexts';

type Props = {
  thesaurusId: ThesaurusId;
  showCode?: ShowThesaurusCode;
  selectableNodeTypes?: string[];
  focusNodeCodeWithTimestamp?: string;
  fieldConfig?: ThesaurusFieldConfig;
  thesaurusValue?: ThesaurusValueType;
  onSetSourceValue: SetSourceValue<ThesaurusValueType, ThesaurusAction>;
  onClose: () => void;
};

export const ThesaurusEdit: React.FC<Props> = ({
  thesaurusId,
  showCode = false,
  selectableNodeTypes,
  focusNodeCodeWithTimestamp,
  fieldConfig,
  thesaurusValue,
  onSetSourceValue,
  onClose,
}) => {
  const {t, tLoose} = useLocalization();
  const fullThesaurus = useThesaurus(thesaurusId);
  // Initialize lazy maps for given thesaurus
  useRef(getThesaurusMaps(fullThesaurus));
  const trimmedThesaurus = useTrimmedThesaurus(fullThesaurus, thesaurusValue);

  const thesaurusEditState = useThesaurusEditStateContextProviderValue(
    trimmedThesaurus,
    showCode,
    selectableNodeTypes as ThesaurusNodeType[] | undefined,
    thesaurusValue,
    focusNodeCodeWithTimestamp,
    onSetSourceValue,
    onClose,
  );
  const {placeholder} = useMemo(
    () => ({
      placeholder: fieldConfig?.showCode
        ? t('search.thesaurus.placeholder.withCode')
        : t('search.thesaurus.placeholder'),
    }),
    [fieldConfig?.showCode, t],
  );

  const {
    searchQuery,
    setSearchQuery,
    state: {search, navigation},
  } = thesaurusEditState;

  /**
   * LayoutHeaderHeight is used to inform the below Scroll-component of the offset.
   * We initially just guess 200, but useEffect will update this to the correct amount.
   */
  const [layoutHeaderHeight, setLayoutHeaderHeight] = useState(200);
  const layoutHeaderRef = createRef<HTMLDivElement>();
  useLayoutEffect(() => {
    if (layoutHeaderRef && layoutHeaderRef.current) {
      setLayoutHeaderHeight(
        layoutHeaderRef.current.clientHeight + HeaderHeight * 2,
      );
    }
  }, [layoutHeaderRef]);

  return (
    <ThesaurusEditStateContext.Provider value={thesaurusEditState}>
      {/* Header with search input */}
      <FlexBox ref={layoutHeaderRef}>
        <SceneTitle
          title={
            thesaurusId
              ? tLoose(`extension.thesaurusEdit.${thesaurusId}.title`)
              : ''
          }
          tooltipTitle={
            fieldConfig?.showCode ? (
              <TooltipContent
                header={t('search.thesaurus.tooltip.header')}
                description={
                  <FlexBox>
                    <TooltipText variant="body2">
                      {t('search.thesaurus.tooltip.description')}
                    </TooltipText>
                    <Spacer size={1} />
                    <TooltipText variant="body2">
                      {t('search.thesaurus.tooltip.example')}
                    </TooltipText>
                  </FlexBox>
                }
              />
            ) : undefined
          }>
          <ThesaurusToolbar onClose={onClose} />
        </SceneTitle>

        <Spacer size={1} />

        <SearchInput
          value={searchQuery ?? ''}
          placeholder={placeholder}
          triggerFocus={fieldConfig}
          onChange={setSearchQuery}
        />
        <Spacer size={3} />
      </FlexBox>

      {/* Content, scrollable */}
      <Scrollable offset={layoutHeaderHeight}>
        {navigation === 'TopLevels' ? (
          search === 'NotSearching' ? (
            <TopLevelsContainer thesaurusId={thesaurusId} />
          ) : (
            <SearchingTopLevelsContainer />
          )
        ) : (
          <CategoryContainer />
        )}
      </Scrollable>
    </ThesaurusEditStateContext.Provider>
  );
};
