import React, {useCallback} from 'react';
import {Data, DataValue, PartSchema} from 'schemaDefinition/types';
import {useLabelAndPlaceholder} from 'schema/form/hooks';
import {Table} from 'schema/table/components';
import {BasePartProps} from './types';
import {usePreviewConfigurationContext} from '../contexts';
import {useTextLabel} from '../hooks';

type PreviewTableProps = Omit<
  BasePartProps<PartSchema, Data[]>,
  'layoutDirection'
>;

export const PreviewTable: React.FC<PreviewTableProps> = ({part, value}) => {
  const config = usePreviewConfigurationContext();
  const {label} = useLabelAndPlaceholder(part);
  const TextLabel = useTextLabel();

  const canNavigate =
    config.onNavigate && config?.parts?.[part.name]?.createLink;
  const handleClick = useCallback(
    (rowValue: DataValue) => {
      const partConfig = config?.parts?.[part.name];
      const createLink = partConfig?.createLink;
      if (config.onNavigate && createLink) {
        const link = createLink(rowValue);
        const siblingIds = value
          .map(v => createLink?.(v as DataValue))
          .map(l => l.entityId);
        config.onNavigate(link, siblingIds);
      }
    },
    [config, part.name, value],
  );

  return (
    <Table
      schema={part as PartSchema}
      data={value}
      header={<TextLabel>{label}</TextLabel>}
      configuration={config?.parts?.[part.name]?.table}
      onRowClick={canNavigate ? handleClick : undefined}
    />
  );
};
