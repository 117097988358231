import React from 'react';
import {IconProps} from 'components/icons/types';
import {LinkStatus} from 'schemaDefinition/types';
import {
  AIIcon,
  CheckCircleIcon,
  GhostIcon,
  SearchIcon,
  VerifiedIcon,
} from 'components/icons';

type Props = {
  status?: LinkStatus | 'externalVerified' | 'empty';
  color: IconProps['color'];
};

export const LinkStatusIcon: React.FC<Props> = ({status, color}) => {
  return status === 'unnamed' ? (
    <AIIcon color={color} />
  ) : status === 'empty' ? (
    <SearchIcon color={color} />
  ) : status === 'unverified' ? (
    <GhostIcon color={color} />
  ) : status === 'verified' ? (
    <CheckCircleIcon color={color} />
  ) : status === 'externalVerified' ? (
    <VerifiedIcon color={color} />
  ) : null;
};
