import {LinkEntityType} from 'types';
import {LinkRole, RoleEntityCode} from 'schemaDefinition/types/linkTypes';

export const splitRoleEntityCode = (
  entityRoleEntityCode: RoleEntityCode,
): {
  role: LinkRole;
  target: LinkEntityType;
} => {
  const [role, target] = entityRoleEntityCode.split('.');
  return {
    role: role as LinkRole,
    target: target as LinkEntityType,
  };
};
