import {LocalizationContextType} from 'localization/context/types';
import {Part, Schema} from 'schemaDefinition/types';
import {getFieldRootKeys} from './getFieldRootKeys';
import {getFieldString} from './getFieldString';

export const getBoxLabel = (
  localization: LocalizationContextType,
  {labelKey, name}: Pick<Part, 'labelKey' | 'name'>,
  schema: Schema,
): string | undefined => {
  const rootKeys = getFieldRootKeys(schema.key, false, labelKey, name);
  return getFieldString(rootKeys, false, '.label.box', localization);
};
