import React from 'react';
import {ColorPalette} from 'theme';
import {FilledIconProps} from './types';
import {CheckRoundBackgroundIcon} from './CheckRoundBackgroundIcon';

export const VerifiedIcon: React.FC<FilledIconProps> = ({
  fillColorCode = ColorPalette.enhancedGreen,
  ...props
}) => {
  return <CheckRoundBackgroundIcon fillColorCode={fillColorCode} {...props} />;
};
