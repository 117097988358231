import {ReactElement} from 'react';
import {DataValue, Part} from 'schemaDefinition/types';
import {BaseFieldProps} from '../types';
import {ValueLayout} from './ValueLayout';

type Props<TPart extends Part, TValue extends DataValue> = Pick<
  BaseFieldProps<TPart, TValue | TValue[]>,
  'part' | 'layoutDirection'
> & {
  renderValue: () => ReactElement | null;
};

export const SingleValue = <TPart extends Part, TValue extends DataValue>({
  part,
  layoutDirection,
  renderValue,
}: Props<TPart, TValue>) => {
  return (
    <ValueLayout
      renderValue={renderValue}
      part={part}
      layoutDirection={layoutDirection}
    />
  );
};
