import {PartSchema} from 'schemaDefinition/types';
import {CodeListRef, Collection} from 'api';

// Chceck for subsequent matching langauges: \1
export const duplicateTitlesLanguageCodeRegex =
  '^.+("language":"\\w+").*(\\1.+)$';

export const collectionTitlesSchemaPart: PartSchema<Collection> = {
  type: 'schema',
  name: 'titles',
  parts: [
    [
      {
        type: 'text',
        name: 'value',
        required: true,
      },
      {
        type: 'codelist',
        name: 'language',
        codelistId: 'LANGUAGE',
        required: true,
        default: CodeListRef.LANGUAGE['Bokmål'],
        validation: {
          // Should not have duplicate languages
          op: 'not',
          arg: {
            regex: duplicateTitlesLanguageCodeRegex,
            arg: {$ref: '/titles'},
          },
        },
      },
    ],
  ],
  cardinality: 'multiple',
  required: true,
};
