import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {assert} from 'assert-ts';
import {useLocalization} from 'localization';
import {Lock, UserDto} from 'api';
import {deleteLock} from 'api/lock';
import {useGetTokens, useUser} from 'services/auth';
import {useUsers} from 'services/users';
import {useSnacks} from 'components/snacks';
import {LockProps, LockState} from '../contexts/types';
import {LockValue} from './types';
import {
  LockStateTransitionDependencies,
  LockTransitionProps,
  lockWorkOnOpenStateTransition,
} from './lockWorkOnOpenStateTransition';
import {useAcquireLockWithErrorHandling} from './useAcquireLockWithErrorHandling';
import {useCurrentLockValue} from './useCurrentLockValue';
import {usePostLockWithErrorHandling} from './usePostLockWithErrorHandling';

const getCurrentLockUser = (
  lock: Lock | undefined,
  users: UserDto[] | undefined,
) => {
  if (!lock || !users) {
    return undefined;
  }
  return users.find(u => u.id === lock.lockedBy);
};

/**
 * States with actions:
 * - LockedByOther: initial if lock exists, locked by other user/session (readonly)
 *   - Every x seconds: reload lock, set state LockedByOther or NotLocked (local data may be stale,
 *     and user should reload to get latest data and set lock, InfoSnack)
 *   - Action: acquire lock, set state LockedByThis
 * - NotLocked: initial if not able to get lock or when lock by other released (available, editable)
 *   - Every x seconds: ditto
 * - LockedByThis: after posting initial lock or acquiring lock (locked by this, editable)
 *   - Every x seconds: reload lock, if acquired/deleted : set state LockedByOther or NotLocked
 *   - On close/leave: try delete lock
 * @param workId
 * @returns
 */

export const useLockWorkOnOpen = (
  workId: string,
): {
  readonly: boolean;
  lockProps: LockProps;
} => {
  const {tLoose} = useLocalization();
  const getTokens = useGetTokens();
  const user = useUser();
  const {
    resource: {data: users},
  } = useUsers();
  const {infoSnack, warningSnack} = useSnacks();

  /**
   * Current lock value for work loaded from backend, updated every x sec
   */
  const loadedLock = useCurrentLockValue(workId);

  const postLockWithErrorHandling = usePostLockWithErrorHandling(workId);
  const acquireLockWithErrorHandling = useAcquireLockWithErrorHandling();

  const [lockState, setLockState] = useState<LockState>(LockState.Initial);

  /**
   * server lock value, either from updates to loaddLock or
   * from postLock/acquireLock (or releasing lock) for this user
   */
  const [serverLock, setServerLock] = useState(loadedLock);

  /**
   * CurrentLockRef, derived from serverLock, stable across updates
   */
  const currentLockRef = useRef(serverLock);

  const lockStateRef = useRef(lockState);

  /**
   * Keep track of lockId for lock set by this user
   * to be able to release it on close/leave without
   * dependencies to state
   */
  const byThisUserLockIdRef = useRef<string | undefined>();

  const partialLockDependencies = useMemo<
    Omit<LockStateTransitionDependencies, 'postLock'>
  >(
    () => ({
      user,
      showWarning: (key: string) => warningSnack(tLoose(key)),
      showInfo: (key: string) => infoSnack(tLoose(key)),
    }),
    [infoSnack, tLoose, user, warningSnack],
  );

  const handleLockUpdate = useCallback(
    (props: {
      current: LockTransitionProps;
      updated: LockValue;
      dependencies: Omit<LockStateTransitionDependencies, 'postLock'>;
      postLock?: LockStateTransitionDependencies['postLock'];
    }) => {
      const postLock =
        props.postLock ?? (() => assert.soft(false, 'postLock not set'));
      const updatedState = lockWorkOnOpenStateTransition({
        ...props,
        dependencies: {...props.dependencies, postLock},
      });

      if (updatedState !== props.current) {
        currentLockRef.current = updatedState.lockValue;
        lockStateRef.current = updatedState.lockState;
        byThisUserLockIdRef.current = updatedState.thisUserLockId;
        setLockState(updatedState.lockState);
      }
    },
    [],
  );

  const handlePostLock = useCallback(() => {
    postLockWithErrorHandling().then(lock => {
      if (lock) {
        setServerLock({isLocked: true, lock});
      }
    });
  }, [postLockWithErrorHandling]);

  const handleAcquireLock = useCallback((): Promise<void> => {
    return acquireLockWithErrorHandling(currentLockRef.current?.lock).then(
      lock => {
        if (lock) {
          setServerLock({isLocked: true, lock});
        }
      },
    );
  }, [acquireLockWithErrorHandling]);

  // On lock loaded from backend => update server lock
  useEffect(() => {
    setServerLock(loadedLock);
  }, [loadedLock]);

  // On server lock updated => handle lock update
  useEffect(() => {
    if (serverLock === undefined) {
      return;
    }

    handleLockUpdate({
      current: {
        lockValue: currentLockRef.current,
        lockState: lockStateRef.current,
        thisUserLockId: byThisUserLockIdRef.current,
      },
      updated: serverLock,
      dependencies: partialLockDependencies,
      postLock: handlePostLock,
    });
  }, [handleLockUpdate, handlePostLock, partialLockDependencies, serverLock]);

  // On unmount, try to release lock
  // Subscribe to beforeunload to try to release lock on close/leave
  useEffect(() => {
    const handleUnlock = () => {
      if (byThisUserLockIdRef.current) {
        const id = byThisUserLockIdRef.current;
        byThisUserLockIdRef.current = undefined;
        deleteLock(id, getTokens, false).catch(() => {});
      }
    };

    window.addEventListener('beforeunload', handleUnlock);

    return () => {
      // Remove event listener on unmount
      window.removeEventListener('beforeunload', handleUnlock);
      // Unlock in case of just unmounting, e.g. when navigting
      handleUnlock();
    };
    // Only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(() => {
    const readonly = lockState === LockState.LockedByOther;
    const lock = currentLockRef.current?.lock;
    const lockProps: LockProps = {
      state: lockState,
      lock,
      byOtherUser:
        lockState === LockState.LockedByOther
          ? getCurrentLockUser(lock, users) ?? {
              id: lock?.id ?? 'unknown',
              email: '',
              name: '',
            }
          : undefined,
      acquireLock: handleAcquireLock,
    };

    return {
      readonly,
      lockProps,
    };
  }, [handleAcquireLock, lockState, users]);
};
