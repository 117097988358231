import React, {useCallback, useMemo, useRef} from 'react';
import {assert} from 'assert-ts';
import {isEmpty} from 'lodash';
import {DataValue, PartThesaurus} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {evaluateFieldRequired} from 'schemaDefinition';
import {useThesaurus} from 'services/thesaurus';
import {DataFormPartProps} from './types';
import {FieldLabel, FlexBox} from '../../../components';
import {useFormSchemaGlobalScopeContext} from '../../contexts';
import {useDataFormContext} from '../contexts';
import {getThesaurusDiffPerCategory} from '../functions/getThesaurusDiffPerCategory';
import {getThesaurusDiffPerType} from '../functions/getThesaurusDiffPerType';
import {
  useLabelAndPlaceholder,
  verifyGlobalScope,
  verifyLocalScope,
} from '../hooks';
import {CompareThesaurusGroup} from './CompareThesaurusGroup';
import {DataFormCompareLayout} from './DataFormCompareLayout';
import {DataFormOriginalValueContainer} from './DataFormOriginalValueContainer';
import {DataFormUpdatedItemValueContainer} from './DataFormUpdatedValueContainer';

type Props = Omit<DataFormPartProps, 'part'> & {
  part: PartThesaurus;
};

export const CompareThesaurus: React.FC<Props> = dataFormPartProps => {
  const {
    part,
    valuePath,
    useValue,
    useOriginalValue,
    scopePath,
    usesGlobalScope,
    relatedScope,
  } = dataFormPartProps;

  const {tLoose} = useLocalization();
  const {id, setApprovedValue} = useDataFormContext();
  const name = part?.name ?? part?.role ?? 'unamed part';
  const {label, placeholder} = useLabelAndPlaceholder(part);

  const value = useValue(valuePath, id, part.name) as DataValue;
  const scope = verifyLocalScope(
    useValue(scopePath, id, `${part.name}.localScope`),
    scopePath,
  );
  const {valuePath: globalPath} = useFormSchemaGlobalScopeContext();
  const globalScope = verifyGlobalScope(
    useValue(
      usesGlobalScope ? globalPath : undefined,
      id,
      `${part.name}.globalScope`,
    ),
    usesGlobalScope,
  );

  const required = useMemo(() => {
    return evaluateFieldRequired(
      part.required,
      valuePath,
      scope,
      globalScope,
      relatedScope,
      value,
    );
  }, [globalScope, part.required, relatedScope, scope, value, valuePath]);

  type ThesaurusValue = string[] | undefined;
  const changedValue = (useValue(valuePath, id, name) ??
    null) as ThesaurusValue;
  const originalValue = (useOriginalValue?.(valuePath, id, name) ??
    null) as ThesaurusValue;
  const thesaurus = useThesaurus(part.thesaurusId);
  // Keep reference to the edited value, initially set to the original value
  // since there is currently no way to get the edited value from the context
  // Hence, any changes by the user to e.g. thema-values in the work itself,
  // will be overwritten when replacing/adding/remove group terms.
  const editedOriginalValue = useRef<string[] | undefined>(originalValue);

  const perGroupDiff = useMemo(() => {
    return part.variant === 'byType'
      ? getThesaurusDiffPerType(
          part,
          valuePath,
          originalValue,
          changedValue,
          thesaurus,
          tLoose,
        )
      : getThesaurusDiffPerCategory(
          part,
          valuePath,
          originalValue,
          changedValue,
          thesaurus,
        );
  }, [part, valuePath, originalValue, changedValue, thesaurus, tLoose]);

  const handleReplace = useCallback(
    (groupId: string) => {
      const groupDiff = perGroupDiff.find(c => c.groupId === groupId);
      if (
        !assert.soft(groupDiff, 'handleReplace: Category not found', {
          groupId,
        })
      ) {
        return;
      }

      const newValue = [
        ...(editedOriginalValue.current ?? []).filter(
          t => !(groupDiff.originalTerms ?? []).includes(t),
        ),
        ...(groupDiff.changesTerms ?? []),
      ];
      editedOriginalValue.current = newValue;

      setApprovedValue(valuePath, 'replace', newValue, groupDiff.groupPath);
    },
    [perGroupDiff, setApprovedValue, valuePath],
  );

  const handleAdd = useCallback(
    (groupId: string) => {
      const groupDiff = perGroupDiff.find(c => c.groupId === groupId);
      if (
        !assert.soft(groupDiff, 'handleReplace: Category not found', {
          groupId,
        })
      ) {
        return;
      }

      const newValue = [
        ...(editedOriginalValue.current ?? []),
        ...(groupDiff.changesTerms ?? []),
      ];
      editedOriginalValue.current = newValue;

      setApprovedValue(valuePath, 'addItem', newValue, groupDiff.groupPath);
    },
    [perGroupDiff, setApprovedValue, valuePath],
  );

  const handleRemove = useCallback(
    (groupId: string) => {
      const groupDiff = perGroupDiff.find(c => c.groupId === groupId);
      if (
        !assert.soft(groupDiff, 'handleReplace: Category not found', {
          groupId,
        })
      ) {
        return;
      }

      const newValue = [
        ...(editedOriginalValue.current ?? []).filter(
          t => !(groupDiff.originalTerms ?? []).includes(t),
        ),
      ];
      editedOriginalValue.current = newValue;

      setApprovedValue(valuePath, 'removeItem', newValue, groupDiff.groupPath);
    },
    [perGroupDiff, setApprovedValue, valuePath],
  );

  return (
    <FlexBox width={'100%'}>
      <FlexBox horizontal>
        <DataFormCompareLayout
          renderCurrent={() => (
            <DataFormOriginalValueContainer>
              <FieldLabel
                label={label ?? part.thesaurusId}
                required={required}
              />
            </DataFormOriginalValueContainer>
          )}
          renderProposed={() => (
            <DataFormUpdatedItemValueContainer>
              <FieldLabel
                label={label ?? part.thesaurusId}
                required={required}
              />
            </DataFormUpdatedItemValueContainer>
          )}
        />
      </FlexBox>

      {perGroupDiff.map(
        (
          {
            groupId: groupId,
            groupPath: groupPath,
            groupName: groupName,
            originalTerms,
            changesTerms,
          },
          idx,
        ) => (
          <CompareThesaurusGroup
            key={groupPath}
            {...dataFormPartProps}
            required
            groupId={groupId}
            groupPath={groupPath}
            groupName={groupName}
            originalTerms={originalTerms}
            changesTerms={changesTerms}
            originalEmptyPlaceholder={
              idx === 0 && isEmpty(originalValue) ? placeholder : undefined
            }
            changesEmptyPlaceholder={
              idx === 0 && isEmpty(changedValue) ? placeholder : undefined
            }
            onReplace={() => handleReplace(groupId)}
            onAdd={() => handleAdd(groupId)}
            onRemove={() => handleRemove(groupId)}
          />
        ),
      )}
    </FlexBox>
  );
};
