import {useMemo} from 'react';
import {DataValue, Part} from 'schemaDefinition/types';
import {getAdornments} from 'schemaDefinition/functions';
import {useDataFormConfigurationContext} from '../contexts/DataFormConfigurationContext';

export function useAdornments(
  part: Part,
  value?: DataValue,
): {
  prefix?: string | JSX.Element;
  suffix?: string | JSX.Element;
} {
  const configuration = useDataFormConfigurationContext();

  return useMemo(
    () => getAdornments(part, value, configuration.adornmentResolver),
    [configuration.adornmentResolver, part, value],
  );
}
