import {useCallback} from 'react';
import {Configuration} from 'configuration';
import {DataLoadStatus, Lock, useGetResource} from 'api';
import {getLock} from 'api/lock';
import {useGetTokens} from 'services/auth';

const LOCK_REFRESH_INTERVAL_MS = 5 * 1000;

export const useCurrentLock = (
  workId: string,
  mock?: boolean,
): DataLoadStatus<Lock | undefined> => {
  const getTokens = useGetTokens();
  const handleGetLock = useCallback(
    () => getLock(workId, 'work', getTokens, mock),
    [getTokens, mock, workId],
  );

  const interval = Configuration.interval.lock ?? LOCK_REFRESH_INTERVAL_MS;
  return useGetResource(handleGetLock, interval);
};
