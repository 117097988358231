import {useMemo} from 'react';
import {Data, Part} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {useFormSchemaGlobalScopeContext} from '../../contexts';
import {getFieldRootKeys, getFieldString, getLabel} from '../functions';

export const useAddItemLabel = (part: Part<Data, Data>): string => {
  const localization = useLocalization();
  const {schema} = useFormSchemaGlobalScopeContext();
  return useMemo(() => {
    const rootKeys = getFieldRootKeys(
      schema.key,
      false,
      part.labelKey,
      part.name,
    );
    return (
      getFieldString(rootKeys, true, '.addItem', localization) ??
      localization.t('form.add') + ' ' + getLabel(rootKeys, false, localization)
    );
  }, [localization, part.labelKey, part.name, schema.key]);
};
