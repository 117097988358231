import {AnyValidation, Part} from 'schemaDefinition/types';
import {evaluateCondition} from 'schemaDefinition/functions/evaluateCondition';
import {ValidationArgs, ValidationResult} from './types';
import {fail} from './fail';
import {validResult} from './validResult';
import {warning} from './warning';

export const validateConditions = <TPart extends Part>(
  conditions: AnyValidation | AnyValidation[],
  part: TPart,
  args: ValidationArgs,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  (Array.isArray(conditions) ? conditions : [conditions]).forEach(condition => {
    if (!evaluateCondition(condition, args)) {
      aggregateResult = (condition.level === 'warning' ? warning : fail)(
        {
          ...args,
          part,
          validation: condition,
          messageKey: condition.messageKey,
        },
        aggregateResult,
      );
    }
  });

  return aggregateResult;
};
