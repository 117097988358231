import React, {useMemo} from 'react';
import {isEmpty} from 'lodash';
import {FlexBox, ThesaurusField, ThesaurusFieldProps} from 'components';
import {PartThesaurus} from '../../../schemaDefinition/types';
import {DataFormPartProps} from './types';
import {CompareThesaurusGroupActions} from './CompareThesaurusGroupActions';
import {DataFormCompareLayout} from './DataFormCompareLayout';
import {DataFormOriginalValueContainer} from './DataFormOriginalValueContainer';
import {DataFormUpdatedItemValueContainer} from './DataFormUpdatedValueContainer';

type Props = Omit<DataFormPartProps, 'part'> & {
  part: PartThesaurus;
  required: boolean;
  groupId: string;
  groupPath: string;
  groupName?: string;
  originalTerms?: string[];
  changesTerms?: string[];
  originalEmptyPlaceholder?: string;
  changesEmptyPlaceholder?: string;
  onReplace: () => void;
  onAdd: () => void;
  onRemove: () => void;
};

export const CompareThesaurusGroup: React.FC<Props> = props => {
  const {
    part,
    required,
    groupPath,
    groupName,
    originalTerms,
    changesTerms,
    originalEmptyPlaceholder,
    changesEmptyPlaceholder,
    onReplace,
    onAdd,
    onRemove,
  } = props;

  const originalProps = useMemo((): ThesaurusFieldProps => {
    return {
      variant: part.variant ?? 'byCategory',
      name: groupPath,
      placeholder: originalEmptyPlaceholder,
      thesaurusValue: originalTerms,
      thesaurusId: part.thesaurusId,
      showCode: part.showCode,
      selectableNodeTypes: part.selectableNodeTypes,
      required: required,
      onChange: () => {},
      readonly: true,
    };
  }, [
    part.variant,
    part.thesaurusId,
    part.showCode,
    part.selectableNodeTypes,
    groupPath,
    originalEmptyPlaceholder,
    originalTerms,
    required,
  ]);

  const changesProps = useMemo((): ThesaurusFieldProps => {
    return {
      variant: part.variant ?? 'byCategory',
      name: groupPath,
      placeholder: changesEmptyPlaceholder,
      thesaurusValue: changesTerms,
      thesaurusId: part.thesaurusId,
      showCode: part.showCode,
      selectableNodeTypes: part.selectableNodeTypes,
      required: required,
      onChange: () => {},
      readonly: true,
    };
  }, [
    part.variant,
    part.thesaurusId,
    part.showCode,
    part.selectableNodeTypes,
    groupPath,
    changesEmptyPlaceholder,
    changesTerms,
    required,
  ]);

  const hideChanges = isEmpty(changesProps.thesaurusValue);
  return (
    <FlexBox horizontal width={'100%'}>
      <DataFormCompareLayout
        renderCurrent={() => (
          <DataFormOriginalValueContainer>
            <ThesaurusField {...originalProps} />
          </DataFormOriginalValueContainer>
        )}
        renderProposed={() => (
          <DataFormUpdatedItemValueContainer
            actionOffset
            renderActions={() => (
              <CompareThesaurusGroupActions
                groupName={groupName}
                originalGroupTerms={originalTerms}
                changesGroupTerms={changesTerms}
                groupPath={groupPath}
                onReplace={onReplace}
                onAdd={onAdd}
                onRemove={onRemove}
              />
            )}>
            {/* Fill empty space with originalProps value for action button to align */}
            <FlexBox hidden={hideChanges}>
              <ThesaurusField
                {...changesProps}
                thesaurusValue={
                  hideChanges
                    ? originalProps.thesaurusValue
                    : changesProps.thesaurusValue
                }
              />
            </FlexBox>
          </DataFormUpdatedItemValueContainer>
        )}
      />
    </FlexBox>
  );
};
