import React, {PropsWithChildren} from 'react';
import {DataValue, Part} from 'schemaDefinition/types';
import {FlexBox} from 'components';
import {CopyToClipboard} from 'components/copyToClipboard';

type Props = {
  part: Part;
  value: DataValue;
};

export function PartWithActions({
  part,
  value,
  children,
}: React.PropsWithChildren<Props>): ReturnType<
  React.FC<PropsWithChildren<Props>>
> {
  const stringValue = JSON.stringify(value);
  const valueWithoutQuotes = stringValue.substring(1, stringValue.length - 1);

  return part.actions ? (
    <FlexBox horizontal bottom>
      {children}
      {part.actions.map(action =>
        action === 'copy' ? (
          <CopyToClipboard key={action} text={valueWithoutQuotes} />
        ) : null,
      )}
    </FlexBox>
  ) : (
    <>{children}</>
  );
}
