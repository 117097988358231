export const getQueryParts = (
  query: string | undefined,
): {codeQuery?: string; textQuery?: string} => {
  if (!query) {
    return {};
  }
  const [part0, ...rest] = query.split(':');
  if (rest.length === 0) {
    return {textQuery: query};
  }
  return {
    codeQuery: part0.trim(),
    textQuery: rest.join(':').trim() || undefined, // If the rest is empty, return undefined
  };
};
