import {ThesaurusNode} from 'api/types';
import {AutocompleteOption, NodeMapper} from '../types';

export const searchThesaurus = (
  query: string,
  thesaurus: ThesaurusNode,
  nodeMapper: NodeMapper,
): AutocompleteOption[] => {
  const itemMatches: AutocompleteOption[] = [];

  if (
    thesaurus.id.toLowerCase().includes(query.toLowerCase()) ||
    thesaurus.code.toLowerCase().includes(query.toLowerCase()) ||
    thesaurus.label.toLowerCase().includes(query.toLowerCase())
  ) {
    itemMatches.push(nodeMapper(thesaurus));
  }

  const childrenMatches = thesaurus.children
    ? thesaurus.children
        ?.map(child => searchThesaurus(query, child, nodeMapper))
        .flatMap(childMatches => childMatches) ?? []
    : [];

  return [...itemMatches, ...childrenMatches];
};
