import {assert} from 'assert-ts';
import {Data, Schema, SchemaModifierMap} from 'schemaDefinition/types';
import {getModifiedSchemaWithModifier} from './getModifiedSchemaWithModifier';

export const getModifiedSchema = <
  TMetadataModifierKey extends string,
  TEntity extends Data = Data,
>(
  schema: Schema<TEntity>,
  modifiers: SchemaModifierMap<TMetadataModifierKey>,
  forKey: TMetadataModifierKey,
  keys: TMetadataModifierKey[],
): Schema<TEntity> => {
  const forKeyIdx = keys.indexOf(forKey);
  assert(forKeyIdx >= 0, 'getModifiedSchema: forKey not found in keys');

  return keys
    .slice(0, forKeyIdx + 1)
    .reduce<Schema<TEntity>>((accSchema, key) => {
      const modifier = modifiers[key];
      return getModifiedSchemaWithModifier(accSchema, modifier);
    }, schema);
};
