import {RangeCondition} from 'schemaDefinition/types';
import {valueAsNumber} from 'services/utils';
import {EvaluationArgs} from './types';
import {evaluateVal} from './evaluateVal';
import {evaluateValWithDefault} from './evaluateValWithDefault';

export const evaluateRangeCondition = (
  condition: RangeCondition,
  args: EvaluationArgs,
): boolean => {
  const min = condition.min
    ? valueAsNumber(evaluateVal(condition.min, args))
    : undefined;
  const max = condition.max
    ? valueAsNumber(evaluateVal(condition.max, args))
    : undefined;
  const arg1 = valueAsNumber(
    evaluateValWithDefault(condition.arg1, args, args.value),
  );

  return (
    arg1 !== undefined &&
    (min === undefined || arg1 >= min) &&
    (max === undefined || arg1 <= max)
  );
};
