import {Part, PartCodeList} from '../types';

const partUnnamedAgentRole: Part = {
  type: 'codelist',
  name: 'unnamedAgentRole',
  codelistId: 'UNNAMED_PERSON',
  required: true,
};

export const getPartCodelistFromUnnamedAgentRole = (): PartCodeList =>
  partUnnamedAgentRole;
