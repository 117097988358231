import {Concept} from 'types';
import {Agent, AgentPerson, MainTitle, WorkSummary} from 'api/types';
import {
  Part,
  PartSchema,
  Separator,
  Today,
  TypedSchema,
  TypedSchemaModifier,
} from 'schemaDefinition/types';
import {Schemas} from '../../api/dto.generated';
import {mainTitlesPart} from './manifestationSchema';
import {agentBAREParts} from './parts';
import {workDataSchema} from './workSchema';

export type AgentPersonWithWorks = AgentPerson &
  WorkSummary &
  Pick<Schemas.AgentWorkRoleDto, 'roles'> & {
    mainTitles?: MainTitle[];
  };

const agentWorksSchema: PartSchema<AgentPersonWithWorks> = {
  type: 'schema',
  name: 'works',
  cardinality: 'multiple',
  noValidation: true,
  variant: 'table',
  parts: [
    ...(workDataSchema.parts as Part<AgentPersonWithWorks>[]),
    mainTitlesPart as PartSchema<AgentPersonWithWorks>,
    {
      type: 'thesaurus',
      name: 'themaLabels',
      labelKey: 'themaLabels',
      cardinality: 'multiple',
      thesaurusId: 'thema',
      showCode: false,
    },
    {
      type: 'text',
      name: 'themaCodes',
      labelKey: 'themaCodes',
      cardinality: 'multiple',
    },
    {
      type: 'codelist',
      name: 'roles',
      cardinality: 'multiple',
      codelistId: 'WORK_AND_EXPRESSION_ROLE_TYPE',
    },
  ],
};

export const agentPersonSchema: TypedSchema<AgentPersonWithWorks> = {
  name: 'person',
  key: 'person',
  parts: [
    {
      type: 'nameVariant',
      agentType: Concept.person,
      name: 'nameVariants',
      cardinality: 'multiple',
      required: true,
    },
    Separator('card', 'line'),
    [
      ...agentBAREParts,
      {
        type: 'bool',
        name: 'pseudonym',
      },
    ],
    {
      type: 'expand',
      role: 'personLinks',
      when: [
        {
          condition: {
            arg: {$ref: '.pseudonym'},
          },
          parts: [
            {
              type: 'linkedLiterary',
              name: 'links',
              labelKey: 'links',
              cardinality: 'multiple',
              roleCodelistId: 'pseudonym.linkedRole',
            },
          ],
        },
      ],
      default: [
        {
          type: 'linkedLiterary',
          name: 'links',
          labelKey: 'links',
          cardinality: 'multiple',
          roleCodelistId: 'realPerson.linkedRole',
        },
      ],
    },
    Separator('line', 'none'),
    [
      {
        type: 'yearOrDate',
        name: 'birth',
        includeBce: true,
        includeApproximate: true,
        validation: [
          {
            max: Today,
            messageKey: 'birthLatestToday',
          },
          {
            max: {$ref: ':death'},
            messageKey: 'birthBeforeDeath',
          },
        ],
      },
      {
        type: 'yearOrDate',
        name: 'death',
        includeBce: true,
        includeApproximate: true,
        validation: {
          min: {$ref: ':birth'},
          max: Today,
          messageKey: 'deathAfterBirth',
        },
      },
    ],
    [
      {
        type: 'codelist',
        name: 'countryOfBirth',
        codelistId: 'COUNTRY',
      },
      {
        type: 'codelist',
        name: 'country',
        labelKey: 'countryFlourishedIn',
        codelistId: 'COUNTRY',
      },
    ],
    [
      {
        type: 'text',
        name: 'profession',
      },
      {
        type: 'text',
        name: 'occupationalField',
        cardinality: 'multiple',
      },
    ],

    {
      type: 'codelist',
      name: 'gender',
      codelistId: 'GENDER',
      required: true,
      variant: 'buttons',
    },
    {
      type: 'textarea',
      name: 'externalComments',
      maxLength: 5000,
    },
    {
      ...Separator('card', 'none'),
      role: 'internalComments',
      titleKey: 'internalComments',
    },
    {
      type: 'textarea',
      name: 'internalComments',
      maxLength: 5000,
    },
    Separator('card', 'card'),
    agentWorksSchema,
  ],
};

/**
 * Dont allow external comment to be changed when national id is set,
 * because we dont update (only on create) this field in BARE anyways.
 */
export const nationIdSchemaModifier: TypedSchemaModifier<Agent> = [
  {
    name: 'externalComments',
    readonly: true,
  },
];
