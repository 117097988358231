import {AnyValidation, Condition} from 'schemaDefinition/types';
import {ExpressionV4} from 'api';

type EntityWithAgents = Pick<ExpressionV4, 'agents'>;

type EntityCondition = Condition<
  EntityWithAgents,
  EntityWithAgents,
  EntityWithAgents['agents']
>;

type EntityValidation = AnyValidation<
  EntityWithAgents,
  EntityWithAgents,
  EntityWithAgents['agents']
>;

export const isUnnamedAgentLink: EntityCondition = {
  op: 'eq',
  arg1: {$ref: '.link.linkStatus'},
  arg2: 'unnamed',
};

export const validateLinkedAgentId: EntityValidation = {
  level: 'warning',
  op: 'or',
  arg: [{arg: {$ref: '.link.entityId'}}, isUnnamedAgentLink],
  messageKey: 'agent.not.verified',
};

export const validateExternalLinkedAgentId: EntityValidation = {
  level: 'warning',
  op: 'or',
  arg: [{arg: {$ref: '.link.nationalId'}}, isUnnamedAgentLink],
  messageKey: 'agent.not.external.verified',
};

export const validateLinkedImprintId: EntityValidation = {
  level: 'warning',
  arg: {$ref: '.link.entityId'},
  messageKey: 'imprint.not.verified',
};
