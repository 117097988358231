import React from 'react';
import {FlexBox} from 'components/layout';
import {TooltipText} from './TooltipText';

type Props = {
  header?: string;
  description?: React.ReactElement | string | null;
};

export const TooltipContent: React.FC<Props> = ({header, description}) => {
  return (
    <FlexBox>
      <TooltipText variant="body1">{header}</TooltipText>
      {typeof description === 'string' ? (
        <TooltipText variant="body2">{description}</TooltipText>
      ) : (
        description ?? null
      )}
    </FlexBox>
  );
};
