/**
 * True if subcode is a subcode of code or same as code
 * Also handling thema aggregate cateogory A-Z specifically
 */
export const isSubcodeOf = (subcode: string, code: string): boolean => {
  return (
    code === subcode ||
    subcode.startsWith(code) ||
    codeMatchesThemaEmner(subcode, code)
  );
};

const codeMatchesThemaEmner = (code: string, childCode: string) => {
  return childCode === 'A-Z' && /^[A-Z].*/.test(code);
};
