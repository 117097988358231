import {
  Part,
  PartBool,
  PartCodeList,
  PartCodeListOrText,
  PartDate,
  PartHtml,
  PartInt,
  PartNameVariant,
  PartText,
  PartTextArea,
  PartYear,
  PartYearOrDate,
} from 'schemaDefinition/types';

export const isLeafPart = (
  part: Part,
): part is
  | PartBool
  | PartHtml
  | PartCodeList
  | PartCodeListOrText
  | PartNameVariant
  | PartInt
  | PartText
  | PartTextArea
  | PartYear
  | PartDate
  | PartYearOrDate => {
  switch (part.type) {
    case 'bool':
    case 'html':
    case 'codelist':
    case 'codelist|text':
    case 'nameVariant':
    case 'int':
    case 'text':
    case 'textarea':
    case 'year':
    case 'date':
    case 'yearOrDate':
      return true;
    case 'linkedAgent':
    case 'linkedLiterary':
    case 'thesaurus':
    case 'schema':
    case 'expand':
      return false;
  }
};
