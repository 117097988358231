import {
  Data,
  Part,
  PartExpand,
  Schema,
  SeparatedParts,
} from 'schemaDefinition/types';
import {evaluateCondition, isData} from 'schemaDefinition/functions';
import {ValidationArgs, ValidationResult} from './types';
import {assertDataValidationArgs} from './assertDataValidationArgs';
import {fail} from './fail';
import {validResult} from './validResult';
import {validateSchemaPart} from './validateSchemaPart';
import {validateValuePart} from './validateValuePart';

/**
 * validates data according to schema
 */
export const validateSchema = (
  schema: Schema,
  args: ValidationArgs,
  aggregateResult: ValidationResult,
): ValidationResult => {
  if (!isData(args.value)) {
    return fail(
      {
        ...args,
        part: schema,
        validation: 'invalid type',
        messageKey: 'invalidType',
      },
      aggregateResult,
    );
  }

  return validateParts(schema.parts, args, aggregateResult);
};

/**
 * validates data according to given parts, i.e. from a schema or expand case/default
 */
const validateParts = (
  parts: SeparatedParts,
  args: ValidationArgs,
  aggregateResult: ValidationResult,
): ValidationResult => {
  return parts.reduce(
    (acc, part) =>
      Array.isArray(part)
        ? validateParts(part, args, acc)
        : part.type === 'separator'
          ? acc
          : validatePart(part, args, acc),
    aggregateResult,
  );
};

/**
 * validates data according part, i.e.:
 * - expand part: validate data according to any active case
 * - schema part: validate data[part.name] according to subschema
 * - "value" parts: validate data[part.name] according to part
 */
export const validatePart = (
  part: Part,
  args: ValidationArgs,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  switch (part.type) {
    case 'schema': {
      return validateSchemaPart(
        part,
        assertDataValidationArgs(args),
        aggregateResult,
      );
    }
    case 'expand': {
      return validateExpandPart(
        part,
        assertDataValidationArgs(args),
        aggregateResult,
      );
    }
    default: {
      return validateValuePart(
        part,
        assertDataValidationArgs(args),
        aggregateResult,
      );
    }
  }
};

/**
 * validates data to any active case
 */
const validateExpandPart = (
  part: PartExpand,
  args: ValidationArgs<Data>,
  aggregateResult: ValidationResult,
): ValidationResult => {
  // Determine active case, if any
  const activeCase = part.when.find(w => evaluateCondition(w.condition, args));

  const activeParts = activeCase ? activeCase.parts : part.default;
  return activeParts
    ? validateParts(activeParts, args, aggregateResult)
    : aggregateResult;
};
