import {OrCondition, OrConditionV0} from '../types';
import {EvaluationArgs} from './types';
import {evaluateCondition} from './evaluateCondition';

export const evaluateOrCondition = (
  condition: OrConditionV0 | OrCondition,
  args: EvaluationArgs,
): boolean => {
  const arg =
    (condition as OrCondition).$or ?? (condition as OrConditionV0).arg;

  return arg.some(cond => evaluateCondition(cond, args));
};
