import {assert} from 'assert-ts';
import {GetTokens, Media} from './types';
import {Schemas} from './dto.generated';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpGet, httpPost} from './http/ebba';
import {mapMediaForEans} from './mappers/mapMediaDto';

export const getMediaByEans = (
  eans: string[],
  getTokens: GetTokens,
  mock?: boolean,
): Promise<Media[]> => {
  return exceptionToPromiseReject(() =>
    httpPost<Schemas.MediaForEansDto, string[]>({
      subdir: 'media/ean',
      body: eans,
      getTokens,
      mock,
    }).then(mapMediaForEans),
  );
};

export const getMediaUrl = (
  mediaId: string,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<string> => {
  return exceptionToPromiseReject(() =>
    httpGet<Schemas.DownloadInfoDto>({
      subdir: 'media/{mediaId}',
      subdirParams: {mediaId},
      getTokens,
      mock,
    }).then(dto => assert(dto.uri, 'getMediaUrl: uri expected')),
  );
};
