import React from 'react';
import {GridRenderCellParams} from '@mui/x-data-grid-pro';
import assert from 'assert-ts';
import {LocalizationContextType} from 'localization/context/types';
import {Part} from 'schemaDefinition/types';
import {Layout, TextMatch} from 'components';
import {CodeListMap, ThesaurusesMap} from '../../../api';
import {
  formatCodeListValue,
  formatLinkedValueLink,
  formatNameVariantValue,
  formatScalarValue,
} from '../../preview/functions';
import {formatThesaurusValue} from '../../preview/functions/formatThesaurusValue';

export const mapRenderCell = (
  part: Part,
  params: GridRenderCellParams,
  l: LocalizationContextType,
  c: CodeListMap,
  t: ThesaurusesMap,
  match?: RegExp,
  delimiter?: string,
): React.ReactNode => {
  assert(part.type);

  const values = Array.isArray(params.value) ? params.value : [params.value];

  switch (part.type) {
    case 'text':
    case 'bool':
    case 'date':
    case 'year':
    case 'yearOrDate':
      return (
        <Layout>
          <TextMatch variant="body1" match={match}>
            {formatScalarValue(values, part, l, delimiter)}
          </TextMatch>
        </Layout>
      );
    case 'textarea':
      return (
        <Layout>
          {values.map((v, idx) => {
            return (
              <TextMatch key={idx} variant="body1" match={match}>
                {v}
              </TextMatch>
            );
          })}
        </Layout>
      );
    case 'linkedAgent':
      return (
        <Layout>
          {values.map((v, idx) => {
            const name = formatLinkedValueLink(v);
            return name ? (
              <TextMatch key={idx} variant="body1" match={match}>
                {name}
              </TextMatch>
            ) : null;
          })}
        </Layout>
      );
    case 'nameVariant':
      return (
        <Layout>
          {values.map((v, idx) => (
            <TextMatch key={idx} variant="body1" match={match}>
              {formatNameVariantValue(v, part.agentType, 'excludeId')}
            </TextMatch>
          ))}
        </Layout>
      );
    case 'codelist':
    case 'codelist|text':
      return (
        <Layout>
          <TextMatch variant="body1" match={match}>
            {formatCodeListValue(params.value, part, c[part.codelistId])}
          </TextMatch>
        </Layout>
      );
    case 'thesaurus':
      return (
        <Layout>
          <TextMatch variant="body1" match={match}>
            {formatThesaurusValue(params.value, part, t[part.thesaurusId])}
          </TextMatch>
        </Layout>
      );
    case 'linkedLiterary':
    default:
      assert(false, `mapRenderCell: Missing mapping for part: ${part.type}`, {
        part,
      });
  }
};
