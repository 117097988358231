import {useMemo} from 'react';
import {assert} from 'assert-ts';
import {ManifestationStatus} from 'api/types';
import {Data, Schema} from 'schemaDefinition/types';
import {
  getModifiedSchema,
  getModifiedSchemaWithModifier,
} from 'schemaDefinition/functions';
import {
  MetadataModifierKeys,
  ModifiableSchemaReference,
  WorkType,
} from '../types';
import {useGetDataBasedSchemaModifier} from './useGetDataBasedSchemaModifier';
import {useSchema} from './useSchema';
import {useSchemaModifiers} from './useSchemaModifiers';

export const useModifiedSchema = (
  savedData: Data,
  workType: WorkType,
  schemaRef: ModifiableSchemaReference,
  forStatus: ManifestationStatus | undefined,
): Schema => {
  const schema = useSchema(schemaRef);
  const getDataBasedModifier = useGetDataBasedSchemaModifier(schemaRef);
  const modifiers = useSchemaModifiers(schemaRef);

  return useMemo(() => {
    const statuses = MetadataModifierKeys[workType];
    const baseSchema = assert(schema, 'useModifiedSchema: schema expected');
    const dataBasedModifier = getDataBasedModifier?.(savedData);
    const modifiedBaseSchema = dataBasedModifier
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        getModifiedSchemaWithModifier(baseSchema, dataBasedModifier)
      : baseSchema;

    return forStatus === undefined
      ? modifiedBaseSchema
      : getModifiedSchema(
          modifiedBaseSchema,
          modifiers,
          `${workType}.${forStatus}`,
          statuses,
        );
  }, [forStatus, getDataBasedModifier, modifiers, savedData, schema, workType]);
};
