import {CodeListMap, ManifestationStatus} from 'api/types';
import {ValidationResult} from 'schema/form/functions/validators/types';
import {Data, Schema, SchemaModifierMap} from 'schemaDefinition/types';
import {
  MetadataModifierKey,
  MetadataModifierKeys,
  WorkType,
} from 'schemas/types';
import {
  getModifiedSchema,
  getModifiedSchemaWithModifier,
} from 'schemaDefinition/functions';
import {validate} from 'schema/form/functions/validators/validate';
import {GetDataBasedModifier} from '../types';
import {EntityStatus} from '../types';

export const validateEntity = (
  savedEntity: Data,
  entity: Data,
  relatedScope: Data,
  workType: WorkType,
  entityStatus: EntityStatus,
  baseSchema: Schema,
  getDataBasedModifier: GetDataBasedModifier | undefined,
  modifiers: SchemaModifierMap<MetadataModifierKey>,
  codelists: CodeListMap,
): {
  validation: ValidationResult;
  status: ManifestationStatus;
} => {
  const status = entityStatus.changedStatus ?? entityStatus.status;
  const dataBasedModifier = getDataBasedModifier?.(savedEntity);
  const modifiedBaseSchema = dataBasedModifier
    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      getModifiedSchemaWithModifier(baseSchema, dataBasedModifier)
    : baseSchema;

  const modifiedSchema = getModifiedSchema(
    modifiedBaseSchema,
    modifiers,
    `${workType}.${status}`,
    MetadataModifierKeys[workType],
  );
  return {
    validation: validate(entity, relatedScope, modifiedSchema, codelists),
    status,
  };
};
