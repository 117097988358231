import {assert} from 'assert-ts';
import {Condition} from '../types';
import {EvaluationArgs} from './types';
import {evaluateAndCondition} from './evaluateAndCondition';
import {evaluateBooleanCondition} from './evaluateBooleanCondition';
import {evaluateExpressionCondition} from './evaluateExpressionCondition';
import {evaluateIncludesCondition} from './evaluateIncludesCondition';
import {evaluateLengthCondition} from './evaluateLengthCondition';
import {evaluateNotCondition} from './evaluateNotCondition';
import {evaluateOrCondition} from './evaluateOrCondition';
import {evaluateRangeCondition} from './evaluateRangeCondition';
import {evaluateRegexCondition} from './evaluateRegexCondition';
import {
  isAndCondition,
  isAndConditionV0,
  isBooleanCondition,
  isComparisonCondition,
  isComparisonConditionV0,
  isIncludesCondition,
  isLengthCondition,
  isNotCondition,
  isNotConditionV0,
  isOrCondition,
  isOrConditionV0,
  isRangeCondition,
  isRegexCondition,
} from './isConditionType';

export const evaluateCondition = (
  condition: Condition | Condition[],
  args: EvaluationArgs,
): boolean => {
  // Array?
  if (Array.isArray(condition)) {
    return condition.reduce<boolean>(
      (acc, c) => acc && evaluateCondition(c, args),
      true,
    );
  }

  if (isRangeCondition(condition)) {
    return evaluateRangeCondition(condition, args);
  }

  if (isLengthCondition(condition)) {
    return evaluateLengthCondition(condition, args);
  }

  if (isRegexCondition(condition)) {
    return evaluateRegexCondition(condition, args);
  }

  if (isComparisonCondition(condition) || isComparisonConditionV0(condition)) {
    return evaluateExpressionCondition(condition, args);
  }

  if (isNotCondition(condition) || isNotConditionV0(condition)) {
    return evaluateNotCondition(condition, args);
  }

  if (isOrCondition(condition) || isOrConditionV0(condition)) {
    return evaluateOrCondition(condition, args);
  }

  if (isAndCondition(condition) || isAndConditionV0(condition)) {
    return evaluateAndCondition(condition, args);
  }

  if (isBooleanCondition(condition)) {
    return evaluateBooleanCondition(condition, args);
  }

  if (isIncludesCondition(condition)) {
    return evaluateIncludesCondition(condition, args);
  }

  assert.soft(false, 'evaluateCondition: unknown condition', {condition});

  return false;
};
