import {FieldRequired, FieldRequiredConditional} from '../types';

export const isFieldRequiredConditional = (
  field?: FieldRequired,
): field is FieldRequiredConditional => {
  return !(
    typeof field === 'boolean' ||
    field === 'should' ||
    field === undefined
  );
};
