import assert from 'assert-ts';
import {Lock} from 'api/types';
import {Schemas} from 'api/dto.generated';

export const mapOptionalLockDto = (dto?: Schemas.LockDto): Lock | undefined =>
  dto ? mapLockDto(dto) : undefined;

export const mapLockDto = (dto: Schemas.LockDto): Lock => {
  return {
    id: assert(dto.id, 'mapLockDto: id expected'),
    contextId: assert(dto.contextId, 'mapLockDto: contextId expected'),
    contextType: assert(dto.contextType, 'mapLockDto: contextType expected'),
    lockedBy: assert(dto.lockedBy, 'mapLockDto: lockedBy expected'),
    lockedAt: assert(dto.lockedAt, 'mapLockDto: lockedAt expected'),
  };
};
