import {assert} from 'assert-ts';
import {Indexer} from 'types';
import {CodeListMap} from 'api/types';
import {
  DataValue,
  LinkedLiterary,
  PartInt,
  PartLinkedLiterary,
  PartText,
  VerifiedLinkedLiterary,
} from 'schemaDefinition/types';
import {
  evaluateFieldRequired,
  getPartCodelistFromLinkedLiterary,
  isLinkedLiterary,
} from 'schemaDefinition';
import {getLinkPropPartFromLinkedLiterary} from 'schemaDefinition/functions/getLinkPropPartFromLinkedLiterary';
import {ValidationArgs, ValidationResult} from './types';
import {addAggregateResult} from './addAggregateResult';
import {fail} from './fail';
import {getLinkedRoleCode} from './getLinkedRoleCode';
import {inTargetEntityType} from './inTargetEntityType';
import {validResult} from './validResult';
// import {getPartCodelistFromLinkedLiterary} from 'schemaDefinition/functions';
import {validateCodeList} from './validateCodeList';
import {validateConditions} from './validateConditions';
import {validateInt} from './validateInt';
import {validateText} from './validateText';
import {validateValueType} from './validateValueType';
import {warning} from './warning';

export const validateLinkedLiterary = (
  part: PartLinkedLiterary,
  args: ValidationArgs,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  return validateValueType(
    part,
    validateSingleLinkedLiteraryValue,
    args,
    aggregateResult,
  );
};

const validateSingleLinkedLiteraryValue = (
  part: PartLinkedLiterary,
  args: ValidationArgs,
  aggregateResult: ValidationResult,
): ValidationResult => {
  if (args.value === undefined || args.value === null) {
    const required = evaluateFieldRequired(
      part.required,
      args.valuePath,
      args.localScope,
      args.globalScope,
      args.relatedScope,
      args.value,
    );

    return required
      ? (required === true ? fail : warning)(
          {
            ...args,
            part,
            validation: 'required',
            messageKey: 'required',
          },
          aggregateResult,
        )
      : aggregateResult;
  }

  if (!isLinkedLiterary(args.value)) {
    return fail(
      {
        ...args,
        part,
        validation: 'invalid type',
        messageKey: 'invalid type',
      },
      aggregateResult,
    );
  }

  let result = aggregateResult;
  result = validateSingleLinkedLiteraryRole(part, args, result);

  result = validateSingleLinkedLiteraryLink(part, args, result);

  result = validateSingleLinkedLiteraryLinkProp(part, args, result);

  return result;
};

export const validateSingleLinkedLiteraryLink = (
  part: PartLinkedLiterary,
  args: ValidationArgs,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  const verified = args.value as VerifiedLinkedLiterary;

  if (!verified?.link?.entityId) {
    return fail(
      {
        ...args,
        part,
        validation: 'required',
        messageKey: 'required',
      },
      aggregateResult,
    );
  }

  if (isRoleAndLinkTypeMismatch(verified, part, args.codelistMap)) {
    return fail(
      {
        ...args,
        part,
        validation: 'invalid type',
        messageKey: 'entityMismatch',
      },
      aggregateResult,
    );
  }

  if (part.validation) {
    return validateConditions(part.validation, part, args, aggregateResult);
  }

  return aggregateResult;
};

export const validateSingleLinkedLiteraryRole = (
  part: PartLinkedLiterary,
  args: ValidationArgs,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  const rolePart = assert(
    getPartCodelistFromLinkedLiterary(part),
    'validateSingleLinkedLiteraryRoles: codelist expected',
    part,
  );
  const linkedLiterary = args.value as LinkedLiterary | undefined;
  const roleValue = linkedLiterary?.role;
  const rolePath = args.valuePath + '.role';
  const codelistResult = validateCodeList(rolePart, {
    ...args,
    value: roleValue,
    valuePath: rolePath,
  });
  let result = addAggregateResult(codelistResult, aggregateResult);

  if (codelistResult.valid === 'valid') {
    if (isRoleAndLinkTypeMismatch(linkedLiterary, part, args.codelistMap)) {
      result = fail(
        {
          ...args,
          value: roleValue,
          valuePath: rolePath,
          part: rolePart,
          validation: 'invalid type',
          messageKey: 'entityMismatch',
        },
        result,
      );
    }
  }

  return result;
};

export const validateSingleLinkedLiteraryLinkProp = (
  part: PartLinkedLiterary,
  args: ValidationArgs,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  const linkedLiterary = args.value as LinkedLiterary | undefined;
  if (!linkedLiterary || !linkedLiterary.role || !args.codelistMap) {
    return aggregateResult;
  }

  const linkedRoleCode = getLinkedRoleCode(
    linkedLiterary.role,
    part,
    args.codelistMap,
  );
  if (!linkedRoleCode || !linkedRoleCode.linkProperty) {
    return aggregateResult;
  }

  const linkPropPart = assert(
    getLinkPropPartFromLinkedLiterary(part, linkedRoleCode),
    'validateSingleLinkedLiteraryRoles: codelist expected',
    part,
  );
  const {type, name} = linkPropPart;
  const linkPropValue = (linkedLiterary as Indexer)?.[name] as DataValue | null;
  const linkPropPath = args.valuePath + '.' + name;
  const linkPropResult =
    type === 'int'
      ? validateInt(linkPropPart as PartInt, {
          ...args,
          value: linkPropValue,
          valuePath: linkPropPath,
        })
      : validateText(linkPropPart as PartText, {
          ...args,
          value: linkPropValue,
          valuePath: linkPropPath,
        });

  const result = addAggregateResult(linkPropResult, aggregateResult);

  return result;
};

const isRoleAndLinkTypeMismatch = (
  value: LinkedLiterary | undefined,
  part: PartLinkedLiterary,
  codelistMap: CodeListMap | undefined,
): boolean => {
  if (!value || !value.role || value.link?.linkStatus !== 'verified') {
    return false;
  }

  const linkedRoleCode = getLinkedRoleCode(value.role, part, codelistMap);
  if (!linkedRoleCode) {
    return false;
  }

  return !inTargetEntityType(value.link?.type, linkedRoleCode.targetEntity);
};
