import {assert} from 'assert-ts';
import {Concept} from 'types';
import {CodeListRef, GenreAndFormRef, ThemaRef, WorkV4} from 'api/types';
import {
  AnyValidation,
  Condition,
  Part,
  PartSchema,
  SelectableThesaurusNodeType,
  Separator,
  TypedSchema,
  TypedSchemaModifierMap,
} from 'schemaDefinition/types';
import {WorkImportProps} from 'services/data/metadata/types';
import {MetadataModifierKey} from '../types';
import {
  isUnnamedAgentLink,
  validateExternalLinkedAgentId,
  validateLinkedAgentId,
} from './partValidations';

type WorkSchema = TypedSchema<WorkV4, WorkImportProps>;

type WorkCondition<TName extends keyof WorkV4> = Condition<
  WorkV4,
  WorkV4,
  WorkV4[TName],
  WorkImportProps
>;

type WorkValidation<TName extends keyof WorkV4> = AnyValidation<
  WorkV4,
  WorkV4,
  WorkV4[TName],
  WorkImportProps
>;

type WorkPart = Part<WorkV4, WorkV4, WorkImportProps>;

type WorkPartSchema = PartSchema<WorkV4, WorkV4, WorkImportProps>;

/**
 * WorkWithExpressions schema
 */

export const deweyRegex = [
  // Just 3 digits
  '^[0-9]{3}$',
  // Or more digits with .
  '^[0-9]{3}\\.[0-9]+$',
  // Or more digits with /.
  '^[0-9]{3}/\\.[0-9]+$',
  // Or more digits with .--/--
  '^[0-9]{3}\\.[0-9]+/[0-9]+$',
];

const isBook: WorkCondition<'type'> = {
  regex: CodeListRef.WORK_TYPE['Bok'],
  arg: {
    $ref: ':type',
  },
  default: true, // BOOK is default although type is required
};

const isSchoolBook: WorkCondition<'literatureType'> = {
  regex: CodeListRef.LITERATURE_TYPE['Skolebøker'],
  arg: {
    $ref: ':literatureType',
  },
  default: false,
};

const isNotSchoolBook: WorkCondition<'literatureType'> = {
  op: 'not',
  arg: isSchoolBook,
};

const litteraturType_includesLærebok: WorkCondition<'literatureType'> = {
  $includes: {
    arg1: {$ref: ':literatureType'},
    arg2: CodeListRef.LITERATURE_TYPE['Lærebøker (høyere utd.)'],
  },
  default: false,
};

const isSkjønnlitteratur: WorkCondition<'literatureType'> = {
  $includes: {
    arg1: {$ref: ':literatureType'},
    arg2: CodeListRef.LITERATURE_TYPE['Skjønnlitteratur'],
  },
  default: false,
};

const isIntellectualLevel_children: WorkCondition<'intellectualLevel'> = {
  $includes: {
    arg1: {$ref: ':intellectualLevel'},
    arg2: [
      CodeListRef.INTELLECTUAL_LEVEL['0-3'],
      CodeListRef.INTELLECTUAL_LEVEL['3-6'],
      CodeListRef.INTELLECTUAL_LEVEL['6-9'],
      CodeListRef.INTELLECTUAL_LEVEL['9-12'],
      CodeListRef.INTELLECTUAL_LEVEL['12-16'],
    ],
  },
  default: false,
};

const emptySubject: WorkCondition<'subjects'> = {
  $not: {arg: {$ref: ':subjects'}},
};

const emptyGenreAndForm: WorkCondition<'genreAndForm'> = {
  $not: {arg: {$ref: ':genreAndForm'}},
};

const genreAndForm_includesLærebok: WorkCondition<'genreAndForm'> = {
  $includes: {
    arg1: {$ref: ':genreAndForm'},
    arg2: GenreAndFormRef['Lærebøker'],
  },
  default: false,
};

const thema_includesHøyereUtdanning: WorkCondition<'themes'> = {
  regex: `${ThemaRef['Høyere utdanning']}.*`,
  arg: {
    $ref: ':themes',
  },
  default: false,
};

const thema_includesAge: WorkCondition<'themes'> = {
  regex: `${ThemaRef['Intellektuelt nivå']}.*`,
  arg: {
    $ref: ':themes',
  },
  default: false,
};

/**
 *  Either litteraturtype is not lærebok or thema includes høyere utdanning
 */
const themes_validation_litteraturType_higherEducation: WorkValidation<'themes'> =
  {
    $or: [
      {
        $not: litteraturType_includesLærebok,
      },
      thema_includesHøyereUtdanning,
    ],
    level: 'warning',
    messageKey: 'themes.lærebok.mismatch',
  };

const theme_validation_intellectualLevel_children: WorkValidation<'themes'> = {
  $or: [
    {
      $not: isIntellectualLevel_children,
    },
    thema_includesAge,
  ],
  level: 'warning',
  messageKey: 'themes.intellectualLevel.mismatch',
};

export const deweyPart: WorkPartSchema = {
  type: 'schema',
  name: 'dewey',
  cardinality: '2',
  compare: 'items',
  parts: [
    [
      {
        type: 'text',
        name: 'value',
        required: true,
        validation: {
          regex: deweyRegex,
          default: true,
        },
        customizeKey: 'deweyNumber',
        labelKey: 'deweyNumber',
      },
      {
        type: 'codelist',
        name: 'source',
        codelistId: 'DEWEY_SOURCE',
        required: true,
        default: CodeListRef.DEWEY_SOURCE['23/nor'],
        customizeKey: 'deweySource',
        labelKey: 'deweySource',
        validation: [
          // Source must be equal to [0].source
          {
            op: 'eq',
            arg2: {$ref: '/dewey[0].source'}, // Must use global path, since we are inside a sub-schema
          },
        ],
      },
    ],
  ],
};

export const intellectualLevelPart: WorkPart = {
  type: 'codelist',
  name: 'intellectualLevel',
  labelKey: 'intellectualLevel',
  cardinality: 'multiple',
  codelistId: 'INTELLECTUAL_LEVEL',
  customizeKey: 'intellectualLevel',
};

/** YP* => 4C*, or equivalent: (!YP* or 4C*) */
export const workValidation_YcodeRequires5Acode: WorkValidation<'themes'> = {
  id: 'YPcodeRequires4Ccode',
  messageKey: 'YPcodeRequires4Ccode',
  op: 'or',
  arg: [
    {
      op: 'not',
      arg: {
        regex: '"YP.*"',
        default: false,
      },
    },
    {
      regex: '"4C.*"',
      default: false,
    },
  ],
};

const selectableGrepNodeTypes: SelectableThesaurusNodeType[] = [
  'education_program_type',
  'education_program',
  'program_area',
  'subject_code',
];

export const workDataSchema: WorkSchema = {
  name: 'work',
  parts: [
    {
      type: 'codelist',
      name: 'type',
      required: true,
      codelistId: 'WORK_TYPE',
    },
    {
      type: 'linkedAgent',
      name: 'agents',
      cardinality: 'multiple',
      roleCodelistId: 'WORK_ROLE_TYPE',
      entitySubtypes: [Concept.person, Concept.corporation, Concept.event],
      compare: 'subValues',
    },
    {
      type: 'expand',
      role: 'showTitleAsMainEntryIfBook',
      when: [
        {
          condition: isBook,
          parts: [
            {
              type: 'bool',
              name: 'useTitleAsMainEntry',
              labelKey: 'titleAsMainEntry',
            },
          ],
        },
      ],
    },
    Separator('card', 'line'),
    {
      type: 'text',
      name: 'preferredTitle',
      labelKey: 'preferredTitle',
      cardinality: 'multiple',
      compare: 'items',
    },
    {
      type: 'schema',
      name: 'otherTitles',
      parts: [
        [
          {
            type: 'text',
            name: 'value',
            labelKey: 'otherTitle.value',
            required: true,
          },
          {
            type: 'codelist',
            name: 'type',
            labelKey: 'otherTitle.type',
            codelistId: 'WORK_TITLE_TYPE',
            required: true,
          },
        ],
      ],
      cardinality: 'multiple',
    },
    {
      type: 'codelist',
      name: 'languages',
      labelKey: 'language',
      codelistId: 'LANGUAGE',
      cardinality: 'multiple',
    },
    Separator('card', 'line'),
    {
      type: 'linkedLiterary',
      name: 'links',
      labelKey: 'links',
      cardinality: 'multiple',
      //linkPropRequired: 'should',
      roleCodelistId: 'work.linkedRole',
      compare: 'items',
    },
    Separator('card', 'line'),
    {
      type: 'expand',
      role: 'showIfBook',
      when: [
        {
          condition: isBook,
          parts: [
            {
              type: 'codelist',
              name: 'literatureType',
              cardinality: 'multiple',
              codelistId: 'LITERATURE_TYPE',
              customizeKey: 'literatureType',
              listValidation: {
                // Hvis litteraturtype er Lærebøker (høyere utd.), så må også faglitteratur være satt:
                // ('Faglitteratur' or not 'Lærebøker (høyere utd.)')
                op: 'or',
                arg: [
                  {
                    regex: CodeListRef.LITERATURE_TYPE['Faglitteratur'],
                    default: false,
                  },
                  {
                    op: 'not',
                    arg: {
                      regex:
                        CodeListRef.LITERATURE_TYPE['Lærebøker (høyere utd.)'],
                      default: false,
                    },
                  },
                ],
                messageKey: 'literatureType.LærebøkerRequiresFaglitteratur',
              },
            },
            deweyPart,
          ],
        },
      ],
    },
    {
      // Vis trinn og målgruppe når litteraturtype er skolebøker
      type: 'expand',
      role: 'showIfSchoolbook',
      when: [
        {
          condition: [isBook, isSchoolBook],
          parts: [
            {
              type: 'codelist',
              name: 'educationLevel',
              codelistId: 'EDUCATION_LEVEL',
              cardinality: 'multiple',
            },
            {
              type: 'codelist',
              name: 'educationTargetGroup',
              codelistId: 'EDUCATION_TARGET_GROUP',
              cardinality: 'multiple',
            },
          ],
        },
      ],
      default: [intellectualLevelPart],
    },
    {
      type: 'expand',
      role: 'showBibleIfBook',
      when: [
        {
          condition: isBook,
          parts: [
            Separator('card', 'line'),
            {
              type: 'codelist',
              name: 'bibleVersion',
              codelistId: 'BIBLE_VERSION',
            },
            {
              type: 'codelist',
              name: 'bibleContent',
              codelistId: 'BIBLE_CONTENT',
              cardinality: 'multiple',
            },
          ],
        },
      ],
    },
    {
      type: 'expand',
      role: 'showAdultImmigrantsIfNotSchoolBook',
      when: [
        {
          condition: [isBook, isNotSchoolBook],
          parts: [
            Separator('card', 'line'),
            {
              type: 'bool',
              name: 'norwegianForAdultImmigrants',
              labelKey: 'norwegianForAdultImmigrants',
            },
          ],
        },
      ],
    },
    {
      type: 'expand',
      role: 'showGrepIfBookAndSchoolbook',
      when: [
        {
          condition: [isBook, isSchoolBook],
          parts: [
            Separator('card', 'line'),
            {
              type: 'thesaurus',
              name: 'grep',
              cardinality: 'multiple',
              thesaurusId: 'grep',
              showCode: true,
              selectableNodeTypes: selectableGrepNodeTypes,
              variant: 'byType',
            },
          ],
        },
      ],
    },
    Separator('card', 'line'),
    {
      type: 'thesaurus',
      name: 'genreAndForm',
      cardinality: 'multiple',
      thesaurusId: 'genreandform',
      showCode: false,
      validation: {
        // Either litteraturtype is not lærebok or genreAndForm includes lærebok
        $or: [
          {
            $not: litteraturType_includesLærebok,
          },
          genreAndForm_includesLærebok,
        ],
        level: 'warning',
        messageKey: 'genreAndForm.lærebok.mismatch',
      },
    },
    {
      type: 'expand',
      role: 'showSubjectIfBookAndNotSchoolbook',
      when: [
        {
          condition: [isBook, isNotSchoolBook],
          parts: [
            Separator('card', 'line'),
            {
              type: 'thesaurus',
              name: 'subjects',
              cardinality: 'multiple',
              thesaurusId: 'bokbasen',
              showCode: false,
            },
          ],
        },
      ],
    },
    Separator('card', 'line'),
    {
      type: 'thesaurus',
      name: 'themes',
      cardinality: 'multiple',
      thesaurusId: 'thema',
      showCode: true,
      listValidation: themes_validation_litteraturType_higherEducation,
    },
    Separator('card', 'line'),
    {
      type: 'text',
      name: 'generalNote',
      labelKey: 'generalNote',
      cardinality: 'multiple',
    },
  ],
};

export const workSchemaModifiers: TypedSchemaModifierMap<
  WorkV4,
  MetadataModifierKey
> = {
  'MERCHANDISE.NEW': [
    {
      // Required by backend so temporarily required here
      name: 'preferredTitle',
      required: true,
    },
  ],
  'MERCHANDISE.PRE_CATALOGED': [
    {
      name: 'languages',
      required: 'should',
    },
  ],
  'MERCHANDISE.CONTROL': [
    {
      name: 'languages',
      required: true,
    },
  ],
  'BOOK.NEW': [
    {
      name: 'preferredTitle',
      required: true,
    },
  ],
  'BOOK.PRE_CATALOGED': [
    {
      name: 'languages',
      required: 'should',
    },
    {
      name: 'literatureType',
      required: true,
    },
    {
      name: 'intellectualLevel',
      required: 'should',
    },
    {
      name: 'genreAndForm',
      required: {
        should: {
          $or: [emptySubject, isSkjønnlitteratur],
        },
      },
    },
    {
      name: 'subjects',
      required: {
        should: emptyGenreAndForm,
      },
    },
    {
      name: 'themes',
      listValidation: [theme_validation_intellectualLevel_children],
    },
  ],
  'BOOK.CONTROL': [
    {
      name: 'languages',
      required: true,
      listValidation: {
        level: 'warning',
        $not: {
          $eq: [{$ref: ':languages'}, [CodeListRef.LANGUAGE.Flerspråklig]],
        },
        messageKey: 'languages.not.only.multilingual',
        default: true,
      },
    },
    {
      name: 'intellectualLevel',
      required: true,
    },
    {
      name: 'links',
      validation: {
        // Valdiation will only trigger for links with languages, i.e. series,
        // where one of the languages of the series must match the language of the work
        $includes: {
          arg1: {$ref: '.link.languages'},
          arg2: {$ref: ':languages[0]'},
        },
        default: true,
        messageKey: 'link.series.languageMismatch',
        level: 'warning',
      },
    },
    {
      name: 'bibleContent',
      validation: {
        level: 'error',
        arg: {$ref: ':bibleVersion'},
        messageKey: 'bibleVersion.missing',
      },
    },
    {
      name: 'dewey',
      required: 'should',
    },
    {
      name: 'agents',
      required: {
        should: {op: 'not', arg: isUnnamedAgentLink},
      },
      validation: [validateLinkedAgentId, validateExternalLinkedAgentId],
    },
    {
      name: 'educationLevel',
      required: 'should',
    },
    {
      name: 'educationTargetGroup',
      required: 'should',
    },
    {
      name: 'grep',
      required: 'should',
    },
    {
      name: 'genreAndForm',
      required: {
        should: {
          $or: [
            {$not: {arg: {$ref: ':subjects'}}},
            {
              $includes: {
                arg1: {$ref: ':literatureType'},
                arg2: CodeListRef.LITERATURE_TYPE['Skjønnlitteratur'],
              },
              default: false,
            },
          ],
        },
      },
    },
    {
      name: 'subjects',
      required: {
        should: emptyGenreAndForm,
      },
    },
    {
      name: 'themes',
      required: 'should',
      listValidation: {
        ...workValidation_YcodeRequires5Acode,
        id: assert(workValidation_YcodeRequires5Acode.id),
        level: 'warning',
      },
    },
  ],
};
