import React, {useMemo} from 'react';
import {PartBool} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {CheckboxField} from 'components';
import {useUniqueFieldId} from 'components/fields/hooks/useUniqueFieldId';
import {getBoxLabel} from 'schema/form/functions';
import {BaseFieldProps} from './types';
import {useFormSchemaGlobalScopeContext} from '../../../contexts';

type Props = BaseFieldProps & {
  part: PartBool;
};

export const Checkbox: React.FC<Props> = ({
  part,
  value,
  setFieldValue,
  valuePath,
  label,
  width,
  flex,
  maxWidth,
  mode,
}) => {
  const localization = useLocalization();
  const {schema} = useFormSchemaGlobalScopeContext();
  const fieldId = useUniqueFieldId(valuePath);

  const boxLabel = useMemo(
    () => getBoxLabel(localization, part, schema),
    [localization, part, schema],
  );

  return (
    <CheckboxField
      fieldId={fieldId}
      name={valuePath}
      label={label ?? ''}
      boxLabel={boxLabel}
      flex={flex}
      width={width}
      maxWidth={maxWidth}
      readonly={mode === 'read-only'}
      value={!!value}
      onChange={newVal => setFieldValue(valuePath, newVal)}
    />
  );
};
