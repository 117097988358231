import {FormMode} from 'schema/form/types';
import {Schema} from 'schemaDefinition/types';
import {
  getSchemaFlattended,
  hasNestedCompare,
} from 'schemaDefinition/functions';

/**
 * In case of 'compare'-mode, schema is simplified:
 * - horizontal layout (nested part arrays) changed to vertical layout
 * @param mode
 * @param schema
 * @returns
 */
export const getSchemaForMode = (mode: FormMode, schema: Schema): Schema => {
  if (mode !== 'compare') return schema;

  return getSchemaFlattended(schema, hasNestedCompare);
};
