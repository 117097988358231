import {DataValue, Part, PartType, Valid} from 'schemaDefinition/types';
import {nameVariantValidator} from './nameVariantValidator';

type Validator = (part: Part, value: DataValue) => Valid;

const validatorMap: {[key in PartType]?: Validator} = {
  nameVariant: nameVariantValidator,
};

export const getCustomValidator = (part: Part): Validator | undefined => {
  return validatorMap[part.type];
};
