import {Data, DataValue, Part} from 'schemaDefinition/types';

export const getValue = (
  data: Data | undefined,
  part: Part,
  valuePath: string,
): {value: DataValue | undefined; valuePath: string} => {
  const path = (
    valuePath ? valuePath + '.' + part.name : part.name ?? ''
  ) as string;
  const value = part.name ? data?.[part.name] : undefined;
  return {value, valuePath: path};
};
