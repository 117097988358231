import {FieldRootKeys} from 'schemaDefinition/functions/types';
import {PartName, Part} from 'schemaDefinition/types';

export const getFieldRootKeys = (
  schemaKey: string | undefined,
  noLabel: boolean | undefined,
  labelKey: Part['labelKey'] | undefined,
  name: PartName | undefined,
): FieldRootKeys | undefined => {
  return noLabel || !(labelKey || name)
    ? undefined
    : {
        schemaScoped: schemaKey
          ? `form.${schemaKey}.field.${labelKey ?? name}`
          : undefined,
        global: `form.global.field.${labelKey ?? name}`,
      };
};
