import {assert} from 'assert-ts';
import {PartLinkedLiterary} from 'schemaDefinition/types';
import {CodeListMap} from 'api';
import {LinkedRoleCode, RoleEntityCode} from 'schemaDefinition/types/linkTypes';

export const getLinkedRoleCode = (
  code: RoleEntityCode,
  part: PartLinkedLiterary,
  codelistMap: CodeListMap | undefined,
): LinkedRoleCode | undefined => {
  const map = assert(codelistMap, 'assertLinkedRoleCode: codeListMap expected');

  return map[part.roleCodelistId]?.codes?.find(
    (c: LinkedRoleCode) => c.code === code,
  );
};
