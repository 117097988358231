import {PartCodeList, PartLinkedLiterary} from 'schemaDefinition/types';
import {CodeListId} from 'api';

export const getPartCodelistFromLinkedLiterary = (
  partLinkedLiterary: PartLinkedLiterary,
): PartCodeList | undefined => {
  return {
    type: 'codelist',
    name: 'role',
    codelistId: partLinkedLiterary.roleCodelistId as CodeListId,
    required: true,
  };
};
