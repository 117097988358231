import {Part} from 'schemaDefinition/types';
import {usePreviewConfigurationContext} from '../contexts';

export const usePartLayout = (part: Part | undefined) => {
  const config = usePreviewConfigurationContext();
  if (!part) {
    return undefined;
  }

  const partKey = part.labelKey ?? part.name;
  const layout = partKey ? config.layout?.parts?.[partKey] : undefined;

  return layout;
};
