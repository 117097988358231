import {Concept} from 'types';
import {AgentEvent} from 'api/types';
import {Separator, TypedSchema} from 'schemaDefinition/types';
import {agentBAREParts} from './parts';

export const agentEventSchemas: TypedSchema<AgentEvent> = {
  name: 'event',
  key: 'event',
  parts: [
    {...Separator('card', 'line'), titleKey: 'nameVariants'},
    {
      type: 'nameVariant',
      agentType: Concept.event,
      name: 'nameVariants',
      cardinality: 'multiple',
      required: true,
    },
    Separator('card', 'line'),
    [...agentBAREParts],
    ...([
      {
        type: 'linkedLiterary',
        name: 'links',
        labelKey: 'links',
        cardinality: 'multiple',
        //linkPropRequired: 'should',
        roleCodelistId: 'agent.linkedRole',
      },
    ] as TypedSchema<AgentEvent>['parts']),
    Separator('line', 'line'),
    {
      type: 'text',
      name: 'place',
    },
    [
      {
        type: 'yearOrDate',
        name: 'from',
        validation: [
          {
            max: {$ref: ':to'},
            messageKey: 'birthBeforeDeath',
          },
        ],
      },
      {
        type: 'yearOrDate',
        name: 'to',
        validation: {
          min: {$ref: ':from'},
          messageKey: 'deathAfterBirth',
        },
      },
    ],
    {
      type: 'textarea',
      name: 'externalComments',
      maxLength: 5000,
    },
    {...Separator('card', 'line'), titleKey: 'internalComments'},
    {
      type: 'textarea',
      name: 'internalComments',
      maxLength: 5000,
    },
  ],
};
