import {useMemo} from 'react';
import {Schema} from 'schemaDefinition/types';
import {CodeListId} from 'api';
import {getSchemaListCodelistIds} from 'schemaDefinition/functions';
import {LinkedRoleCodeListId} from 'schemaDefinition/types/linkTypes';

export const useSchemaListCodelistIds = (
  schemas: Schema[],
): (CodeListId | LinkedRoleCodeListId)[] => {
  return useMemo(() => getSchemaListCodelistIds(schemas), [schemas]);
};
