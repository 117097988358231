import assert from 'assert-ts';
import {PreviewConfiguration} from 'schema/types';
import {Data, Part, PartSeparator, SeparatedPart} from 'schemaDefinition/types';
import {evaluateCondition} from 'schemaDefinition';

/**
 * Expand active case/default of any expand parts based on actual value and config context,
   i.e. resulting parts will not contain expand parts
 */
export const getExpandedSchemaParts = (
  value: Data,
  parts: SeparatedPart[],
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  config: PreviewConfiguration,
): SeparatedPart[] => {
  const expandedparts = parts.reduce<SeparatedPart[]>((acc, part) => {
    if (Array.isArray(part)) {
      const expandedRowParts = part.reduce<Part[]>((rowAcc, rowPart) => {
        assert(
          !Array.isArray(rowPart),
          'getExpandedSchemaParts: rowPart cannot be array',
        );
        const expandedRowPart = getExpandedPart(
          value,
          rowPart,
          valuePath,
          localScope,
          globalScope,
          config,
        );
        // @TODO: Fix typing from getExpandedPart
        rowAcc.push(...(expandedRowPart as Part[]));
        return rowAcc;
      }, []);

      acc.push(expandedRowParts);
      return acc;
    }

    const expandedPart = getExpandedPart(
      value,
      part,
      valuePath,
      localScope,
      globalScope,
      config,
    );
    acc.push(...expandedPart);
    return acc;
  }, []);

  return expandedparts;
};

const getExpandedPart = (
  value: Data,
  part: PartSeparator | Part,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  config: PreviewConfiguration,
): SeparatedPart[] => {
  if (part.type !== 'expand') {
    return [part];
  }

  const activeCase = part.when.find(w =>
    evaluateCondition(w.condition, {
      valuePath,
      localScope,
      globalScope,
      relatedScope: config.relatedScope,
      value,
    }),
  );

  return activeCase?.parts ?? part.default ?? [];
};
