import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {HttpError} from 'api/http/types';
import {RegisteredReceiveContentResponse} from 'api/types';
import {useLocalization} from 'localization';
import {postManifestationPhysicalContentReceived} from 'api';
import {useGetTokens} from 'services/auth';
import {useCodelist} from 'services/codeLists';
import {
  clearReceivedItems,
  setReceivedItems,
  useReceivedItems,
} from 'services/receiveItems';
import {Builder} from 'services/utils';
import {ReceiveEan} from '../types';

export const useReceiveEans = (): ReceiveEan => {
  const dispatch = useDispatch();
  const productFormCodelist = useCodelist('PRODUCT_FORM');
  const attempts = useReceivedItems();
  const getTokens = useGetTokens();
  const {tryT, t} = useLocalization();

  const createReason = useCallback(
    (response: RegisteredReceiveContentResponse): string | undefined => {
      const updatedString = `${response.updated ? t('page.receive.response.updated') : t('page.receive.response.already_received')}`;
      const dateString = `${t('page.receive.response.dato.label')}: ${response.date ? new Date(response.date).toLocaleDateString('no') : t('general.empty')}`;
      const statusString = `${t('page.receive.response.status.label')}: ${response.catalogingStatus ? t(`entity.manifestation.status.full.${response.catalogingStatus}`) : t('general.empty')}`;
      const productForm = productFormCodelist?.codes.find(
        code => code.code === response.productForm,
      )?.value;

      const reason = new Builder(updatedString)
        .add(' - ', dateString)
        .add(' - ', statusString)
        .add(
          ` - ${t('page.receive.response.productForm.label')}: `,
          productForm,
        ).value;

      return reason;
    },
    [productFormCodelist?.codes, t],
  );

  const addEan = useCallback(
    (ean: string): void => {
      postManifestationPhysicalContentReceived(ean, getTokens)
        .then(response => {
          dispatch(
            setReceivedItems([
              ...attempts,
              {ean, error: false, reason: createReason(response)},
            ]),
          );
        })
        .catch((error: HttpError) => {
          dispatch(
            setReceivedItems([
              ...attempts,
              {
                ean,
                error: true,
                reason:
                  tryT(`page.receive.response.error.${error.status}`) ??
                  t('page.receive.response.error.unknown'),
              },
            ]),
          );
        });
    },
    [attempts, createReason, dispatch, getTokens, t, tryT],
  );

  const clearEans = useCallback(() => {
    dispatch(clearReceivedItems());
  }, [dispatch]);

  return {
    addEan,
    clearEans,
    attempts,
  };
};
