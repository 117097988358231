import {IncludesCondition} from 'schemaDefinition/types';
import {EvaluationArgs} from './types';
import {evaluateSingleOrArrayVal} from './evaluateSingleOrArrayVal';
import {isArg1AndArg2} from './isArg1AndArg2';
import {isNullish} from './isNullish';

/**
 * If any values cannot be converted to argType, then the condition is undefined.
 */
export const evaluateIncludesCondition = (
  condition: IncludesCondition,
  args: EvaluationArgs,
): boolean =>
  //  | undefined
  {
    const includes = condition.$includes;

    const [arg1, arg2] = isArg1AndArg2(includes)
      ? [
          evaluateSingleOrArrayVal(includes.arg1, args),
          evaluateSingleOrArrayVal(includes.arg2, args),
        ]
      : [args.value, evaluateSingleOrArrayVal(includes, args)];

    if (isNullish(arg1) || isNullish(arg2)) {
      return condition.default ?? false;
    }

    const arg1Val = Array.isArray(arg1) ? arg1 : [arg1];
    const arg2Val = Array.isArray(arg2) ? arg2 : [arg2];

    // If arg2 is empty, the condition is true (all of arg2 (nothing) is included in arg1).
    if (arg2Val.length === 0) {
      return true;
    }

    // If arg1 is empty, the condition is false.
    if (arg1Val.length === 0) {
      return condition.default ?? false;
    }

    return arg1Val.some(val => arg2Val.includes(val));
  };
