import {useEffect, useState} from 'react';
import {HttpError} from 'api/http/types';
import {DataLoadStatus} from '../types';

/**
 * Handles loading of a resource (entity) given a load function,
 * i.e. returns resource with load stauts ('NotLoaded', 'Loading', 'Loaded', 'Failed')
 *
 * get - function to load resource from api
 * returns resource with load status
 */
export const useGetResource = <TData>(
  get: () => Promise<TData>,
  /** Millisecs before result expires and should be reloaded */
  expirationMs?: number,
  /** Delay millisecs before calling */
  delayMs?: number,
): DataLoadStatus<TData> => {
  const [result, setResult] = useState<DataLoadStatus<TData>>({
    status: 'NotLoaded',
  });
  const [awaitDelay, setAwaitDelay] = useState(delayMs !== undefined);

  // When status is 'NotLoaded'
  // => load resource
  useEffect(() => {
    if (result.status === 'NotLoaded' && !awaitDelay) {
      setResult(old => ({status: 'Loading', data: old.data}));
      get()
        .then(data => {
          setResult(() => ({status: 'Loaded', data}));
        })
        .catch((error: HttpError) => {
          setResult(() => ({status: 'Failed', error}));
        });
    }
  }, [awaitDelay, get, result.status]);

  // When expirationMs is set
  // => reset status to 'NotLoaded' after expirationMs
  useEffect(() => {
    if (expirationMs) {
      const handle = setInterval(() => {
        setResult(old => {
          return old.status === 'Loaded' || old.status === 'Failed'
            ? {status: 'NotLoaded', data: old.data}
            : old;
        });
      }, expirationMs);

      return () => clearInterval(handle);
    }
  }, [expirationMs, get, result.status]);

  // When delay is set
  // => set await to false after delayMs
  useEffect(() => {
    if (delayMs !== undefined) {
      const handle = setTimeout(() => {
        setAwaitDelay(false);
      }, delayMs);

      return () => clearTimeout(handle);
    }
  }, [delayMs, expirationMs, get, result.status]);

  return result;
};
