import {assert} from 'assert-ts';
import {LinkEntityType} from 'types';
import {LocalizationContextType} from 'localization/context/types';
import {Locale} from 'localization/types';
import {
  GeneralLinkedRoleCodeListId,
  LinkProperty,
  LinkRole,
  LinkType,
  LinkedRoleCode,
  LinkedRoleCodeList,
  LinkedRoleCodeListId,
  SpecificLinkedRoleCodeListId,
} from 'schemaDefinition/types/linkTypes';
import {
  getEntityMainType,
  isSpecificLinkedRoleCodeListId,
} from 'services/utils';

type CacheKey = `${Locale}.${LinkedRoleCodeListId}`;
const codelistCache: {[id in CacheKey]?: LinkedRoleCodeList} = {};

/**
 * For now: Get type of entity to link from from codelistId (e.g. from 'work.linkedRole').
 * Based on this, all possible roles with target types are determined.
 * @param codelistId
 * @param links
 * @param localization
 * @returns
 */
export const getLinkedSourceRoleCodelist = (
  codelistId: LinkedRoleCodeListId,
  links: LinkType[],
  localization?: LocalizationContextType,
): LinkedRoleCodeList => {
  const locale = localization?.locale ?? 'en';
  const cacheKey: CacheKey = `${locale}.${codelistId}`;
  if (!codelistCache[cacheKey]) {
    const codes = isSpecificLinkedRoleCodeListId(codelistId)
      ? getSpecificLinkedSourceRoleCodelist(codelistId, links, localization)
      : getGeneralLinkedSourceRoleCodelist(codelistId, links, localization);

    const codelist: LinkedRoleCodeList = {
      id: codelistId,
      codes,
    };

    codelistCache[cacheKey] = codelist;
  }

  return assert(codelistCache[cacheKey]);
};

const getSpecificLinkedSourceRoleCodelist = (
  codelistId: SpecificLinkedRoleCodeListId,
  links: LinkType[],
  localization?: LocalizationContextType,
): LinkedRoleCode[] => {
  switch (codelistId) {
    case 'realPerson.linkedRole': {
      const codes = getGeneralLinkedSourceRoleCodelist(
        'person.linkedRole',
        links,
        localization,
      );
      return codes.filter(code => code.role !== 'pseudonymFor');
    }
    case 'pseudonym.linkedRole': {
      const codes = getGeneralLinkedSourceRoleCodelist(
        'person.linkedRole',
        links,
        localization,
      );
      return codes.filter(code => code.role !== 'hasPseudonym');
    }
  }

  assert(
    false,
    `getSpecificLinkedSourceRoleCodelist: unknown codelistId ${codelistId}`,
  );
};

export const getGeneralLinkedSourceRoleCodelist = (
  codelistId: GeneralLinkedRoleCodeListId,
  links: LinkType[],
  localization?: LocalizationContextType,
): LinkedRoleCode[] => {
  const fromEntity = codelistId.split('.')[0] as LinkEntityType;
  const codes = getSourceCodes(fromEntity, links, localization);

  const mainType = getEntityMainType(fromEntity) as LinkEntityType;
  if (mainType !== fromEntity) {
    const mainCodes = getSourceCodes(mainType, links, localization);
    codes.push(...mainCodes);
  }

  return codes;
};

const getSourceCodes = (
  fromEntity: LinkEntityType,
  links: LinkType[],
  localization?: LocalizationContextType,
) => {
  const codes = links.reduce<LinkedRoleCode[]>((acc, link) => {
    if (link.typeA === fromEntity) {
      acc.push(
        makeLinkedSourceRoleCode(
          link.typeA,
          link.roleA,
          link.typeB,
          link.linkProperty,
          false,
          localization,
        ),
      );
    }
    if (
      link.typeB === fromEntity &&
      // Don't add the same role twice, i.e. when symmetric link, e.g. corp.relatedTo.corp
      (link.typeB !== link.typeA || link.roleB !== link.roleA)
    ) {
      acc.push(
        makeLinkedSourceRoleCode(
          link.typeB,
          link.roleB,
          link.typeA,
          link.linkProperty,
          link.oneWayEdit,
          localization,
        ),
      );
    }
    return acc;
  }, []);

  return codes;
};

const makeLinkedSourceRoleCode = (
  sourceType: LinkEntityType,
  role: LinkRole,
  targetType: LinkEntityType,
  linkProperty: LinkProperty | undefined,
  disabled?: boolean,
  localization?: LocalizationContextType,
): LinkedRoleCode => {
  const code = `${role}.${targetType}`;
  const valueKey = `link.${sourceType}.${code}.label`;
  const value = localization
    ? localization.tLoose(valueKey) ?? `Missing ${valueKey}`
    : code;

  return {
    code,
    value,
    targetEntity: targetType,
    role,
    linkProperty,
    disabled,
  };
};
