import React from 'react';
import {
  Icon as MuiIcon,
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from '@mui/material';
import {IconName} from 'components/icons/types';
import {px2rem} from 'theme';
import {Icon} from 'components/icons';
import {TooltipText} from './TooltipText';

const slotProps: MuiTooltipProps['slotProps'] = {
  tooltip: {
    sx: {
      marginTop: `${px2rem(20)}!important`,
      borderRadius: `${px2rem(16)}!important`,
      padding: `${px2rem(12)}!important`,
    },
  },
};

export type TooltipProps = {
  title?: React.ReactElement | string | null;
  icon?: IconName;
};

export const Tooltip: React.FC<TooltipProps> = ({
  title,
  icon = 'InfoCircle',
}) => {
  const Title = React.useCallback(() => {
    return typeof title === 'string' ? (
      <TooltipText variant="body1">{title}</TooltipText>
    ) : (
      title ?? null
    );
  }, [title]);

  return (
    <MuiTooltip title={<Title />} slotProps={slotProps}>
      <MuiIcon>
        <Icon icon={icon} />
      </MuiIcon>
    </MuiTooltip>
  );
};
