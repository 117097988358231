import {valueReducerFactory} from 'services/utils/redux/valueReducerFactory';
import {Attempt} from './types';

export type ReceivedItemsState = Attempt[];
const [
  RECEIVED_ITEMS_KEY,
  receivedItemsReducer,
  setReceivedItems,
  clearReceivedItems,
  useReceivedItems,
] = valueReducerFactory(`receiveItems`, [] as ReceivedItemsState);

export type ReceiveItemsAction =
  | ReturnType<typeof setReceivedItems>
  | ReturnType<typeof clearReceivedItems>;

export {
  RECEIVED_ITEMS_KEY,
  receivedItemsReducer,
  setReceivedItems,
  clearReceivedItems,
  useReceivedItems,
};
