import {PreviewConfiguration} from 'schema/types';
import {Part} from 'schemaDefinition/types';

export const isPartExcluded = (
  part: Part | undefined,
  config: PreviewConfiguration,
): boolean => {
  return !!(
    part &&
    part.name &&
    ((config.excludeParts &&
      config.excludeParts.includes(part.name.toString())) ||
      (config.includeParts &&
        !config.includeParts.includes(part.name.toString())))
  );
};
