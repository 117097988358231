import {valueAsNumber} from 'services/utils';
import {LengthCondition} from '../types';
import {EvaluationArgs} from './types';
import {evaluateVal} from './evaluateVal';
import {evaluateValWithDefault} from './evaluateValWithDefault';

export const evaluateLengthCondition = (
  condition: LengthCondition,
  args: EvaluationArgs,
): boolean => {
  const minLength = condition.minLength
    ? valueAsNumber(evaluateVal(condition.minLength, args))
    : undefined;
  const maxLength = condition.maxLength
    ? valueAsNumber(evaluateVal(condition.maxLength, args))
    : undefined;
  const arg1 = evaluateValWithDefault(condition.arg1, args, args.value);

  return (
    (typeof arg1 === 'string' || Array.isArray(arg1)) &&
    (minLength === undefined || arg1.length >= minLength) &&
    (maxLength === undefined || arg1.length <= maxLength)
  );
};
