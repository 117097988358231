import {Data} from 'schemaDefinition/types';
import {DataFormProps as DataFormPropsV1} from 'schema/form/typesV1';
import {useDataFormV1toV2Props} from '../hooks';
import {DataForm} from './DataForm';

export function DataFormV1<TData extends Data>(
  propsV1: DataFormPropsV1<TData> & {
    /**
     * Readonly data that are incrementally loaded.
     * This is only supported in V1 for now.
     * Used in agent editor/viewer to show related works
     * lazy/incrementally loaded.
     */
    lazyReadonlyData?: Partial<TData>;
  },
) {
  const propsV2 = useDataFormV1toV2Props(propsV1);
  return <DataForm {...propsV2} />;
}
