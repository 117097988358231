import {useMemo} from 'react';
import {GridColDef} from '@mui/x-data-grid-pro';
import {assert} from 'assert-ts';
import {LocalizationContextType} from 'localization/context/types';
import {Schema} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {Icon} from 'components';
import {getFieldRootKeys, getLabel} from 'schema/form/functions';
import {useSchemaCodelists} from 'schema/hooks';
import {
  BooleanPartOption,
  ColumnConfig,
  PartValueDelimiter,
  PartValueGetters,
  PartValueRenderers,
  TablePart,
} from '../types';
import {useSchemaThesauruses} from '../../hooks/useSchemaThesauruses';
import {
  mapRenderCell,
  mapType,
  mapValueGetter,
  mapValueOptions,
} from '../mapper';
import {mapFilterOperators} from '../mapper/mapFilterOperators';

export const useDataTableColumns = (
  schema: Schema,
  columnConfig: ColumnConfig[],
  partValueRenderers: PartValueRenderers | undefined,
  partValueGetters: PartValueGetters | undefined,
  partValueDelimiter: PartValueDelimiter | undefined,
  disableFiltering?: boolean,
  disableFilteringByName?: BooleanPartOption,
  highlights?: string[],
  indicateRowId?: string,
): GridColDef[] => {
  const l = useLocalization();
  const codelistMap = useSchemaCodelists(schema);
  const thesaurusesMap = useSchemaThesauruses(schema);
  const schemaKey = schema.key;

  return useMemo(() => {
    const match =
      highlights && highlights.length > 0
        ? new RegExp(highlights.join('|'), 'gi')
        : undefined;

    const dataColumns = columnConfig.map<GridColDef>(c => {
      const part = assert(
        schema.parts.find(p => (p as TablePart).name === c.name) as TablePart,
        `useDataTableColumns: Missing ${c.name}`,
        schema.parts,
      );

      const RenderValue = partValueRenderers?.[part.name];
      const customValueGetter = partValueGetters?.[part.name];
      const delimiter = partValueDelimiter?.[part.name];

      return {
        field: part.name as string,
        headerName: getHeaderName(
          l,
          schemaKey,
          part.name as string,
          part.labelKey,
        ),
        width: c.width || 100,
        type: mapType(part.type),
        valueOptions: mapValueOptions(part, codelistMap),
        filterOperators: mapFilterOperators(part),
        filterable: disableFiltering
          ? false
          : !(disableFilteringByName && disableFilteringByName[part.name]),
        valueGetter: params => {
          if (customValueGetter) {
            return customValueGetter(part, params);
          } else {
            return mapValueGetter(part, params);
          }
        },
        renderCell: params => {
          return RenderValue ? (
            <RenderValue
              part={part}
              value={params.value}
              match={match}
              delimiter={delimiter}
            />
          ) : (
            mapRenderCell(
              part,
              params,
              l,
              codelistMap,
              thesaurusesMap,
              match,
              delimiter,
            )
          );
        },
      };
    });

    const indicateRowIdCol: GridColDef | undefined = indicateRowId
      ? {
          field: 'rowId',
          headerName: '',
          maxWidth: 24,
          hideable: false,
          sortable: false,
          filterable: false,
          resizable: false,
          disableColumnMenu: true,
          renderCell: params => {
            return params.id === indicateRowId ? (
              <Icon icon="ArrowRight" fontSize="small" />
            ) : null;
          },
        }
      : undefined;

    return indicateRowIdCol ? [indicateRowIdCol, ...dataColumns] : dataColumns;
  }, [
    highlights,
    columnConfig,
    indicateRowId,
    schema.parts,
    partValueRenderers,
    partValueGetters,
    partValueDelimiter,
    l,
    schemaKey,
    codelistMap,
    disableFiltering,
    disableFilteringByName,
    thesaurusesMap,
  ]);
};

const getHeaderName = (
  localization: LocalizationContextType,
  schemaKey: string | undefined,
  partName: string,
  partLabelKey?: string,
): string => {
  const rootKeys = getFieldRootKeys(schemaKey, false, partLabelKey, partName);
  return (
    getLabel(rootKeys, !!partLabelKey, localization) ?? partLabelKey ?? partName
  );
};
