import React, {useMemo} from 'react';
import {
  gridFilteredSortedTopLevelRowEntriesSelector,
  gridPaginationModelSelector,
} from '@mui/x-data-grid-pro';
import {GridApiPro} from '@mui/x-data-grid-pro/models/gridApiPro';
import {PaginatorProps} from '../../paginator/types';
import {Paginator} from '../../paginator';

type UsePaginatorProps = {
  paginatorProps?: PaginatorProps;
  apiRef?: React.MutableRefObject<GridApiPro>;
};

export const usePaginator = (props: UsePaginatorProps) => {
  return useMemo(() => {
    const {paginatorProps, apiRef} = props;

    if (paginatorProps) {
      return <Paginator {...paginatorProps} />;
    }

    /* Hack for overview table, because filterModel handled by DataGrid */
    if (apiRef) {
      const gridRows = gridFilteredSortedTopLevelRowEntriesSelector(apiRef);
      const paginatorModel = gridPaginationModelSelector(apiRef);

      const pages = Math.ceil(gridRows.length / paginatorModel.pageSize);
      const onNext = () => apiRef.current.setPage(paginatorModel.page + 1);
      const onPrevious = () => apiRef.current.setPage(paginatorModel.page - 1);

      return (
        <Paginator
          page={paginatorModel.page + 1}
          pages={pages}
          onSelectNext={onNext}
          onSelectPrevious={onPrevious}
        />
      );
    }

    return null;
  }, [props]);
};
