/**
 * In binary megabytes because that is what Windows shows
 */
export const formatSizeInMB = (sizeInBytes: number): string => {
  if (sizeInBytes === 0) {
    return '0 MB';
  }
  if (sizeInBytes < 1024 * 1024) {
    return `${0.1} MB`;
  }

  return `${+(sizeInBytes / Math.pow(1024, 2)).toFixed(1)} MB`;
};
