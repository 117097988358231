import {useCallback, useMemo} from 'react';
import {GetTokens, OverviewTask, TaskTypeAll} from 'api/types';
import {LocalizationContextType} from 'localization/context/types';
import {Configuration} from 'configuration';
import {logError} from 'logging';
import {useLocalization} from 'localization';
import {getTasksV2, useGetResource} from 'api';
import {getLocks} from 'api/lock';
import {useGetTokens} from 'services/auth';
import {useUsers} from 'services/users';
import {OverviewTaskType} from '../types';
import {makeSearchableTaskString} from '../functions';
import {mergeDuplicateManifestations} from '../mapper/mapOverviewTask';
import {useSearchedTasks} from './useSearchedTasks';
import {useTaskSearchText} from './useTaskSearchText';
import {useTaskTypes} from './useTaskTypes';

const getTasksMappedToTaskTable = (
  getTokens: GetTokens,
  localization: LocalizationContextType,
): Promise<OverviewTask[]> => {
  return getTasksV2(getTokens)
    .then(tasks => {
      const overviewTasks: OverviewTask[] = tasks.map(
        ({type, metadata, ...rest}) => {
          const {
            publisherSalesDate,
            contentReceivedDate,
            inSaleDate,
            productOwner,
            ...restMetadata
          } = metadata;

          return {
            taskTypes: [type],
            // On part type="date" DataValue is string, not date.
            publisherSalesDate: publisherSalesDate ?? undefined, //: publisherSalesDate?.toUTCString(),
            contentReceivedDate: contentReceivedDate ?? undefined, //: contentReceivedDate?.toUTCString(),
            inSaleDate: inSaleDate ?? '', //: inSaleDate?.toUTCString(),
            // Duplicated to be filterable but not visible
            productOwner: productOwner,
            productOwnerName: productOwner ? productOwner.name : undefined,
            productOwnerCode: productOwner ? productOwner.code : undefined,
            ...rest,
            ...restMetadata,
            searchableTaskString: '',
          };
        },
      );
      overviewTasks.forEach(task => {
        task.searchableTaskString = makeSearchableTaskString(
          task,
          localization,
        );
      });
      return overviewTasks;
    })
    .then(mergeDuplicateManifestations)
    .then(tasks => {
      // Hide tasks with only ML suggestions
      return tasks.filter(t => {
        return !(t.taskTypes.length === 1 && t.taskTypes[0] === 'ml');
      });
    })
    .catch(e => {
      // Log it and pass it on for the error-snack to collect
      logError(e);

      throw new Error(e);
    });
};

const LOCKS_RELOAD_FALLBACK_MS = 1000 * 5;
const LOCKS_RELOAD_MS = Configuration.interval.lock ?? LOCKS_RELOAD_FALLBACK_MS;
const LOCKS_DELAY_MS = 200;

export const useOverviewTasks = (): {
  allTasks: OverviewTask[];
  filteredTasks: OverviewTask[];
  taskTypes: OverviewTaskType[];
  selectedTaskType: TaskTypeAll;
  setSelectedTaskType: (taskType: TaskTypeAll) => void;
  searchText: string;
  setSearchText: (searchText: string) => void;
  loading: boolean;
  errorMsg?: string;
} => {
  const localization = useLocalization();
  const {taskTypes, selectedTaskType, setSelectedTaskType} = useTaskTypes();
  const {searchText, setSearchText} = useTaskSearchText();
  const getTokens = useGetTokens();

  const handleGetTasks = useCallback(
    () => getTasksMappedToTaskTable(getTokens, localization),
    [getTokens, localization],
  );

  const {
    status: loadStatus,
    data: allTasks,
    error,
  } = useGetResource(handleGetTasks);

  const handleGetLocks = useCallback(() => getLocks(getTokens), [getTokens]);
  const {data: allLocks} = useGetResource(
    handleGetLocks,
    LOCKS_RELOAD_MS,
    LOCKS_DELAY_MS, // Delay to allow for locks to be released when navigating from work
  );

  const {
    resource: {data: users},
  } = useUsers();

  const tasksWithLocks = useMemo(
    (): OverviewTask[] | undefined =>
      allLocks &&
      allLocks.length > 0 &&
      allTasks &&
      allTasks.length > 0 &&
      users
        ? allTasks.map(task => {
            const lock = allLocks?.find(
              l => l.contextId === task.workId && l.contextType === 'work',
            );
            return lock
              ? {
                  ...task,
                  lockedBy: lock.lockedBy,
                }
              : task;
          })
        : allTasks,
    [allLocks, allTasks, users],
  );

  const filteredTasks = useSearchedTasks(
    tasksWithLocks,
    selectedTaskType,
    searchText,
  );

  const handleSetSelectedTaskType = useCallback(
    (taskType: TaskTypeAll) => {
      setSelectedTaskType(taskType);
    },
    [setSelectedTaskType],
  );

  return {
    allTasks: tasksWithLocks || [],
    filteredTasks,
    taskTypes,
    selectedTaskType,
    setSelectedTaskType: handleSetSelectedTaskType,
    searchText,
    setSearchText,
    loading: loadStatus === 'NotLoaded' || loadStatus === 'Loading',
    errorMsg: error ? 'Failed' : undefined,
  };
};
