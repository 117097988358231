import {DefaultPartCompare, Part} from 'schemaDefinition/types';
import {PartDiffProps} from '../components/types';

/**
 * Check if part needs item value container
 * @param part Part to check
 * @param diff Part diff properties
 * @returns True if part needs item value container
 */
export const needsItemValueContainer = (
  part: Part,
  diff: PartDiffProps,
): boolean => {
  return !diff ||
    // Should already have full value container on outer level
    diff.outerCompare === 'fullValue'
    ? false
    : (part.compare ?? diff.outerCompare ?? DefaultPartCompare) === 'items';
};
