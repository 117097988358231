import {useEffect, useMemo, useRef, useState} from 'react';
import {HttpError} from 'api/http/types';
import {DataLoadStatus} from '../types';

/**
 * Handles loading of a list of resources (entities) by a list of ids for a given a load function,
 * batched into smaller chunks, according to the given batch size,
 * Returns accummulated list of resources with load stauts:
 * - 'NotLoaded'/'Loading': not loaded/partially loaded (some batches loaded)
 * - 'Loaded': successfully loaded all batches
 * - 'Failed': some batches failed to load, but may be partially loaded
 *
 * get - function to load list of resources from api by list of ids
 * returns (partial)list of resources with load status
 */
export const useGetResourceListBatchedByIds = <TData>(
  ids: string[] | undefined,
  batchSize: number,
  get: (ids: string[]) => Promise<TData[]>,
): DataLoadStatus<TData[]> => {
  const [batches, setBatches] = useState<Array<string[]>>(() =>
    splitArrayIntoBatches(ids ?? [], batchSize),
  );
  const [batchResults, setBatchResults] = useState<
    Array<DataLoadStatus<TData[]>>
  >(batches.map(() => ({status: 'NotLoaded'})));

  const nextBatchIdxRef = useRef<number | undefined>(
    ids === undefined ? undefined : 0,
  );

  // Reset batch results when ids change
  useEffect(() => {
    const _batches = splitArrayIntoBatches(ids ?? [], batchSize);
    setBatches(_batches);
    setBatchResults(_batches.map(() => ({status: 'NotLoaded'})));
    nextBatchIdxRef.current = ids === undefined ? undefined : 0;
    // Only trigger when ids change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids]);

  // Load entites in batches
  useEffect(() => {
    const idx = nextBatchIdxRef.current;
    if (idx !== undefined && batchResults[idx]?.status === 'NotLoaded') {
      setBatchResults(old => {
        const newResults = [...old];
        newResults[idx] = {status: 'Loading', data: old[idx].data};
        return newResults;
      });

      get(batches[idx])
        .then(data => {
          setBatchResults(old => {
            const newResults = [...old];
            newResults[idx] = {status: 'Loaded', data};
            return newResults;
          });
          nextBatchIdxRef.current =
            idx < batches.length - 1 ? idx + 1 : undefined;
        })
        .catch((error: HttpError) => {
          setBatchResults(old => {
            const newResults = [...old];
            newResults[idx] = {status: 'Failed', error};
            return newResults;
          });
          nextBatchIdxRef.current =
            idx < batches.length - 1 ? idx + 1 : undefined;
        });
    }
  }, [ids, get, batchResults, batches]);

  return useMemo(() => {
    const status = batchResults.every(r => r.status === 'Loaded')
      ? 'Loaded'
      : batchResults.some(
            r => r.status === 'NotLoaded' || r.status === 'Loading',
          )
        ? 'Loading'
        : 'Failed';
    const data = batchResults
      .filter(r => r.status === 'Loaded')
      .flatMap(r => r.data);
    const error = batchResults.find(r => r.status === 'Failed')?.error;
    return {status, data, error} as DataLoadStatus<TData[]>;
  }, [batchResults]);
};

function splitArrayIntoBatches<T>(array: T[], batchSize: number): Array<T[]> {
  const result = [];
  for (let i = 0; i < array.length; i += batchSize) {
    result.push(array.slice(i, i + batchSize));
  }
  return result;
}
