import {GridValueGetterParams} from '@mui/x-data-grid-pro';
import {assert} from 'assert-ts';
import {Part} from 'schemaDefinition/types';

export const mapValueGetter = (part: Part, params: GridValueGetterParams) => {
  assert(!Array.isArray(part), 'mapValueGetter: part is array');

  switch (part.type) {
    case 'date':
      return new Date(params.value).toString() !== 'Invalid Date'
        ? new Date(params.value)
        : undefined;
    case 'year':
      return Number(params.value);
    default:
      return params.value;
  }
};
