import {Concept} from 'types';
import {VerifiedLinkedValueLink} from 'schemaDefinition/types';
import {splitRoleEntityCode} from 'api/mappers/splitEntityRoleEntityCode';
import {
  LinkedRoleCodeList,
  RoleEntityCode,
} from 'schemaDefinition/types/linkTypes';
import {isAgentSubType} from 'services/utils';

export const getRoleEntityFromNewLink = (
  partRoleEntity: RoleEntityCode | undefined,
  prev: RoleEntityCode | undefined,
  newLink: VerifiedLinkedValueLink,
  codelist: LinkedRoleCodeList,
): RoleEntityCode | undefined => {
  // Fixed role
  if (partRoleEntity) {
    return partRoleEntity;
  }

  const {role: prevRole, target: prevTarget} = prev
    ? splitRoleEntityCode(prev)
    : {role: undefined, target: undefined};
  if (prevTarget === newLink.type) {
    return prev;
  }

  const newLinkType = isAgentSubType(newLink.type)
    ? Concept.agent
    : newLink.type;

  const match =
    (codelist.codes.find(code => {
      const {role, target} = splitRoleEntityCode(code.code as RoleEntityCode);
      return (
        (prevRole === undefined || prevRole === role) && target === newLinkType
      );
    })?.code as RoleEntityCode) ?? prev;

  return match;
};
