import {useMemo, useRef} from 'react';
import {LockValue} from './types';
import {useCurrentLock} from './useCurrentLock';

export const useCurrentLockValue = (workId: string): LockValue | undefined => {
  const currentLock = useCurrentLock(workId, false);
  const currentLockValueRef = useRef<LockValue | undefined>();

  return useMemo(() => {
    // Only update ref when loaded
    if (currentLock.status !== 'Loaded') {
      return currentLockValueRef.current;
    }

    const lockValue: LockValue = {
      isLocked: !!currentLock.data,
      lock: currentLock.data,
    };

    currentLockValueRef.current = lockValue;

    return currentLockValueRef.current;
  }, [currentLock]);
};
