import {assert} from 'assert-ts';
import {
  PartExpand,
  PartSchema,
  Schema,
  SeparatedPart,
} from 'schemaDefinition/types';

/**
 * Flattens schema, i.e. horizontal layout (nested part arrays)
 * changed to vertical layout. Also applied to sub-schema and expand parts.
 */
export const getSchemaFlattended = (
  schema: Schema,
  shouldFlattenSubSchema: (part: PartSchema) => boolean = () => true,
): Schema => ({
  ...schema,
  parts: getPartsFlattend(schema.parts, shouldFlattenSubSchema),
});

const getPartsFlattend = (
  parts: SeparatedPart[],
  shouldFlattenSubSchema: (part: PartSchema) => boolean,
): SeparatedPart[] => {
  return parts
    .reduce<SeparatedPart[]>((acc, p) => {
      if (Array.isArray(p)) {
        acc.push(...p);
      } else {
        acc.push(p);
      }
      return acc;
    }, [])
    .map(p => {
      assert(!Array.isArray(p));
      if (p.type === 'schema' && shouldFlattenSubSchema(p)) {
        return {
          ...p,
          parts: getPartsFlattend(p.parts, shouldFlattenSubSchema),
        };
      }
      if (p.type === 'expand') {
        return getExpandFlattended(p, shouldFlattenSubSchema);
      }

      return p;
    });
};

const getExpandFlattended = (
  expand: PartExpand,
  shouldFlattenSubSchema: (part: PartSchema) => boolean,
): PartExpand => {
  return {
    ...expand,
    when: expand.when.map(w => ({
      ...w,
      parts: getPartsFlattend(
        w.parts,
        shouldFlattenSubSchema,
      ) as typeof w.parts,
    })),
    default: expand.default
      ? (getPartsFlattend(
          expand.default,
          shouldFlattenSubSchema,
        ) as typeof expand.default)
      : undefined,
  };
};
