import {assert} from 'assert-ts';
import {Part} from 'schemaDefinition/types';
import {evaluateFieldRequired} from 'schemaDefinition';
import {ValidationArgs, ValidationResult} from './types';
import {isMultiple} from '../isMultiple';
import {fail} from './fail';
import {validResult} from './validResult';
import {warning} from './warning';

/**
 * Generic validation of multiple, i.e.:
 * - required: at least one item
 **/
export const validateMultipleValue = (
  part: Part,
  args: ValidationArgs,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  assert(isMultiple(part.cardinality));

  if (args.value !== undefined && args.value !== null) {
    if (!Array.isArray(args.value)) {
      return fail(
        {
          ...args,
          part,
          validation: 'invalid type',
          messageKey: 'invalidArrayValue',
        },
        aggregateResult,
      );
    }
  }

  const required = evaluateFieldRequired(
    part.required,
    args.valuePath,
    args.localScope,
    args.globalScope,
    args.relatedScope,
    args.value,
  );

  if (required) {
    if (!args.value || args.value.length === 0) {
      return (required === true ? fail : warning)(
        {
          ...args,
          part,
          validation: 'required',
          messageKey: 'required',
        },
        aggregateResult,
      );
    }
  }

  return aggregateResult;
};
