import {useMemo} from 'react';
import {FCWithChildren} from 'types';
import {Schema} from 'schemaDefinition/types';
import {Card} from 'components/card';
import {MinimalIndentContainer} from '../components';
import {DefaultContainer} from '../components/DefaultContainer';
import {useDataFormConfigurationContext} from '../contexts/DataFormConfigurationContext';

const NoContainer: FCWithChildren = ({children}) => <>{children}</>;

export const useSchemaContainer = (schema: Schema): FCWithChildren => {
  const configuration = useDataFormConfigurationContext();
  return useMemo(() => {
    const container =
      configuration.schemaContainers?.[schema.name] ??
      configuration.schemaContainers?.defaultContainer;

    return container === undefined || container === 'DefaultCard'
      ? DefaultContainer
      : container === 'NoContainer'
        ? NoContainer
        : container === 'MinimalIndentContainer'
          ? MinimalIndentContainer
          : ((({children}) => (
              <Card {...container}>
                <Card.Content>{children}</Card.Content>
              </Card>
            )) as FCWithChildren);
  }, [configuration.schemaContainers, schema.name]);
};
