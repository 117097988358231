import React, {Fragment, useCallback, useMemo} from 'react';
import Stack from '@mui/material/Stack';
import {assert} from 'assert-ts';
import {
  LinkStatus,
  LinkedLiterary,
  LinkedValueLink,
  PartLinkedAgent,
  VerifiedLinkedValueLink,
} from 'schemaDefinition/types';
import {useCodelist} from 'services/codeLists';
import {FlexBox, LinkStatusIcon, Spacer} from 'components';
import {FieldLabel} from 'components/fields/FieldLabel';
import {useLabelAndPlaceholder} from 'schema/form/hooks';
import {BasePartProps} from './types';
import {usePreviewConfigurationContext} from '../contexts';
import {formatLinkedValueLink, getLinkedValuesByRoles} from '../functions';
import {useTextValue} from '../hooks';
import {GroupLabelProps} from './constants';
import {ValueLayout} from './fields/ValueLayout';

type PreviewSimpleValueProps = BasePartProps<PartLinkedAgent>;

const resolveStatus = (
  link: Pick<LinkedValueLink, 'linkStatus' | 'nationalId'>,
): LinkStatus | 'externalVerified' => {
  return link.linkStatus === 'verified' && link.nationalId
    ? 'externalVerified'
    : link.linkStatus;
};

export const PreviewLinkedValueByRoles: React.FC<PreviewSimpleValueProps> = ({
  part,
  value,
}) => {
  const {label} = useLabelAndPlaceholder(part);
  const config = usePreviewConfigurationContext();
  const {noLabels} = config.layout;
  const codelist = useCodelist(part.roleCodelistId);
  const formattedLinkedValuesByRoles = useMemo(() => {
    const byRoles = getLinkedValuesByRoles(value);
    const byRolesFormatted = byRoles.map(byRole => {
      const role = codelist.codes.find(c => c.code === byRole.role)?.value;
      const links = byRole.links.map(link => ({
        value:
          formatLinkedValueLink(
            link?.link,
            (link as LinkedLiterary)?.numberInSeries,
          ) ?? '',
        link: link.link,
      }));
      if (
        assert.soft(role, 'PreviewLinkedValueByRoles: code missing', {
          code: byRole.role,
          codelist: part.roleCodelistId,
        })
      ) {
        return {role, links};
      } else {
        return {role: `${byRole.role} (missing)`, links};
      }
    });

    return byRolesFormatted;
  }, [codelist.codes, part.roleCodelistId, value]);

  const TextValue = useTextValue();
  // const Separator = useStaticTextValue(';', {pr: 0.5});

  const renderValue = useCallback(
    (links: {value: string; link: LinkedValueLink}[], label?: string) => {
      const allVerifiedSiblingIds = links
        .filter(l => l.link.linkStatus === 'verified')
        .map(l => l.link.entityId)
        .filter(Boolean) as string[];

      return config.onNavigate ? (
        <>
          {links.map((link, idx) => {
            const verifiedLink =
              link.link.linkStatus === 'verified'
                ? (link.link as VerifiedLinkedValueLink)
                : undefined;
            const notLast = idx < links.length - 1;
            return (
              <Fragment key={idx}>
                <FlexBox horizontal flexWrap="nowrap">
                  <FlexBox sx={{transform: 'translateY(-2px)'}}>
                    <LinkStatusIcon
                      status={resolveStatus(link.link)}
                      color="disabled"
                    />
                  </FlexBox>
                  {verifiedLink ? (
                    <TextValue
                      onClick={() =>
                        config.onNavigate?.(
                          verifiedLink,
                          allVerifiedSiblingIds,
                          label,
                        )
                      }>
                      {link.value}
                    </TextValue>
                  ) : (
                    <TextValue key={idx}>{link.value}</TextValue>
                  )}
                  {notLast ? <Spacer size={0.75} /> : null}
                  {/* {idx < links.length - 1 ? <Separator /> : null} */}
                </FlexBox>
              </Fragment>
            );
          })}
        </>
      ) : (
        <FlexBox horizontal>
          {links.map((l, idx) => {
            const notLast = idx < links.length - 1;
            return (
              <FlexBox key={idx} horizontal flexWrap="nowrap">
                <FlexBox sx={{transform: 'translateY(-2px)'}}>
                  <LinkStatusIcon
                    status={resolveStatus(l.link)}
                    color="disabled"
                  />
                </FlexBox>
                <TextValue>{l.value}</TextValue>
                {notLast ? <Spacer size={0.5} /> : null}
              </FlexBox>
            );
          })}
        </FlexBox>
      );
    },
    [TextValue, config],
  );

  return (
    <Stack>
      {noLabels ? null : <FieldLabel label={label ?? ''} />}
      {formattedLinkedValuesByRoles.map(({role, links}, idx) => (
        <ValueLayout
          key={idx}
          renderValue={() => renderValue(links, label)}
          forcedLabel={role}
          labelProps={GroupLabelProps}
          part={part}
          layoutDirection={'horizontal'}
        />
      ))}
    </Stack>
  );
};
