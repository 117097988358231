import {
  AndCondition,
  AndConditionV0,
  BooleanCondition,
  ComparisonCondition,
  ComparisonConditionV0,
  Condition,
  EqComparisonCondition,
  GeComparisonCondition,
  GtComparisonCondition,
  IncludesCondition,
  LeComparisonCondition,
  LengthCondition,
  LtComparisonCondition,
  NotCondition,
  NotConditionV0,
  OrCondition,
  OrConditionV0,
  RangeCondition,
  RegexCondition,
} from '../types';

const baseConditionKeys = ['id', 'level', 'messageKey'];

export const isRangeCondition = (
  condition: Condition,
): condition is RangeCondition => {
  const range = condition as RangeCondition;
  return range.min !== undefined || range.max !== undefined;
};

export const isLengthCondition = (
  condition: Condition,
): condition is LengthCondition => {
  const length = condition as LengthCondition;
  return length.minLength !== undefined || length.maxLength !== undefined;
};

export const isRegexCondition = (
  condition: Condition,
): condition is RegexCondition => {
  const regex = condition as RegexCondition;
  return regex.regex !== undefined;
};

export const isComparisonConditionV0 = (
  condition: Condition,
): condition is ComparisonConditionV0 => {
  const comparison = condition as ComparisonConditionV0;
  return (
    ['lt', 'le', 'eq', 'ge', 'gt'].includes(comparison.op) && !!comparison.arg2
  );
};

export const isEqComparisonCondition = (
  condition: Condition,
): condition is EqComparisonCondition => {
  const eq = condition as EqComparisonCondition;
  return eq.$eq !== undefined;
};

export const isLtComparisonCondition = (
  condition: Condition,
): condition is LtComparisonCondition => {
  const lt = condition as LtComparisonCondition;
  return lt.$lt !== undefined;
};

export const isLeComparisonCondition = (
  condition: Condition,
): condition is LeComparisonCondition => {
  const le = condition as LeComparisonCondition;
  return le.$le !== undefined;
};

export const isGeComparisonCondition = (
  condition: Condition,
): condition is GeComparisonCondition => {
  const ge = condition as GeComparisonCondition;
  return ge.$ge !== undefined;
};

export const isGtComparisonCondition = (
  condition: Condition,
): condition is GtComparisonCondition => {
  const gt = condition as GtComparisonCondition;
  return gt.$gt !== undefined;
};

export const isComparisonCondition = (
  condition: Condition,
): condition is ComparisonCondition => {
  return (
    isEqComparisonCondition(condition) ||
    isLtComparisonCondition(condition) ||
    isLeComparisonCondition(condition) ||
    isGeComparisonCondition(condition) ||
    isGtComparisonCondition(condition)
  );
};

export const isNotConditionV0 = (
  condition: Condition,
): condition is NotConditionV0 => {
  const not = condition as NotConditionV0;
  return not.op === 'not' && !!not.arg;
};

export const isNotCondition = (
  condition: Condition,
): condition is NotCondition => {
  const not = condition as NotCondition;
  return not.$not !== undefined;
};

export const isOrConditionV0 = (
  condition: Condition,
): condition is OrConditionV0 => {
  const or = condition as OrConditionV0;
  return or.op === 'or' && Array.isArray(or.arg);
};

export const isOrCondition = (
  condition: Condition,
): condition is OrCondition => {
  const or = condition as OrCondition;
  return or.$or !== undefined;
};

export const isAndConditionV0 = (
  condition: Condition,
): condition is AndConditionV0 => {
  const and = condition as AndConditionV0;
  return and.op === 'and' && Array.isArray(and.arg);
};

export const isAndCondition = (
  condition: Condition,
): condition is AndCondition => {
  const and = condition as AndCondition;
  return and.$and !== undefined;
};

const boolConditionKeys = ['arg'];

export const isBooleanCondition = (
  condition: Condition,
): condition is BooleanCondition => {
  const asBoolean = condition as BooleanCondition;
  return (
    typeof asBoolean === 'object' &&
    typeof asBoolean.arg === 'object' &&
    Object.keys(asBoolean).every(
      key => boolConditionKeys.includes(key) || baseConditionKeys.includes(key),
    )
  );
};

export const isIncludesCondition = (
  condition: Condition,
): condition is IncludesCondition => {
  const includes = condition as IncludesCondition;
  return includes.$includes !== undefined;
};
