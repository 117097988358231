import {Condition, DateRange, RangeCondition} from 'schemaDefinition/types';
import {EvaluationArgs} from './types';
import {compareDateValues} from './compareDateValues';
import {evaluateVal} from './evaluateVal';
import {getDateMaxValue, getDateMinValue} from './getDateValue';
import {isRangeCondition} from './isConditionType';

export const getDateRange = (
  condition: Condition | Condition[] | undefined,
  args: EvaluationArgs,
): DateRange => {
  const conditions = Array.isArray(condition)
    ? condition
    : condition
      ? [condition]
      : [];
  const rangeConditions = conditions.filter(c =>
    isRangeCondition(c),
  ) as RangeCondition[];

  return rangeConditions.reduce<DateRange>(
    (acc, cond) => {
      const condMin = cond.min ? evaluateVal(cond.min, args) : undefined;
      const condMax = cond.max ? evaluateVal(cond.max, args) : undefined;

      const condMinVal = getDateMinValue(condMin);
      const condMaxVal = getDateMaxValue(condMax);

      return {
        minValue:
          !acc.minValue ||
          (condMinVal && compareDateValues(condMinVal, acc.minValue) > 0)
            ? condMinVal
            : acc.minValue,
        maxValue:
          !acc.maxValue ||
          (condMaxVal && compareDateValues(condMaxVal, acc.maxValue) < 0)
            ? condMaxVal
            : acc.maxValue,
      };
    },
    {
      minValue: undefined,
      maxValue: undefined,
    },
  );
};
