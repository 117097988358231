import dayjs from 'dayjs';
import {RelativeDate} from 'schemaDefinition/types';

export const resolveRelativeDateValue = (
  value: RelativeDate | undefined,
): string | null => {
  // No reference
  if (value === undefined) {
    return null;
  }

  const {$date} = value;

  const {offset, unit} = $date;
  const relativeValue = dayjs().add(offset, unit);
  return relativeValue.format('YYYY-MM-DD');
};
