import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {assert} from 'assert-ts';
import {Schema} from 'schemaDefinition/types';
import {SchemaMode} from 'schemas/types';
import {useLocalization} from 'localization';
import {CodeListId, HttpError, NationalAgent, NationalAgentType} from 'api';
import {LinkedRoleCodeListId} from 'schemaDefinition/types/linkTypes';
import {useCodelistsLazy} from 'services/codeLists';
import {areAgentsEqual} from 'services/data/agent';
import {clone} from 'services/utils';
import {getErrorMessage} from 'services/utils/getErrorMessage';
import {useSnacks} from 'components';
import {useSchemaCodelistIds} from 'schema';
import {validate} from 'schema/form/functions/validators/validate';
import {useAgentSchema} from 'schemas';
import {
  AgentQuery,
  AgentSelection,
  EditAgentWizardProps,
  EditorMode,
  EmptyResultAlternative,
  Matching,
} from '../types';
import {
  createEmptyNationalAgent,
  getAgentQuery,
  getSavedNationalAgentMessage,
  mergeFromNationalRegistryAgent,
} from '../functions';
import {useAgentSearchSelection} from './useAgentSearchSelection';
import {
  NationalAgentSearch,
  useNationalAgentSearch,
} from './useNationalAgentSearch';

/**
 * Registrer/rediger aktør med integrasjon mot BARE. Også mulig å opprette bare i Ebba hvis N-ID ikke er satt.
 *
 * N-ID alltid readonly
 * - varianter
 *   1) - registrer ny, søk
 *     a) - finnes ikke i BARE
 *     => - enten: opprett ny både i Ebba og BARE (N-ID tom (vil bli satt))
 *        - enten: mulig å opprette kun i Ebba (N-ID tom (vil ikke bli satt))
 *        - Save: "Registrer person"
 *        v - Checbox: "Registrer i BARE", checked, enabled, !checked => ?onlyEbba query parameter
 *        - i) -> Snack: Registrert i Ebba og BARE
 *        - ii) -> Snack (BARE feilet): Registrert i Ebba, ikke BARE
 *        - iii) -> Snack: Registrert kun i Ebba
 *
 *     b) - valgt treff kun i BARE
 *     => - ta med verdier fra bare, inkl. N-ID, opprett kun i Ebba:
 *        - Save: "Registrer person"
 *        v - Checbox: "Oppdater i BARE", checked, disabled
 *        -> Snack: Registrert i Ebba og koblet til BARE
 *     c) - valgt treff i BARE og Ebba
 *     => Rediger aktør
 *     d) - søk til BARE feiler
 *     => - opprett ny kun i Ebba (N-ID tom (legg til seinere))
 *        - Save: "Registrer person" ?onlyEbba query parameter
 *        v - Checbox: "Oppdater i BARE", unchecked, disabled
 *        -> Snack: Registrert i Ebba, ikke BARE
 *   2) rediger eksisterende
 *     2.1) uten N-ID (fra 1.a.ii, 1.a.iii 1.d),
 *          - vis steg 1, fyll inn navn og andre felt, gjøre søk
 *       a) - finnes ikke i BARE
 *       => - enten: opprett i BARE ved lagring
 *          - eller: lagre kun i Ebba
 *          - Save: "Lagre person"
 *          v - Checbox: "Registrer i BARE", checked, enabled, !checked => ?onlyEbba query parameter
 *          - i) -> Snack: Oppdatert i Ebba og registrert i BARE
 *          - ii) -> Snack (BARE feilet): Oppdatert i Ebba, ikke i BARE
 *          - iii) -> Snack: Oppdatert kun i Ebba
 *       b) - valgt treff i BARE
 *       => - merge verdier fra BARE (inkl. N-ID og ISNI), ikke skriv over verdier i Ebba fra BARE
 *          - Save: "Lagre person"
 *          v - Checbox: "Oppdater i BARE", checked, disabled
 *          -> Snack: Oppdatert i Ebba og koblet til BARE
 *       c) - søk til BARE feiler
 *       => - Save: "Lagre person" ?onlyEbba query parameter
 *          v - Checbox: "Registrer i BARE", unchecked, disabled
 *          -> Snack: Oppdatert i Ebba, ikke BARE
 *     (2.2) alt I: med N-ID, vi går for alt II)
 *     2.2) alt II: med N-ID, vis steg 2
 *       a) - rediger aktør
 *       => - Save: "Lagre person"
 *          v - Checbox: "Oppdater i BARE", checked, disabled
 *          -> Snack: Oppdatert i Ebba og BARE
 *       - Gå til steg 1,
 *          - fyll inn navn og andre felt, gjøre søk,
 *          - automatisk valg av evt. treff med samme N-ID, eller?
 *       b) - valgt treff i BARE (med samme N-ID)
 *       => - merge verdier fra BARE (inkl. N-ID og ISNI), ikke skriv over verdier i Ebba fra BARE
 *          - Save: "Lagre person"
 *          v - Checbox: "Oppdater i BARE", checked, disabled
 *          -> Snack: Oppdatert i Ebba og BARE
 *       c) - valgt treff i BARE (med ulik N-ID)
 *       => - merge verdier fra BARE (inkl. N-ID og ISNI), ikke skriv over verdier i Ebba fra BARE
 *          - Save: "Lagre person"
 *          v - Checbox: "Oppdater i BARE", checked, disabled
 *          -> Snack: Oppdatert i Ebba og re-koblet fra BARE
 *       d) - Behold eksisterende BARE-kobling (ingen av treffene valgt) -> a)
 *       e) - søk til BARE feiler -> a)
 *
 * Tilstandsmaskin
 *   States:
 *   - Aktør:
 * 	   - ny aktør
 * 	     - uten N-ID
 * 	     - med N-ID
 * 	   - eksisterende aktør
 * 	     - uten N-ID
 * 	     - med N-ID
 *   - Steg:
 *     - Steg 1: søk
 *     - Steg 2: rediger
 *   - Matching: ikke match (only ebba hvis checkbox ikke satt), match, failed (only ebba)
 *
 */

const STEP1_ID = 'STEP1';
const STEP2_ID = 'STEP2';

type EditAgentWizardState = {
  step1Id: string;
  step2Id: string;
  currentStep: string;
  agentType: NationalAgentType;
  agentSchema: Schema;
  codelistIds: (CodeListId | LinkedRoleCodeListId)[];
  mode: EditorMode;
  hasNationalId: boolean;
  upsertBARE: boolean;
  disabledUpsertBARE: boolean;
  setUpsertBARE: (value: boolean) => void;
  agentQuery: AgentQuery;
  search: NationalAgentSearch;
  isAutoSearching: boolean;
  selection: AgentSelection;
  emptyResultAlternative: EmptyResultAlternative;
  setEmptyResultAlternative: (value: EmptyResultAlternative) => void;
  // Initial value of agent when going to edit mode
  initialEditAgent: NationalAgent;
  hasChanges: boolean;
  showValidationErrors: boolean;
  setAgentQuery: (agentQuery: AgentQuery) => void;
  gotoStep: (stepId: string) => void;
  agentEdited: (agent: NationalAgent) => void;
  onSave: () => Promise<void>;
  onDelete: () => Promise<void>;
};

const getResolvedUpsertBARE = (
  matching: Matching,
  hasNationalId: boolean,
  upsertBARE: boolean | undefined,
): boolean => {
  if (hasNationalId) {
    return true;
  }
  if (matching === 'FAILED') {
    return false;
  }

  return !!upsertBARE;
};

export const useEditAgentWizardState = (
  {
    agentType,
    suggestion,
    originalAgent: givenOriginalAgent,
    onSave,
    onDelete,
  }: EditAgentWizardProps,
  schemaMode: SchemaMode,
): EditAgentWizardState => {
  const resolvedAgentType = assert(
    agentType ?? givenOriginalAgent?.agentType,
    'agentType is required',
  );
  const localization = useLocalization();
  const {t, tryT} = localization;
  const navigate = useNavigate();

  const [originalAgent, setOriginalAgent] = useState<NationalAgent | undefined>(
    givenOriginalAgent,
  );

  const [initialMode] = useState<EditorMode>(
    originalAgent ? 'edit' : 'register',
  );
  const [mode, setMode] = useState<EditorMode>(initialMode);
  const [hasNationalId] = useState<boolean>(
    originalAgent && originalAgent.nationalId ? true : false,
  );
  const [currentStep, setCurrentStep] = useState(
    hasNationalId ? STEP2_ID : STEP1_ID,
  );
  const [agentQuery, setAgentQuery] = useState<AgentQuery>({
    name: suggestion,
  });
  const [upsertBARE, setUpsertBARE] = useState<boolean>();

  const search = useNationalAgentSearch(resolvedAgentType);
  const [isAutoSearching, setIsAutoSearching] = useState(false);
  const selection = useAgentSearchSelection(mode);

  const [initialEditAgent, setInitialEditAgent] = useState(
    originalAgent ?? createEmptyNationalAgent(resolvedAgentType),
  );
  const editedAgentRef = useRef<NationalAgent | undefined>(
    hasNationalId ? originalAgent : undefined,
  );
  const [hasChanges, setHasChanges] = useState(false);
  const [showValidationErrors, setShowValidationErrors] = useState(false);

  const agentSchema = useAgentSchema(
    resolvedAgentType,
    schemaMode,
    initialEditAgent,
  );
  const codelistIds = useSchemaCodelistIds(agentSchema);
  const codelistMap = useCodelistsLazy(codelistIds);

  const {successSnack, errorSnack} = useSnacks();

  const getMatching = useCallback(() => {
    const selectedId = selection.resultSelection[0];
    const nationalRegistryAgent = search.getNationalRegistryAgent(selectedId);

    const matching: Matching = search.showSearchErrors
      ? 'FAILED'
      : nationalRegistryAgent
        ? 'MATCH'
        : 'NO_MATCH';

    return {matching, nationalRegistryAgent};
  }, [search, selection.resultSelection]);

  const handleAutoSearch = useCallback(
    (agent: NationalAgent) => {
      const query = getAgentQuery(agent);
      if (query.name || query.other) {
        setAgentQuery(query);
        setIsAutoSearching(true);
        search.doSearch(query).finally(() => setIsAutoSearching(false));
      }
    },
    [search],
  );

  const gotoSearchStep = useCallback(() => {
    setCurrentStep(STEP1_ID);
    if (mode !== initialMode && initialMode === 'register') {
      setMode(initialMode);
      setOriginalAgent(undefined);
      setInitialEditAgent(createEmptyNationalAgent(resolvedAgentType));
    }
    const agent = initialEditAgent ?? originalAgent;
    if (agent && !(agentQuery.name || agentQuery.other)) {
      handleAutoSearch(agent);
    }
  }, [
    agentQuery.name,
    agentQuery.other,
    handleAutoSearch,
    initialEditAgent,
    originalAgent,
    mode,
    initialMode,
    resolvedAgentType,
  ]);

  const gotoEditStep = useCallback(() => {
    const {matching, nationalRegistryAgent} = getMatching();
    // New agent (no original agent)
    if (!originalAgent) {
      switch (matching) {
        case 'MATCH': {
          const ebbaAgent = search.getEbbaAgent(
            nationalRegistryAgent?.nationalId,
          );
          // Change to edit mode if agent already exists in Ebba
          if (ebbaAgent) {
            setOriginalAgent(ebbaAgent);
            // Merge values from national registry agent
            const baseAgent = mergeFromNationalRegistryAgent(
              ebbaAgent,
              assert(
                nationalRegistryAgent,
                'marge: nationalRegistryAgent is required',
              ),
            );
            setInitialEditAgent(baseAgent);
            editedAgentRef.current = baseAgent;
            setMode('edit');
          } else {
            // Base new agent on selected national registry agent
            const baseAgent = {
              ...clone(
                assert(
                  nationalRegistryAgent,
                  'clone: nationalRegistryAgent is required',
                ),
              ),
              id: undefined,
            };
            setInitialEditAgent(baseAgent);
            editedAgentRef.current = baseAgent;
          }
          break;
        }
        case 'NO_MATCH':
        case 'FAILED': {
          // Base new agent on agent type only
          const baseAgent = createEmptyNationalAgent(resolvedAgentType);
          setInitialEditAgent(baseAgent);
          editedAgentRef.current = baseAgent;
          break;
        }
      }
    } else {
      // Edit existing agent
      switch (matching) {
        case 'MATCH': {
          // Merge values from national registry agent
          const baseAgent = mergeFromNationalRegistryAgent(
            originalAgent,
            assert(
              nationalRegistryAgent,
              'marge: nationalRegistryAgent is required',
            ),
          );
          setInitialEditAgent(baseAgent);
          editedAgentRef.current = baseAgent;

          const mergedHasChanges = !areAgentsEqual(originalAgent, baseAgent);
          setHasChanges(mergedHasChanges);
          break;
        }
        case 'NO_MATCH':
        case 'FAILED': {
          // Keep existing values
          setInitialEditAgent(clone(originalAgent));
          editedAgentRef.current = originalAgent;
          break;
        }
      }
    }
    setCurrentStep(STEP2_ID);
  }, [getMatching, originalAgent, resolvedAgentType, search]);

  const handleGotoStep = useCallback(
    (stepId: string) => {
      if (stepId === currentStep) {
        return;
      }

      stepId === STEP1_ID ? gotoSearchStep() : gotoEditStep();
    },
    [currentStep, gotoEditStep, gotoSearchStep],
  );

  useEffect(() => {
    // If editing existing agent and on step 1, fill search criteria and do search
    if (originalAgent && currentStep === STEP1_ID) {
      handleAutoSearch(originalAgent);
    }
    // Only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAgentEdited = useCallback(
    (agent: NationalAgent) => {
      editedAgentRef.current = agent;
      const newHasChanges =
        mode === 'register' ||
        upsertBARE === true ||
        !areAgentsEqual(originalAgent, editedAgentRef.current);

      setHasChanges(newHasChanges);
    },
    [mode, originalAgent, upsertBARE],
  );

  const handleSaveAgent = useCallback((): Promise<void> => {
    const validation = validate(
      assert(
        editedAgentRef.current,
        'useEditAgentWizardState:save: edited agent required',
      ),
      {},
      agentSchema,
      assert(codelistMap, 'useEditAgentWizard: codelistMap is required'),
    );

    if (validation.valid !== 'valid') {
      setShowValidationErrors(true);
      return Promise.resolve();
    }

    const {matching} = getMatching();
    const hasInitialNationalId = !!initialEditAgent.nationalId;
    const resolvedUpsertBARE = getResolvedUpsertBARE(
      matching,
      hasInitialNationalId,
      upsertBARE,
    );
    const saveToEbbaOnly = !resolvedUpsertBARE ? true : undefined;
    return onSave(
      assert(editedAgentRef.current),
      saveToEbbaOnly ? 'bbOnly' : undefined,
    )
      .then(savedAgent => {
        const msg = getSavedNationalAgentMessage(
          originalAgent,
          savedAgent,
          matching,
          resolvedUpsertBARE,
          t,
        );
        successSnack(msg);
        setInitialEditAgent(savedAgent as NationalAgent);
        setOriginalAgent(savedAgent as NationalAgent);
        editedAgentRef.current = savedAgent as NationalAgent;
        setHasChanges(false);
      })
      .catch(error => {
        const message = getErrorMessage(
          error,
          'page.agents.save.failed',
          localization,
        );
        errorSnack(message);
      });
  }, [
    agentSchema,
    codelistMap,
    errorSnack,
    getMatching,
    initialEditAgent.nationalId,
    localization,
    onSave,
    originalAgent,
    successSnack,
    t,
    upsertBARE,
  ]);

  const handleDeleteAgent = useCallback((): Promise<void> => {
    return onDelete(assert(editedAgentRef.current))
      .then(() => {
        successSnack(t('page.agents.delete.success'));
        navigate('/agent');
      })
      .catch((error: HttpError) => {
        const message =
          tryT(`error.api.${error?.message}`) ??
          t('page.metadata.newExpression.save.failed');

        errorSnack(message);
      });
  }, [errorSnack, navigate, onDelete, successSnack, t, tryT]);

  return useMemo(() => {
    const hasNationalId = !!initialEditAgent.nationalId;
    const {matching} = getMatching();
    const resolvedUpsertBARE = getResolvedUpsertBARE(
      matching,
      hasNationalId,
      upsertBARE,
    );
    const state: EditAgentWizardState = {
      step1Id: STEP1_ID,
      step2Id: STEP2_ID,
      currentStep,
      agentType: resolvedAgentType,
      agentSchema,
      codelistIds,
      mode,
      hasNationalId,
      upsertBARE: resolvedUpsertBARE,
      setUpsertBARE,
      // Search failed => upsert off and disabled to avoid creating duplicates in BARE
      disabledUpsertBARE: hasNationalId || search.showSearchErrors,
      agentQuery,
      search,
      isAutoSearching,
      selection,
      emptyResultAlternative:
        upsertBARE === false ? 'editInternal' : 'createExternal',
      setEmptyResultAlternative: value => {
        setUpsertBARE(value === 'createExternal');
      },
      initialEditAgent: initialEditAgent,
      hasChanges,
      showValidationErrors,
      setAgentQuery,
      gotoStep: handleGotoStep,
      agentEdited: handleAgentEdited,
      onSave: handleSaveAgent,
      onDelete: handleDeleteAgent,
    };

    return state;
  }, [
    initialEditAgent,
    getMatching,
    upsertBARE,
    currentStep,
    resolvedAgentType,
    agentSchema,
    codelistIds,
    mode,
    search,
    agentQuery,
    isAutoSearching,
    selection,
    hasChanges,
    showValidationErrors,
    handleGotoStep,
    handleAgentEdited,
    handleSaveAgent,
    handleDeleteAgent,
  ]);
};
