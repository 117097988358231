import {ThesaurusNode} from 'api/types';
import {DataSimpleValue} from 'schemaDefinition/types';

export const findThesaurusNode = (
  id: DataSimpleValue,
  thesaurus: ThesaurusNode,
): ThesaurusNode | undefined => {
  if (id === null) {
    return undefined;
  }

  if (isMatch(id, thesaurus)) {
    return thesaurus;
  } else {
    if (thesaurus.children && thesaurus.children.length > 0) {
      const nodes = thesaurus.children
        .map(node => {
          return findThesaurusNode(id, node);
        })
        .filter(v => v !== undefined);

      if (Array.isArray(nodes) && nodes.length > 0) {
        // We might find several if more then one was in the tree (e.g. child of another).
        // But we only the first one.
        if (nodes.length >= 1) {
          return nodes[0];
        }
      }
    }
  }

  return undefined;
};

const isMatch = (
  id: DataSimpleValue,
  thesaurus: ThesaurusNode,
): ThesaurusNode | undefined => {
  return id === thesaurus.id || id === thesaurus.code ? thesaurus : undefined;
};
