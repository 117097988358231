import {AndCondition, AndConditionV0} from 'schemaDefinition/types';
import {EvaluationArgs} from './types';
import {evaluateCondition} from './evaluateCondition';

export const evaluateAndCondition = (
  condition: AndConditionV0 | AndCondition,
  args: EvaluationArgs,
): boolean => {
  const arg =
    (condition as AndCondition).$and ?? (condition as AndConditionV0).arg;

  return arg.every(cond => evaluateCondition(cond, args));
};
