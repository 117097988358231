import React, {useCallback, useMemo, useState} from 'react';
import assert from 'assert-ts';
import {Concept} from 'types';
import {
  EmptyLinkedAgentMultiRole,
  FieldError,
  LinkedAgentMultiRole,
  LinkedAgentMultiRole as LinkedAgentType,
  LinkedValueLink,
  PartLinkedAgent,
  PartRef,
  UnnamedLinkedAgentMultiRole,
} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {
  assertAsLinkedValue,
  evaluateFieldRequired,
} from 'schemaDefinition/functions';
import {useCodelist} from 'services/codeLists';
import {isNullish} from 'services/utils';
import {Layout, LinkedField, SortableCodeListField, Spacer} from 'components';
import {formatLinkedValueLink} from 'schema/form/functions/formatLinkedValueLink';
import {toFieldError} from 'schema/form/functions/validators';
import {
  validateSingleLinkedAgentLink,
  validateSingleLinkedAgentRoles,
  validateSingleUnnamedAgentRole,
} from 'schema/form/functions/validators/validateLinkedAgent';
import {useFieldId} from 'schema/form/hooks';
import {BaseFieldProps} from './types';
import {useDataFormContext} from '../../contexts';
import {DataFormUpdatedValueContainerType} from '../DataFormUpdatedValueContainer';

type Props = BaseFieldProps & {
  part: PartLinkedAgent;
  ValueContainer: DataFormUpdatedValueContainerType;
};

const EmptyRoles: string[] = [];

const setLink = (
  current: LinkedAgentType | null | undefined,
  link: LinkedValueLink | undefined,
): LinkedAgentType | null => {
  const newValue = current
    ? link
      ? ({...current, link} as LinkedAgentType)
      : ({
          roles: current.roles || EmptyRoles,
          link: {
            linkStatus: 'empty',
          },
        } as EmptyLinkedAgentMultiRole)
    : link
      ? ({link, roles: EmptyRoles} as LinkedAgentType)
      : null;
  return newValue;
};
const setRoles = (
  current: LinkedAgentType | undefined | null,
  roles: string | string[] | null,
): LinkedAgentType | null => {
  const roleList = roles ? (Array.isArray(roles) ? roles : [roles]) : undefined;
  const newValue: LinkedAgentType | null = current
    ? roleList
      ? {...current, roles: roleList}
      : current
    : roleList
      ? {link: {linkStatus: 'empty'}, roles: roleList}
      : null;
  return newValue;
};

const setUnnamedRole = (
  current: UnnamedLinkedAgentMultiRole | undefined | null,
  role: string | string[] | null,
): UnnamedLinkedAgentMultiRole | null => {
  if (!assert.soft(isNullish(role) || typeof role === 'string')) {
    return current ?? null;
  }

  if (
    !assert.soft(!isNullish(current) && current.link?.linkStatus === 'unnamed')
  ) {
    return current ?? null;
  }

  const newValue: UnnamedLinkedAgentMultiRole = {
    ...current!,
    unnamedAgentRole: role as string,
  };
  return newValue;
};

export const LinkedAgent: React.FC<Props> = ({
  part,
  value,
  setFieldValue,
  valuePath,
  mode,
  diff,
  ValueContainer,
  showWhenReadonlyAndEmpty,
  label,
  focusableId,
  localScope,
  globalScope,
  relatedScope,
}) => {
  const roleCodelist = useCodelist(part.roleCodelistId);
  const unnameRoleCodelist = useCodelist('UNNAMED_PERSON');
  const {t} = useLocalization();
  const [visited, setVisited] = useState(false);
  const handleSetVisited = useCallback(() => {
    setVisited(true);
  }, []);

  const required = useMemo(() => {
    return evaluateFieldRequired(
      part.required,
      valuePath,
      localScope,
      globalScope,
      relatedScope,
      value,
    );
  }, [globalScope, part.required, relatedScope, localScope, value, valuePath]);

  const {showErrors} = useDataFormContext();
  const {rolesError, linkError, unnamedAgentRoleError} = useMemo((): {
    rolesError: FieldError;
    linkError: FieldError;
    unnamedAgentRoleError: FieldError;
  } => {
    const rolesError = part.entityRole
      ? false
      : showErrors || visited
        ? toFieldError(
            validateSingleLinkedAgentRoles(part, {
              value,
              valuePath,
              localScope,
              globalScope,
              relatedScope,
              codelistMap: {[part.roleCodelistId]: roleCodelist},
            }).valid,
          )
        : false;

    const unnamedAgentRoleError =
      (value as LinkedAgentMultiRole)?.link?.linkStatus !== 'unnamed'
        ? false
        : showErrors || visited
          ? toFieldError(
              validateSingleUnnamedAgentRole(part, {
                value,
                valuePath,
                localScope,
                globalScope,
                relatedScope,
                codelistMap: {UNNAMED_PERSON: unnameRoleCodelist},
              }).valid,
            )
          : false;

    const linkError =
      showErrors || visited
        ? toFieldError(
            validateSingleLinkedAgentLink(part, {
              value: assertAsLinkedValue(value, valuePath),
              valuePath,
              localScope,
              globalScope,
              relatedScope,
            }).valid,
          )
        : false;

    return {rolesError, linkError, unnamedAgentRoleError};
  }, [
    part,
    showErrors,
    visited,
    value,
    valuePath,
    localScope,
    globalScope,
    relatedScope,
    roleCodelist,
    unnameRoleCodelist,
  ]);

  const linkedValue = assertAsLinkedValue(value, valuePath);
  const fieldId = useFieldId(valuePath);

  const handleSetLink = useCallback(
    (link: LinkedValueLink | undefined) => {
      const newValue = setLink(linkedValue, link);
      setFieldValue(valuePath, newValue);
    },
    [linkedValue, valuePath, setFieldValue],
  );
  const handleSetRoles = useCallback(
    (roles: string | string[] | null) => {
      const newValue = setRoles(linkedValue, roles);
      setFieldValue(valuePath, newValue);
    },
    [linkedValue, valuePath, setFieldValue],
  );

  const handleSetUnnamedRole = useCallback(
    (roles: string | string[] | null) => {
      const newValue = setUnnamedRole(
        linkedValue as UnnamedLinkedAgentMultiRole,
        roles,
      );
      setFieldValue(valuePath, newValue);
    },
    [linkedValue, valuePath, setFieldValue],
  );

  const handleSetLinkWithStaticRole = useCallback(
    (link: LinkedValueLink | undefined) => {
      const withLink = setLink(null, link);
      const withRole = setRoles(
        withLink,
        part.entityRole ? [part.entityRole] : [],
      );
      setFieldValue(valuePath, withRole);
    },
    [part.entityRole, valuePath, setFieldValue],
  );

  const {placeholder, formattedValue} = useMemo(() => {
    return {
      placeholder: t('form.global.field.roles.placeholder'),
      formattedValue: formatLinkedValueLink(linkedValue?.link) ?? '',
    };
  }, [linkedValue, t]);

  const canEditLink = part.entityRole !== 'publisher';

  const nameContainerProps =
    diff && diff.mode === 'updated'
      ? {
          valuePath: `${valuePath}.link`,
          part: {name: 'link', labelKey: 'agent.link', type: 'text'} as PartRef,
        }
      : undefined;
  const rolesContainerProps =
    diff && diff.mode === 'updated'
      ? {
          valuePath: `${valuePath}.roles`,
          part: {
            name: 'roles',
            labelKey: 'agent.roles',
            type: 'codelist',
          } as PartRef,
        }
      : undefined;
  const unnamedAgentRoleContainerProps =
    diff && diff.mode === 'updated'
      ? {
          valuePath: `${valuePath}.unnamedAgentRole`,
          part: {
            name: 'unnamedAgentRole',
            labelKey: 'agent.unnamedAgentRole',
            type: 'codelist',
          } as PartRef,
        }
      : undefined;

  const isUnnamed = linkedValue?.link?.linkStatus === 'unnamed';
  const unnamedRole =
    (linkedValue as UnnamedLinkedAgentMultiRole | undefined)
      ?.unnamedAgentRole ?? null;

  return part.entityRole ? (
    <LinkedField
      focusableId={focusableId}
      name={fieldId}
      label={label}
      required={required}
      readonly={mode === 'read-only'}
      showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
      entity={Concept.agent}
      entitySubtypes={part.entitySubtypes}
      allowedEntities={part.entitySubtypes}
      error={linkError}
      link={linkedValue?.link}
      formattedValue={formattedValue}
      onChange={handleSetLinkWithStaticRole}
      onEdit={canEditLink ? handleSetLinkWithStaticRole : undefined}
      onBlur={handleSetVisited}
      data-cy={'agent-value'}
    />
  ) : (
    <Layout horizontal={diff ? undefined : true} flex={1}>
      <ValueContainer {...nameContainerProps}>
        <LinkedField
          focusableId={focusableId}
          name={fieldId}
          label={label}
          required={required}
          readonly={mode === 'read-only'}
          showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
          searchTitleKey="general.entity.agent"
          entity={Concept.agent}
          entitySubtypes={part.entitySubtypes}
          allowedEntities={part.entitySubtypes}
          error={linkError}
          link={linkedValue?.link}
          linkable={!isUnnamed}
          formattedValue={formattedValue}
          onChange={handleSetLink}
          onEdit={canEditLink ? handleSetLink : undefined}
          flex={1}
          data-cy={'agent-value'}
        />
      </ValueContainer>
      <Spacer size={diff ? 1 : 2} />
      <ValueContainer {...rolesContainerProps}>
        <SortableCodeListField
          label={label}
          value={linkedValue?.roles ?? EmptyRoles}
          cardinality={'multiple'}
          readonly={mode === 'read-only'}
          showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
          error={rolesError}
          name={fieldId}
          id={fieldId}
          placeholder={placeholder}
          options={roleCodelist.codes}
          flex={1}
          onChange={handleSetRoles}
          onBlur={handleSetVisited}
          data-cy={'agent-role'}
        />
      </ValueContainer>
      {isUnnamed ? <Spacer size={diff ? 1 : 2} /> : null}
      {isUnnamed ? (
        <ValueContainer {...unnamedAgentRoleContainerProps}>
          <SortableCodeListField
            label={label}
            value={unnamedRole}
            cardinality={'single'}
            readonly={mode === 'read-only'}
            showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
            error={unnamedAgentRoleError}
            name={fieldId}
            id={fieldId}
            placeholder={placeholder}
            options={unnameRoleCodelist.codes}
            flex={1}
            onChange={handleSetUnnamedRole}
            onBlur={handleSetVisited}
            data-cy={'unnamed-agent-role'}
          />
        </ValueContainer>
      ) : null}
    </Layout>
  );
};
