import {Data, PartSchema} from 'schemaDefinition/types';
import {isNullish} from 'services/utils';
import {
  ValidateSingleTypeValue,
  ValidationArgs,
  ValidationResult,
} from './types';
import {assertData} from './assertData';
import {fail} from './fail';
import {getValue} from './getValue';
import {validResult} from './validResult';
import {validateSchema} from './validateSchema';
import {validateValueType} from './validateValueType';
import {warning} from './warning';

/**
 * validates data[part.name] according to "value" part
 */
export const validateSchemaPart = (
  part: PartSchema,
  args: ValidationArgs<Data>,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  if (part.noValidation) {
    return aggregateResult;
  }

  const {value, valuePath} = getValue(args.value, part, args.valuePath);

  return validateValueType(
    part,
    validateSingleSchemaInstance,
    {
      ...args,
      value,
      valuePath: valuePath,
      // Might want to keep the current localscope.
      localScope: undefined,
    },
    aggregateResult,
  );
};

const validateSingleSchemaInstance: ValidateSingleTypeValue<PartSchema> = (
  part: PartSchema,
  args,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  if (!part.required && isNullish(args.value)) {
    return aggregateResult;
  }

  if (part.required && !args.value) {
    return (part.required === true ? fail : warning)(
      {
        ...args,
        part,
        validation: 'required',
        messageKey: 'required',
      },
      aggregateResult,
    );
  }

  return validateSchema(
    part,
    {
      ...args,
      localScope: assertData(args.value),
    },
    aggregateResult,
  );
};
