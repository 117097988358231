import {NotCondition, NotConditionV0} from '../types';
import {EvaluationArgs} from './types';
import {evaluateCondition} from './evaluateCondition';

export const evaluateNotCondition = (
  condition: NotConditionV0 | NotCondition,
  args: EvaluationArgs,
): boolean => {
  const notArgs =
    (condition as NotCondition).$not ?? (condition as NotConditionV0).arg;

  return !evaluateCondition(notArgs, args);
};
