import {useCallback, useMemo} from 'react';
import {FCWithChildren} from 'types';
import {LocalizationContextType} from 'localization/context/types';
import {DataValue, Part} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {Layout, Spacer} from 'components';
import {useTextValue} from 'schema/preview/hooks';
import {BaseFieldProps} from '../types';
import {ValueLayout} from './ValueLayout';

type Props<TPart extends Part> = BaseFieldProps<TPart> & {
  prefix?: string | JSX.Element;
  suffix?: string | JSX.Element;
  formatter: (
    value: DataValue,
    part: TPart,
    localization: LocalizationContextType,
  ) => string;
};

type AdornmentElementsProps = {
  prefix?: JSX.Element;
  suffix?: JSX.Element;
};
const WithAdornmentElements: FCWithChildren<AdornmentElementsProps> = ({
  prefix,
  suffix,
  children,
}) => {
  return prefix || suffix ? (
    <Layout horizontal adjustCenter adjustLeft>
      {prefix}
      {prefix ? <Spacer width={1} /> : null}
      {children}
      {suffix}
    </Layout>
  ) : (
    <>{children}</>
  );
};

export const SingleLineValue = <TPart extends Part>({
  part,
  value,
  layoutDirection,
  prefix,
  suffix,
  formatter,
}: Props<TPart>) => {
  const localization = useLocalization();

  const formattedValue = useMemo(() => {
    const base = formatter(value, part, localization);
    const prefixText = typeof prefix === 'string' ? prefix : undefined;
    const suffixText = typeof suffix === 'string' ? suffix : undefined;
    return prefixText || suffixText
      ? `${prefixText ?? ''}${base}${suffixText ?? ''}`
      : base;
  }, [formatter, localization, part, prefix, suffix, value]);

  const TextValue = useTextValue();

  const renderValue = useCallback(() => {
    const prefixElement = typeof prefix === 'string' ? undefined : prefix;
    const suffixElement = typeof suffix === 'string' ? undefined : suffix;
    return (
      <WithAdornmentElements prefix={prefixElement} suffix={suffixElement}>
        <TextValue>{formattedValue}</TextValue>
      </WithAdornmentElements>
    );
  }, [TextValue, formattedValue, prefix, suffix]);

  return (
    <ValueLayout
      renderValue={renderValue}
      part={part}
      layoutDirection={layoutDirection}
    />
  );
};
