import React, {useEffect, useState} from 'react';
import {Link} from '@mui/material';
import {Media} from 'api/types';
import {getMediaUrl} from 'api';
import {useGetTokens} from 'services/auth';
import {useDownloadFile, usePreviewFile} from 'services/data';
import {useHandleDelayedCall} from 'services/utils';
import {Layout, ToolBar} from 'components';

type Props = {
  file: Media;
  mock?: boolean;
};

export const FileToolbar: React.FC<Props> = ({file, mock = false}) => {
  const [directDownloadUrl, setDirectDownloadUrl] = useState<
    string | undefined
  >();
  const getTokens = useGetTokens();
  const {preview} = usePreviewFile(file, mock);
  const {download} = useDownloadFile(file, mock);

  const handlePreviewDelayed = useHandleDelayedCall(preview);
  const handleDownloadDelayed = useHandleDelayedCall(download);

  /**
   * If above threshold, use direct download.
   * Some users struggle with big files. Maybe because of blob size, but we
   * just offer a direct download option.
   */
  useEffect(() => {
    const fileThreshold = 200 * (1024 * 1024); // 200 MB

    if ((file.size ?? 0) > fileThreshold) {
      getMediaUrl(file.id, getTokens, mock).then(url =>
        setDirectDownloadUrl(url),
      );
    }
  }, [file.id, file.size, getTokens, mock]);

  return (
    <Layout horizontal adjustCenter adjustRight sx={{width: '100%'}}>
      <ToolBar spacing="tight">
        {directDownloadUrl ? (
          <Link
            download={'hola'}
            target="_blank"
            rel="noopener noreferrer"
            href={directDownloadUrl}
            underline="hover">
            Direktelink
          </Link>
        ) : null}

        <ToolBar.ControlledIconTool
          icon="Download"
          size="small"
          onClick={handleDownloadDelayed.handleCall}
          isCalling={handleDownloadDelayed.isCalling}
          isDelayed={handleDownloadDelayed.isDelayed}
        />
        <ToolBar.ControlledIconTool
          icon="Preview"
          size="small"
          onClick={handlePreviewDelayed.handleCall}
          isCalling={handlePreviewDelayed.isCalling}
          isDelayed={handlePreviewDelayed.isDelayed}
        />
      </ToolBar>
    </Layout>
  );
};
