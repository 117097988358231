import assert from 'assert-ts';
import {
  DateValidation,
  PartDate,
  PartYear,
  PartYearOrDate,
} from 'schemaDefinition/types';
import {isRangeCondition} from 'schemaDefinition';
import {evaluateDateRangeCondition} from 'schemaDefinition/functions/evaluateDateRangeCondition';
import {ValidationArgs, ValidationResult} from './types';
import {fail} from './fail';
import {validResult} from './validResult';

export const validateYearOrDateValidations = (
  validations: DateValidation | DateValidation[],
  part: PartYear | PartDate | PartYearOrDate,
  args: ValidationArgs,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  (Array.isArray(validations) ? validations : [validations]).forEach(
    condition => {
      assert(
        isRangeCondition(condition),
        'validateYearOrDateValidations: only range condition supported',
        condition,
      );

      if (!evaluateDateRangeCondition(condition, args)) {
        aggregateResult = fail(
          {
            ...args,
            part,
            validation: condition,
            messageKey: condition.messageKey,
          },
          aggregateResult,
        );
      }
    },
  );

  return aggregateResult;
};
