import {DataValue, Val} from '../types';
import {EvaluationArgs} from './types';
import {evaluateVal} from './evaluateVal';

/**
 * If value cannot be converted to argType, then it is undefined.
 */
export const evaluateValWithDefault = (
  val: Val | undefined,
  args: EvaluationArgs,
  defaultValue: DataValue | undefined,
): DataValue | undefined => {
  return val !== undefined ? evaluateVal(val, args) : defaultValue ?? null;
};
