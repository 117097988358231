import {BooleanCondition} from 'schemaDefinition/types';
import {valueAsBool} from 'services/utils';
import {EvaluationArgs} from './types';
import {evaluateValWithDefault} from './evaluateValWithDefault';

export const evaluateBooleanCondition = (
  condition: BooleanCondition,
  args: EvaluationArgs,
): boolean => {
  const arg = evaluateValWithDefault(condition.arg, args, args.value);

  const boolValue = valueAsBool(arg);
  return boolValue;
};
