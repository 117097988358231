import React, {Fragment, useCallback, useMemo} from 'react';
import {PartThesaurus} from 'schemaDefinition/types';
import {NodeAndAncestors} from 'services/thesaurus/types';
import {assertAsThesaurusValue} from 'schemaDefinition/functions';
import {
  getNodesAndAncestors,
  groupPrCategory,
  orderByThesaurusCategories,
  useThesaurus,
} from 'services/thesaurus';
import {formatNodeTitle} from 'services/thesaurus/functions/formatNodeTitle';
import {FieldLabel} from 'components/fields/FieldLabel';
import {useLabelAndPlaceholder} from 'schema/form/hooks';
import {usePreviewConfigurationContext} from 'schema/preview/contexts';
import {useTextValue} from 'schema/preview/hooks';
import {BaseFieldProps} from '../types';
import {GroupLabelProps} from '../constants';
import {ValueLayout} from './ValueLayout';

type Props = BaseFieldProps<PartThesaurus>;

export const ThesaurusItemValue: React.FC<Props> = ({part, value}) => {
  const {label} = useLabelAndPlaceholder(part);
  const config = usePreviewConfigurationContext();
  const {noLabels} = config.layout;
  const thesaurus = useThesaurus(part.thesaurusId);
  const thesaurusValueOrdered = useMemo(() => {
    const thesaurusValue = assertAsThesaurusValue(value);

    const nodesWithAncestors = thesaurusValue
      ? getNodesAndAncestors(thesaurusValue, thesaurus)
      : [];

    const grouped = groupPrCategory(nodesWithAncestors);

    return thesaurusValue
      ? orderByThesaurusCategories(grouped, thesaurus, g => g.categoryNode.id)
      : undefined;
  }, [thesaurus, value]);
  const TextValue = useTextValue();

  const renderNodes = useCallback(
    (nodes: NodeAndAncestors[]) => {
      return (
        <TextValue>
          {nodes
            .map(({node}) =>
              formatNodeTitle(node, part.showCode, part.selectableNodeTypes),
            )
            .join(', ')}
        </TextValue>
      );
    },
    [TextValue, part],
  );

  return thesaurusValueOrdered ? (
    <Fragment>
      {noLabels ? null : <FieldLabel label={label ?? ''} />}
      {thesaurusValueOrdered.map(c => (
        <ValueLayout
          key={c.categoryNode.id}
          renderValue={() => renderNodes(c.nodes)}
          forcedLabel={c.categoryNode.label}
          labelProps={GroupLabelProps}
          part={part}
          layoutDirection={'horizontal'}
        />
      ))}
    </Fragment>
  ) : null;
};
