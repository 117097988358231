import {assert} from 'assert-ts';
import {
  Data,
  DataSimpleValue,
  DataValue,
  Val,
  ValueRefType,
  ValueRefTypeGlobal,
  ValueRefTypeLocal,
  ValueRefTypeProp,
  ValueRefTypeRelated,
} from '../types';
import {EvaluationArgs} from './types';
import {evaluatePropValue} from './evaluatePropValue';
import {isConstantSingleValue} from './isConstantSingleValue';
import {isRelativeDateValue} from './isRelativeDateValue';
import {resolveRelativeDateValue} from './resolveRelativeDateValue';

/**
 * If any values cannot be converted to argType, then the condition is undefined.
 */
export const evaluateVal = (
  val: Val | undefined,
  args: EvaluationArgs,
): DataValue => {
  if (val === undefined) {
    return null;
  }

  // Constant value
  if (isConstantSingleValue(val)) {
    return val as DataValue;
  }

  // Array of values
  if (Array.isArray(val)) {
    return val.map(v => evaluateVal(v, args)) as DataSimpleValue[] | Data[];
  }

  // Relative date
  if (isRelativeDateValue(val)) {
    return resolveRelativeDateValue(val);
  }

  // Reference value
  const refType = val.$ref[0] as ValueRefType;
  const refPath = val.$ref.slice(1);

  // - prop keys
  if (refType === ValueRefTypeProp) {
    return evaluatePropValue(refPath, args.value);
  }

  if (refType === ValueRefTypeLocal) {
    return evaluatePropValue(refPath, args.localScope);
  }

  // - global keys
  if (refType === ValueRefTypeGlobal) {
    const assertProps = {
      val,
      valuePath: args.valuePath,
      globalScope: args.globalScope,
    };
    assert(
      args.globalScope !== undefined,
      'evaluateVal: globalScope must be set when globalPathKeys',
      assertProps,
    );
    return evaluatePropValue(refPath, args.globalScope);
  }

  // - related keys
  if (refType === ValueRefTypeRelated) {
    const assertProps = {
      val,
      valuePath: args.valuePath,
      relatedScope: args.relatedScope,
    };
    assert(
      args.relatedScope !== undefined,
      'evaluateVal: relatedScope must be set when relatedPathKeys',
      assertProps,
    );
    return evaluatePropValue(refPath, args.relatedScope);
  }

  assert(false, 'evaluateVal: invalid val - all undefined', {
    val,
    valuePath: args.valuePath,
  });
};
