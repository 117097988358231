import React, {useCallback} from 'react';
import {PartCodeList, PartCodeListOrText} from 'schemaDefinition/types';
import {useCodelist} from 'services/codeLists';
import {useTextValue} from 'schema/preview/hooks';
import {BaseFieldProps} from '../types';
import {formatCodeListValue} from '../../functions';
import {ValueLayout} from './ValueLayout';

type Props = BaseFieldProps<PartCodeList | PartCodeListOrText>;

export const CodeListValue: React.FC<Props> = ({
  part,
  value,
  layoutDirection,
}) => {
  const codeList = useCodelist(part.codelistId);
  const TextValue = useTextValue();

  const renderValue = useCallback(() => {
    const formattedValue = formatCodeListValue(value, part, codeList);

    return <TextValue>{formattedValue}</TextValue>;
  }, [TextValue, codeList, part, value]);

  return (
    <ValueLayout
      renderValue={renderValue}
      part={part}
      layoutDirection={layoutDirection}
    />
  );
};
