import React, {useMemo} from 'react';
import {TextProps} from 'components/text/types';
import {useThemeColors} from 'theme';
import {Text} from 'components/text';

type Props = Omit<TextProps, 'kind'>;

export const TooltipText: React.FC<React.PropsWithChildren<Props>> = props => {
  const t = useThemeColors();
  const sx = useMemo(() => ({color: t.text.primary}), [t.text.primary]);

  return <Text {...props} sx={sx} />;
};
