import {Arg1AndArg2} from 'schemaDefinition/types';

export const isArg1AndArg2 = <T>(args: unknown): args is Arg1AndArg2<T> => {
  const asArg1AndArg2 = args as Arg1AndArg2<T>;
  return (
    asArg1AndArg2 &&
    asArg1AndArg2.arg1 !== undefined &&
    asArg1AndArg2.arg2 !== undefined
  );
};
