import React from 'react';
import {OverviewTask} from 'api/types';
import {useLocalization} from 'localization';
import {CodeListsGate} from 'services/codeLists';
import {SearchInput} from 'components/dataTable/components';
import {Table, useSchemaListCodelistIds} from 'schema';
import {taskSchema} from 'schemas';
import {overviewTaskTableConfig} from 'schemas/configurations/table/overviewTaskTableConfig';
import {useOverviewContext} from '../context/OverviewContext';

const sx = {
  float: 'left',
};

export const TaskTable: React.FC = () => {
  const {
    loading,
    filteredTasks,
    openManifestation,
    searchText,
    setSearchText,
    onRowsUpdate,
  } = useOverviewContext();
  const {t} = useLocalization();

  const schemaCodelistIds = useSchemaListCodelistIds([taskSchema]);

  return (
    <CodeListsGate codeLists={schemaCodelistIds}>
      <SearchInput
        value={searchText || ''}
        onChange={setSearchText}
        placeholder={t('page.overview.search.placeholder')}
        sx={sx}
      />
      <Table<OverviewTask>
        header={t('page.overview.tasks.today')}
        schema={taskSchema}
        data={filteredTasks}
        loading={loading}
        onRowClick={openManifestation}
        configuration={overviewTaskTableConfig}
        onRowsUpdate={onRowsUpdate}
      />
    </CodeListsGate>
  );
};
