import React, {useCallback, useMemo, useState} from 'react';
import {assert} from 'assert-ts';
import {
  Data,
  DataSimpleValue,
  EmptyLinkedAgentMultiRole,
  EmptyLinkedLiterary,
  FieldError,
  PartBool,
  PartDate,
  PartHtml,
  PartInt,
  PartLinkedAgent,
  PartLinkedLiterary,
  PartSchema,
  PartText,
  PartTextArea,
  PartYear,
  PartYearOrDate,
} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {evaluateFieldRequired} from 'schemaDefinition/functions';
import {useFormSchemaGlobalScopeContext} from 'schema/contexts';
import {usePartCodelists} from 'schema/hooks';
import {BasePartSimpleProps} from './types';
import {SingleValueListField, useListOperations} from '../../../components';
import {useDataFormContext} from '../contexts';
import {getErrorMessage} from '../functions';
import {toFieldError} from '../functions/validators';
import {validatePart} from '../functions/validators/validateSchema';
import {
  useAddItemLabel,
  useCompareItem,
  useContentLabelsWithLayout,
  useDeleteAllStrings,
  useLabelAndPlaceholder,
  useLayout,
  verifyGlobalScope,
  verifyLocalScope,
} from '../hooks';
import {useResolvedDataFormPartConfiguration} from '../hooks/useResolvedDataFormPartConfiguration';
import {DataFormSingleValue} from './DataFormSingleValue';

type SingleValue =
  | PartText
  | PartTextArea
  | PartHtml
  | PartInt
  | PartBool
  | PartYear
  | PartDate
  | PartYearOrDate
  | PartLinkedAgent
  | PartLinkedLiterary
  | PartSchema;

type DataFormSingleValueListProps = BasePartSimpleProps & {
  part: SingleValue;
  /** @deprecated Use DataFormConfiguration hideStructureOperations instead */
  noMove?: boolean;
};

const newValue = (part: SingleValue): null | string | Data => {
  if (part.type === 'linkedAgent') {
    const linkedAgent: EmptyLinkedAgentMultiRole = {
      link: {linkStatus: 'empty'},
      roles: [],
    };
    return linkedAgent;
  }
  if (part.type === 'linkedLiterary') {
    const linkedLiteray: EmptyLinkedLiterary = {link: {linkStatus: 'empty'}};
    return linkedLiteray;
  }
  if (part.type === 'text') return '';
  if (part.type === 'textarea') return '';
  if (part.type === 'int') return null;
  if (part.type === 'bool') return null;
  if (part.type === 'year') return null;
  if (part.type === 'schema') return {};
  return null;
};

const EmptyValue = null;

export const DataFormSingleValueList: React.FC<
  DataFormSingleValueListProps
> = props => {
  const {
    part,
    useValue,
    valuePath,
    scopePath,
    usesGlobalScope,
    relatedScope,
    mode,
    diff,
    noMove,
  } = props;
  const {tLoose} = useLocalization();
  const codelistMap = usePartCodelists(part);
  const {id, showErrors, setEditValue} = useDataFormContext();

  const {
    hideStructureOperations,
    showWhenReadonlyAndEmpty,
    renderCustomListActions,
  } = useResolvedDataFormPartConfiguration(part);
  const value = useValue(valuePath, id, part.name) ?? EmptyValue;
  const scope = verifyLocalScope(
    useValue(scopePath, id, `${part.name}.localScope`),
    scopePath,
  );
  const {valuePath: globalPath} = useFormSchemaGlobalScopeContext();
  const globalScope = verifyGlobalScope(
    useValue(
      usesGlobalScope ? globalPath : undefined,
      id,
      `${part.name}.globalScope`,
    ),
    usesGlobalScope,
  );

  const [visited, setVisited] = useState(false);
  const handleSetVisited = useCallback(() => setVisited(true), []);

  const required = useMemo(() => {
    return evaluateFieldRequired(
      part.required,
      valuePath,
      scope,
      globalScope,
      relatedScope,
      value,
    );
  }, [globalScope, part.required, relatedScope, scope, value, valuePath]);

  const {error, errorMessage} = useMemo((): {
    error: FieldError;
    errorMessage?: string;
  } => {
    if (showErrors || visited) {
      const validation = validatePart(part, {
        valuePath,
        localScope: scope,
        globalScope,
        relatedScope,
        // Use localscope if provided (e.g. something referenced by name/ref etc),
        // else create scope with only part property values.
        value: scope ?? {[part.name]: value},
        codelistMap,
      });

      return {
        error: toFieldError(validation.valid),
        errorMessage: getErrorMessage(validation, tLoose),
      };
    }

    return {error: false};
  }, [
    codelistMap,
    globalScope,
    part,
    relatedScope,
    scope,
    showErrors,
    tLoose,
    value,
    valuePath,
    visited,
  ]);

  assert(
    value === null || Array.isArray(value),
    'DataForm: multi-value property must be array (or null)',
    {part: part, contextPath: valuePath, dataScope: value},
  );

  const {onAppendValue, onDeleteValue, onDeleteAll, onMoveValue} =
    useListOperations<Data | DataSimpleValue>(
      update => {
        const nextValue = update((value ?? []) as (Data | DataSimpleValue)[]);
        setEditValue(valuePath, nextValue, part);
        return nextValue;
      },
      () => newValue(part),
    );

  const {label} = useLabelAndPlaceholder(part);
  const addLabel = useAddItemLabel(part);
  const deleteAllStrings = useDeleteAllStrings(part);
  const contentLabels = useContentLabelsWithLayout(part);
  const layout = useLayout(part);

  const {
    valuePaddingSize,
    getItemValuePath,
    getItemDiff,
    getItemContainerProps,
    getItemContainer,
  } = useCompareItem(diff, part, value, valuePath);

  return (
    <SingleValueListField<Data | DataSimpleValue>
      addLabel={addLabel}
      deleteAllStrings={deleteAllStrings}
      label={label}
      contentLabels={contentLabels}
      name={part.name}
      required={required}
      readonly={mode === 'read-only'}
      showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
      cardinality={part.cardinality}
      error={error}
      errorMessage={errorMessage}
      values={(value ?? []) as Data[]}
      {...layout}
      valuePaddingSize={valuePaddingSize}
      getValueKey={getItemValuePath}
      renderValue={(idx, _, focusableId) => {
        const subpath = getItemValuePath(idx);
        const partContainerProps = getItemContainerProps?.(idx);
        const nestedDiff = getItemDiff?.(idx);
        const ItemContainer = getItemContainer(idx);

        return (
          <ItemContainer {...partContainerProps}>
            <DataFormSingleValue
              focusableId={focusableId}
              part={part}
              noLabel
              useValue={useValue}
              valuePath={subpath}
              scopePath={scopePath}
              usesGlobalScope={usesGlobalScope}
              relatedScope={relatedScope}
              mode={mode}
              diff={nestedDiff}
              data-cy={`single-value-${part.name}`}
            />
          </ItemContainer>
        );
      }}
      renderCustomActions={
        renderCustomListActions
          ? () => renderCustomListActions(part, props)
          : undefined
      }
      onAppendValue={
        hideStructureOperations?.includes('add') ? undefined : onAppendValue
      }
      onDeleteValue={
        hideStructureOperations?.includes('delete') ? undefined : onDeleteValue
      }
      onDeleteAll={
        hideStructureOperations?.includes('delete') ? undefined : onDeleteAll
      }
      onMoveValue={
        hideStructureOperations?.includes('move') || noMove
          ? undefined
          : onMoveValue
      }
      onBlur={handleSetVisited}
    />
  );
};
