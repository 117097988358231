/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Box, Button} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {AgentSubType, Concept, NoProps} from 'types';
import {TableConfiguration} from 'schema/table/types';
import {PreviewConfiguration} from 'schema/types';
import {Data, Schema} from 'schemaDefinition/types';
import {ColorPalette} from 'theme';
import {CodeListId, MainTitle, NationalAgent, OtherTitle} from 'api';
import {visitSchemaParts} from 'schemaDefinition/functions';
import {CodeListsGate} from 'services/codeLists';
import {ThesaurusesGate} from 'services/thesaurus';
import {Builder} from 'services/utils';
import {
  ActionButton,
  Card,
  CheckboxField,
  FlexBox,
  GridLayout,
  Icon,
  IconButton,
  Layout,
  ProgressCircle,
  Scene,
  Spacer,
  Text,
  TextArea,
  TextChanges,
  TextField,
  useAlertDialog,
  useAlertDialogAsPromise,
  useSnacks,
} from 'components';
import {useAlertDialogAsPromiseV2} from 'components/alertdialog/hooks/useAlertDialogAsPromiseV2';
import {useAlertDialogAsPromiseV3} from 'components/alertdialog/hooks/useAlertDialogAsPromiseV3';
import {LocalizedFormatting} from 'components/demos/LocalizedFormatting';
import {FieldLabel} from 'components/fields/FieldLabel';
import {Tooltip, TooltipContent} from 'components/tooltip';
import {DataFormConfigurationContextType, Preview, Table} from 'schema';
import {DataFormV1} from 'schema/form';
import {EditAgentWizardDialog} from 'editors/agent/components';
import {useLinkedValueSearchContextProvider} from 'sceneExtensions';
import {Icons} from '../../components/demos/Icons';
import {TestCodeListSelectors} from './TestCodeListSelectors';

type Props = NoProps;

const codeLists: CodeListId[] = [
  'COUNTRY',
  'LANGUAGE',
  'EXPRESSION_FORMAT',
  'PRODUCT_FORM',
  'GENDER',
  'EDITION_TYPE',
];

export const DemoScene: React.FC<Props> = () => {
  // const handleSave = useCallback((updatedData: Data) => {
  //   const updatedManifestation = updatedData as unknown as Manifestation;
  //   return putManifestation('9788202098087', updatedManifestation.mainTitle);
  // }, []);

  // const spotColors = useSpotColors();

  // const verifiedAuthor: VerifiedLinkedValue = {
  //   linkStatus: 'verified',
  //   roles: ['author'],
  //   agentId: '1234',
  //   agentType: Concept.person,
  //   agentName: {
  //     id: '12345',
  //     name: 'Lars',
  //     surName: 'Mytting',
  //   },
  //   name: 'Lars Mytting',
  // };

  // const unverifiedAuthor: UnverifiedLinkedValue = {
  //   type: 'unverified',
  //   roles: ['author'],
  //   name: 'Jo Nesbø',
  // };

  // const [authors, setAuthors] = useState<LinkedValue[]>([
  //   verifiedAuthor,
  //   unverifiedAuthor,
  // ]);
  // const {
  //   onReplace: replaceAuthor,
  //   onDeleteValue: deleteAuthor,
  //   ...authorsOp
  // } = useListOperations(
  //   setAuthors,
  //   () => ({type: 'empty'} as EmptyLinkedValue),
  // );

  // const [subtitles, setSubtitles] = useState<string[]>([]);
  // const {onReplace: replaceSubtitle, ...subtitlesOp} = useListOperations(
  //   setSubtitles,
  //   () => '',
  // );

  // const getKey = (name: string, index: number) => `${name}${index}`;

  // const getAuthorKey = (index: number) => getKey('author', index);

  // const getSubtitleKey = (index: number) => getKey('subtitle', index);

  const LinkedValueSearchContextProvider =
    useLinkedValueSearchContextProvider();

  // const [isbn, setIsbn] = useState('9875849283847');
  // const [no, setNo] = useState('2');
  // const [year, setYear] = useState(1998);
  // const [format, setFormat] = useState<string | null>('AA');
  // const [languages, setLanguages] = useState<string[] | null>(['se', 'no']);
  // const [gender, setGender] = useState<string | null>('female');
  // const [forSalesDate, setForSalesDate] = useState<string | null>('2022-04-01');
  // const [isDate, setIsDate] = useState(false);

  // const productFormCodeList = useCodeList('productForm');
  // const languageCodeList = useCodeList('language');
  // const genderCodeList = useCodeList('GENDER');

  const {infoSnack, successSnack, warningSnack, errorSnack} = useSnacks();

  const testSchema = useMemo(
    (): Schema => ({
      name: 'testTitles',
      key: 'testTitles',
      parts: [
        {
          type: 'text',
          name: 'tekst1',
        },
        {
          type: 'textarea',
          name: 'tekst2',
        },
        {
          type: 'int',
          name: 'int1',
        },
        {
          type: 'bool',
          name: 'b1',
        },
        {
          type: 'year',
          name: 'y1',
        },
        {
          type: 'date',
          name: 'd1',
        },
        {
          type: 'yearOrDate',
          name: 'yd1',
        },
        {
          type: 'codelist',
          name: 'cl1',
          codelistId: 'COUNTRY',
        },
        // {
        //   type: 'thesaurus',
        //   name: 'th1',
        //   thesaurusId: 'thema',
        //   cardinality: 'multiple',
        // },
      ],
    }),
    [],
  );

  const titleSchema: Schema = {
    name: 'testTitles',
    key: 'testTitles',
    parts: [
      {
        type: 'text',
        name: 'tekst1',
        required: true,
      },
      // {
      //   type: 'schema',
      //   name: 'titles',
      //   parts: [
      //     {
      //       type: 'text',
      //       name: 'value',
      //       required: true,
      //     },
      //     {
      //       type: 'text',
      //       name: 'parallelTitles',
      //       cardinality: 'multiple',
      //     },
      //     {
      //       type: 'schema',
      //       name: 'subTitles',
      //       parts: [
      //         {
      //           type: 'text',
      //           name: 'value',
      //           required: true,
      //         },
      //         {
      //           name: 'parallelSubTitles',
      //           type: 'text',
      //           cardinality: 'multiple',
      //         },
      //       ],
      //       cardinality: 'multiple',
      //     },
      //   ],
      //   cardinality: 'multiple',
      //   required: true,
      // },
    ],
  };
  const titleFormConfiguration = useMemo(
    (): DataFormConfigurationContextType => ({
      schemaContainers: {
        titles: {
          colorx: ColorPalette.beige,
        },
        otherTitles: 'NoContainer',
      },
      showWhenReadonlyAndEmpty: true,
      parts: {},
    }),
    [],
  );

  const [timestamp, setTimestamp] = useState(Date.now());

  const testEntityName = 'Publisher Agent';
  const testAllRequiredSchema = useMemo(() => {
    const clone = JSON.parse(JSON.stringify(testSchema)) as Schema;
    visitSchemaParts(clone, part => {
      part.required = true;
    });
    return clone;
  }, [testSchema]);

  const testAllShouldSchema = useMemo(() => {
    const clone = JSON.parse(JSON.stringify(testSchema)) as Schema;
    visitSchemaParts(clone, part => {
      part.required = 'should';
    });
    return clone;
  }, [testSchema]);

  const testAllOptionalSchema = useMemo(() => {
    const clone = JSON.parse(JSON.stringify(testSchema)) as Schema;
    visitSchemaParts(clone, part => {
      part.required = false;
    });
    return clone;
  }, [testSchema]);

  const [previewConfig, setPreviewConfig] = useState<PreviewConfiguration>({
    nameVariant: {
      excludeMainForm: true,
    },
    layout: {
      groups: {
        info: {
          direction: 'horizontal',
        },
        internalComments: {
          direction: 'horizontal',
        },
      },
    },
  });

  const makeTitleWithParallelTitles = ({
    value,
    parallelTitles,
    parallelSubTitles,
  }: {
    value?: string;
    parallelTitles?: string[];
    parallelSubTitles?: string[];
  }): string | undefined => {
    const parallel = (parallelTitles ?? parallelSubTitles ?? []).join('; ');
    const title = new Builder(value).add(' = ', parallel).value;

    return title;
  };

  const [tableConfig] = useState<TableConfiguration>({
    defaultColumns: [
      {
        name: 'personName',
      },
      {
        name: 'nameVariants',
      },
      {
        name: 'edition',
        visible: true,
      },
      {
        name: 'publishedYear',
        visible: true,
      },
      {
        name: 'profession',
        visible: true,
      },
      {
        name: 'birth',
        visible: true,
      },
      {
        name: 'gender',
        visible: true,
      },
      {
        name: 'language',
      },
      {
        name: 'nationalId',
      },
      {
        name: 'isni',
      },
      {
        name: 'isPseudonym',
      },
      {
        name: 'isCollectivPseudonym',
      },
    ],
  });

  const [person, setPerson] = useState<Data>({id: 1});
  const [person2] = useState<Data>({id: 2});

  // Wizard dialog
  const [isWizardDialogOpen, setIsWizardDialogOpen] = useState<{
    [key in AgentSubType]?: boolean;
  }>({});

  // Temp fix, will be resolved by schema. Depends on ThesaurusesGate before population
  //const themaThesaurusData = useThesaurus('thema');
  //const getTokens = useGetTokens();

  // console.log(themaThesaurusData);
  //const themaTheasaurusData: TreeNode[] = []

  const [previewTitles, setPreviewTitles] = useState(true);
  type TitleInfo = {
    titles?: MainTitle[];
    otherTitles?: OtherTitle[];
  };
  const titleInfoRef = useRef<TitleInfo>({});

  const {
    AlertDialog: AlertDialogV3,
    pleaseConfirm,
    isOpen,
    isClosing,
  } = useAlertDialogAsPromiseV3();

  const {AlertDialog: AlertDialogV2, pleaseConfirm: pleaseConfirmV2} =
    useAlertDialogAsPromiseV2();

  const [count, setCount] = useState(0);

  const handleConfirmV3 = useCallback((): Promise<void> => {
    return pleaseConfirm(
      () =>
        new Promise<void>(resolve => {
          console.log('saving...');
          setTimeout(() => {
            console.log('saved', count);
            resolve();
          }, 1000);
        }),
      {
        title: 'Test AlertDialogV3',
        body: `Test body: ${count}`,
        okTitle: 'OK',
        cancelTitle: 'Cancel',
      },
    ).then();
  }, [count, pleaseConfirm]);

  const [original, setOriginal] = useState('På nittitallet');
  const [updated, setUpdated] = useState('På 90-tallet');

  return (
    <CodeListsGate codeLists={codeLists}>
      <ThesaurusesGate thesauruses={['thema']}>
        <LinkedValueSearchContextProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Scene browserTitle={'Demo'}>
              <Scene.Header title={'Testing / demos'} />
              <Scene.Content>
                <GridLayout>
                  <GridLayout.Item>
                    <Card color="secondary">
                      <Card.Header>
                        <Text variant="h2" kind="primary">
                          {'Tooltip'}
                        </Text>
                      </Card.Header>
                      <Card.Content>
                        <FlexBox horizontal>
                          <Text variant="h2">
                            Simple tooltip - string title
                          </Text>
                          <Tooltip title="This is a tooltip" />
                        </FlexBox>
                        <Spacer size={3} />
                        <FlexBox horizontal>
                          <Text variant="h2">Complex tooltip</Text>
                          <Tooltip
                            title={
                              <TooltipContent
                                header="Header"
                                description="Description"
                              />
                            }
                          />
                        </FlexBox>
                      </Card.Content>
                    </Card>
                    <Spacer size={2} />

                    <Card color="secondary">
                      <Card.Header>
                        <Text variant="h2" kind="primary">
                          {'Text differences'}
                        </Text>
                      </Card.Header>
                      <Card.Content>
                        <FlexBox flex={1} horizontal>
                          <FlexBox flex={1}>
                            <TextField
                              name="original"
                              label="Current value"
                              value={original}
                              type="text"
                              diff={{
                                mode: 'original',
                                compareValue: updated,
                              }}
                              onChange={e => setOriginal(e.target.value)}
                              prefix={<Icon icon="Ghost" />}
                            />
                          </FlexBox>
                          <Spacer width={2} />
                          <FlexBox flex={1}>
                            <TextField
                              name="original"
                              label="Proposed value"
                              value={updated}
                              type="text"
                              diff={{
                                mode: 'updated',
                                compareValue: original,
                              }}
                              onChange={e => setUpdated(e.target.value)}
                            />
                          </FlexBox>
                        </FlexBox>
                      </Card.Content>
                    </Card>
                    <Spacer size={2} />

                    <Card color="secondary">
                      <Card.Header>
                        <Text variant="h2" kind="primary">
                          {'AlertDialogV3'}
                        </Text>
                      </Card.Header>
                      <Card.Content>
                        <ActionButton
                          onClick={handleConfirmV3}
                          title="Test AlertDialogV3"
                        />
                        <ActionButton
                          onClick={() =>
                            pleaseConfirmV2(
                              () => {
                                return new Promise(resolve => {
                                  console.log('saving...');
                                  setTimeout(() => {
                                    console.log('saved', count);
                                    resolve();
                                  }, 2000);
                                });
                              },
                              {
                                title: 'Test AlertDialogV2',
                                body: `Test body: ${count}`,
                                okTitle: 'OK',
                                cancelTitle: 'Cancel',
                              },
                            ).then()
                          }
                          title="Test AlertDialogV2"
                        />
                        <ActionButton
                          onClick={() => setCount(prev => prev + 1)}
                          title="Count"
                        />
                        <AlertDialogV3 isOpen={isOpen} isClosing={isClosing} />
                        <AlertDialogV2 />
                      </Card.Content>
                    </Card>
                    <Spacer size={2} />

                    <Card color="secondary">
                      <Card.Header>
                        <Text variant="h2" kind="primary">
                          {'Register all part types'}
                        </Text>
                      </Card.Header>
                      <Card.Content>
                        <DataFormV1
                          key={'test'}
                          id={'test'}
                          schema={testSchema}
                          data={{}}
                          configuration={titleFormConfiguration}
                          relatedData={{}}
                          mode={'edit'}
                          onDataChanged={setPerson}
                        />
                      </Card.Content>
                    </Card>
                    <Spacer size={2} />

                    <Card color="secondary">
                      <Card.Header>
                        <Text variant="h2" kind="primary">
                          {'Codelist selectors'}
                        </Text>
                      </Card.Header>
                      <Card.Content>
                        <TestCodeListSelectors />
                      </Card.Content>
                    </Card>
                    <Spacer size={2} />
                    <Card color="secondary">
                      <Card.Header>
                        <Text variant="h2" kind="primary">
                          {'Manifestation titles'}
                        </Text>
                      </Card.Header>
                      <Card.Content>
                        {previewTitles ? (
                          <Layout pl={2}>
                            <Layout horizontal justifyContent={'space-between'}>
                              <Layout pt={2}>
                                <FieldLabel label={'Titles'} required />
                              </Layout>
                              <IconButton
                                icon={'DropDown'}
                                onClick={() => setPreviewTitles(!previewTitles)}
                              />
                            </Layout>
                            <Layout>
                              {(titleInfoRef.current.titles ?? []).map(
                                (mt, mi) => {
                                  const title = makeTitleWithParallelTitles(mt);
                                  return (
                                    <React.Fragment key={mi}>
                                      <Text variant="body1">{title}</Text>
                                      {mt.subTitles ? (
                                        <Layout pl={2}>
                                          {mt.subTitles.map((st, idx) => (
                                            <Text key={idx} variant="body1">
                                              {makeTitleWithParallelTitles(st)}
                                            </Text>
                                          ))}
                                        </Layout>
                                      ) : null}
                                    </React.Fragment>
                                  );
                                },
                              )}
                            </Layout>
                          </Layout>
                        ) : (
                          <Layout sx={{transform: 'translateY(-40px)'}}>
                            <Layout
                              horizontal
                              adjustRight
                              sx={{transform: 'translateY(40px)'}}>
                              <IconButton
                                icon={previewTitles ? 'DropDown' : 'ChevronUp'}
                                onClick={() => setPreviewTitles(!previewTitles)}
                              />
                            </Layout>

                            <DataFormV1
                              key={'titles'}
                              id={'titles'}
                              schema={titleSchema}
                              data={titleInfoRef.current}
                              configuration={titleFormConfiguration}
                              relatedData={{}}
                              mode={'edit'}
                              onDataChanged={(data: TitleInfo) => {
                                console.log(data);
                                titleInfoRef.current = data;
                              }}
                            />
                          </Layout>
                        )}
                      </Card.Content>
                    </Card>
                    <Spacer size={2} />
                    <Card colorx={ColorPalette.offWhite}>
                      <Card.Header>
                        <Text variant="h2" kind="primary">
                          {'Agent wizard'}
                        </Text>
                      </Card.Header>
                      <Card.Content>
                        {(
                          [
                            Concept.person,
                            // , Concept.corporation, Concept.event
                          ] as const
                        ).map(agentType => (
                          <>
                            <EditAgentWizardDialog
                              key={agentType}
                              agentType={agentType}
                              suggestion={''}
                              isOpen={!!isWizardDialogOpen[agentType]}
                              onCancel={() =>
                                setIsWizardDialogOpen(prev => ({
                                  ...prev,
                                  [agentType]: false,
                                }))
                              }
                              onSave={() =>
                                Promise.resolve({} as NationalAgent)
                              }
                              onDelete={() => Promise.resolve()}
                            />
                            <ActionButton
                              title={`Register ${agentType}`}
                              onClick={() =>
                                setIsWizardDialogOpen(prev => ({
                                  ...prev,
                                  [agentType]: true,
                                }))
                              }
                            />
                            <Spacer size={1} />
                          </>
                        ))}
                        {/*
                        <Thesaurus
                          data={useThesaurus('thema').children || []}
                          values={['DC', 'C']}
                          onNodeSelect={(
                            event: SyntheticEvent,
                            nodeIds: string | string[],
                          ) => console.log('Selected', nodeIds)}
                        />
                        */}
                      </Card.Content>
                    </Card>
                    <Spacer size={2} />
                    <Card color="secondary">
                      <Card.Header>
                        <Text variant="h2" kind="primary">
                          {'ML Forslag'}
                        </Text>
                      </Card.Header>
                      <Card.Content>
                        <Link to="/metadata/3c32868c-69ae-48c5-9674-42273022f090?manifestationId=9b0726fd-bca2-4bf9-b331-507e4e41ff1d&mock=true">
                          Metadata med ML forslag
                        </Link>
                      </Card.Content>
                    </Card>
                    <Spacer size={2} />
                    <Card color="secondary">
                      <Card.Header>
                        <Text variant="h2" kind="primary">
                          {'Manifestation with file'}
                        </Text>
                      </Card.Header>
                      <Card.Content>
                        <Link to="/metadata/86fd9983-ba19-4e11-95a4-7f2f456d9dea?manifes[…]0a034e6-c7fb-4d22-b635-3215e8aad852&isbn=9788249220663">
                          Metadata med filer
                        </Link>
                      </Card.Content>
                    </Card>
                    <Spacer size={2} />
                    <Card color="secondary">
                      <Card.Header>
                        <Text variant="h2" kind="primary">
                          {'Snacks'}
                        </Text>
                      </Card.Header>
                      <Card.Content>
                        <Button onClick={() => infoSnack('infoSnack')}>
                          Info snack
                        </Button>
                        <Button
                          onClick={() =>
                            infoSnack({
                              message: 'infoSnack',
                              linkLabel: 'link label',
                              navigationLink: '',
                            })
                          }>
                          Info snack with link
                        </Button>
                        <Button onClick={() => successSnack('successSnack')}>
                          Success snack
                        </Button>
                        <Button
                          onClick={() =>
                            successSnack({
                              message: 'Forslag til endringer fra ML',
                              linkLabel: 'Se forslag',
                              navigationLink:
                                '/metadata/3c32868c-69ae-48c5-9674-42273022f090?manifestationId=537bbdb2-5cb5-4a70-8d0f-497570ff3837&mock=true',
                            })
                          }>
                          Success snack with link
                        </Button>
                        <Button onClick={() => warningSnack('warningSnack')}>
                          Warning snack
                        </Button>
                        <Button onClick={() => errorSnack('errorSnack')}>
                          Error snack
                        </Button>
                      </Card.Content>
                    </Card>
                    <Spacer size={2} />
                    <Card color="secondary">
                      <Card.Header>
                        <Text variant="h2" kind="primary">
                          {'Icons'}
                        </Text>
                      </Card.Header>
                      <Card.Content>
                        <Icons />
                        <Layout horizontal>
                          <ProgressCircle value={67} size="small" />
                          <ProgressCircle value={67} />
                          <ProgressCircle value={67} size="large" />
                        </Layout>
                      </Card.Content>
                    </Card>
                  </GridLayout.Item>
                  <GridLayout.Item>
                    {/* <Card color="secondary">
                      <Card.Header>
                        <Text variant="h2" kind="primary">
                          {'Preview actor table'}
                        </Text>
                      </Card.Header>
                      <Card.Content>
                        <Table
                          key={'test'}
                          header={null}
                          schema={testSchema}
                          data={[person, person2]}
                          configuration={tableConfig}
                        />
                      </Card.Content>
                    </Card>
                    <Spacer size={2} /> */}
                    {/* Person preview (as editing below) */}
                    <Card colorx={ColorPalette.offWhite}>
                      <Card.Header>
                        <Layout horizontal flex={1}>
                          <Text variant="h2" kind="primary">
                            {'Preview all part types'}
                          </Text>
                          <Layout horizontal>
                            <CheckboxField
                              fieldId="Vertical groups"
                              name="Vertical groups"
                              boxLabel="Vertical gropups"
                              value={
                                previewConfig.layout.groups?.[
                                  'internalComments'
                                ].direction === 'vertical'
                              }
                              onChange={val => {
                                setPreviewConfig(conf => ({
                                  ...conf,
                                  layout: {
                                    ...conf.layout,
                                    groups: {
                                      ...conf.layout.groups,
                                      // info: {
                                      //   direction: val
                                      //     ? 'vertical'
                                      //     : 'horizontal',
                                      // },
                                      internalComments: {
                                        direction: val
                                          ? 'vertical'
                                          : 'horizontal',
                                      },
                                    },
                                  },
                                }));
                              }}
                            />
                            <CheckboxField
                              fieldId="Exclude main form"
                              name="Exclude main form"
                              boxLabel="Exclude main form"
                              value={
                                !!previewConfig.nameVariant?.excludeMainForm
                              }
                              onChange={val => {
                                setPreviewConfig(conf => ({
                                  ...conf,
                                  nameVariant: {
                                    ...conf.nameVariant,
                                    excludeMainForm: val,
                                  },
                                }));
                              }}
                            />
                          </Layout>
                        </Layout>
                      </Card.Header>
                      <Card.Content>
                        <Preview
                          key={'test'}
                          schema={testSchema}
                          data={person}
                          configuration={previewConfig}
                        />
                      </Card.Content>
                    </Card>
                    <Spacer size={2} />

                    {/* Test edit - all required */}
                    <Card color="secondary">
                      <Card.Header>
                        <Text variant="h2" kind="primary">
                          {`Registrer ${testEntityName} - all required`}
                        </Text>
                      </Card.Header>
                      <Card.Content>
                        <DataFormV1
                          key={'test'}
                          id={'test'}
                          schema={testAllRequiredSchema}
                          data={{}}
                          dataTimestamp={timestamp}
                          configuration={titleFormConfiguration}
                          relatedData={{}}
                          mode={'edit'}
                          onDataChanged={() => undefined}
                        />
                        <Button
                          title="reset"
                          onClick={() => setTimestamp(Date.now())}
                        />
                      </Card.Content>
                    </Card>
                    <Spacer size={2} />

                    {/* Test edit - all 'should' */}
                    <Card color="secondary">
                      <Card.Header>
                        <Text variant="h2" kind="primary">
                          {`Registrer ${testEntityName} - all "should"`}
                        </Text>
                      </Card.Header>
                      <Card.Content>
                        <DataFormV1
                          key={'test'}
                          id={'test'}
                          schema={testAllShouldSchema}
                          data={{}}
                          configuration={titleFormConfiguration}
                          relatedData={{}}
                          mode={'edit'}
                          onDataChanged={() => undefined}
                        />
                      </Card.Content>
                    </Card>
                    <Spacer size={2} />

                    {/* Test edit - all optional */}
                    <Card color="secondary">
                      <Card.Header>
                        <Text variant="h2" kind="primary">
                          {`Registrer ${testEntityName} - all optional`}
                        </Text>
                      </Card.Header>
                      <Card.Content>
                        <DataFormV1
                          key={'test'}
                          id={'test'}
                          schema={testAllOptionalSchema}
                          data={{}}
                          configuration={titleFormConfiguration}
                          relatedData={{}}
                          mode={'edit'}
                          onDataChanged={() => undefined}
                        />
                      </Card.Content>
                    </Card>
                    <Spacer size={2} />

                    {/* <Card color="secondary">
                      <Card.Header>
                        <Text variant="h2" kind="primary">
                          {'Metadata fields - editable'}
                        </Text>
                      </Card.Header>
                      <Card.Content>
                        <Layout horizontal adjustLeft>
                          <Avatar
                            src={`https://images.unsplash.com/photo-1527980965255-d3b416303d12?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80`}
                          />
                          <Avatar src={`https://images.unsplash.com/bad`} />
                        </Layout>
                        <Spacer size={3} />
                        <FieldLayout>
                          <FieldLayout horizontal>
                            <TextField
                              name={'edit-isbn'}
                              label={'isbn'}
                              value={isbn}
                              width="8em"
                              type="digits"
                              onChange={e => setIsbn(e.target.value)}
                            />
                            <TextField
                              name={'edit-no'}
                              label={'no'}
                              value={no}
                              width="3em"
                              type="digits"
                              onChange={e => setNo(e.target.value)}
                            />
                            <YearPickerField
                              name={'edit-year'}
                              label={'year'}
                              value={year}
                              width="6em"
                              onChange={setYear}
                            />
                            <CheckboxField
                              name={'edit-isDate'}
                              label={'dato'}
                              boxLabel="Dato"
                              value={isDate}
                              onChange={setIsDate}
                            />
                          </FieldLayout>
                          <DatePickerField
                            name="edit-salesDate"
                            label="sales date"
                            required
                            width="8em"
                            value={forSalesDate}
                            onChange={setForSalesDate}
                          />
                          <TextField
                            label="Original title"
                            name="edit-originalTitle"
                            required={false}
                            placeholder="WorkWithExpressions title"
                            type="text"
                          />
                          <SingleValueListField<string>
                            label="Subtitle"
                            addLabel="Add subtitle"
                            name="edit-subtitle"
                            required={false}
                            values={subtitles}
                            getValueKey={(idx) => getSubtitleKey(idx)}
                            renderValue={(idx, value, focusableId) => (
                              <TextField
                                focusableId={focusableId}
                                name={getAuthorKey(idx)}
                                value={value}
                                required={false}
                                placeholder="Subtitle"
                                type="text"
                                onChange={e =>
                                  replaceSubtitle(idx, e.target.value)
                                }
                              />
                            )}
                            {...subtitlesOp}
                          />

                          <SingleValueListField<LinkedValue>
                            label="author"
                            addLabel="Add author"
                            name="edit-author"
                            required={false}
                            values={authors}
                            getValueKey={(idx) => getAuthorKey(idx)}
                            renderValue={(idx, value, focusableId) => (
                              <LinkedField
                                focusableId={focusableId}
                                name={getAuthorKey(idx)}
                                link={value}
                                required={false}
                                onChange={val =>
                                  val
                                    ? replaceAuthor(idx, val)
                                    : deleteAuthor && deleteAuthor(idx)
                                }
                              />
                            )}
                            onDeleteValue={idx =>
                              deleteAuthor && deleteAuthor(idx)
                            }
                            {...authorsOp}
                          />

                          <CodeListField
                            label="format"
                            value={format}
                            name="edit-format"
                            options={productFormCodeList.codes}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            onChange={setFormat}
                          />

                          <CodeListField
                            label="language"
                            value={languages}
                            cardinality="multiple"
                            name="edit-languages"
                            options={languageCodeList.codes}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            onChange={setLanguages}
                          />

                          <RadioButtonsField
                            label="gender"
                            value={gender}
                            name="edit-gender"
                            options={genderCodeList.codes}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            onChange={setGender}
                          />

                          <TextAreaField
                            label="Biography"
                            name="edit-biography"
                            required={false}
                            maxLength={1000}
                            value={
                              'Abid Raja er en norsk politiker (V) og advokat. Høsten 2013 ble han valgt inn på Stortinget for Akershus Venstre. Han var kultur- og likestillingsminister i Erna Solbergs regjering fra 24. januar 2020 til 14. oktober 2021. Abid Raja er en norsk politiker (V) og advokat. Høsten 2013 ble han valgt inn på Stortinget for Akershus Venstre. Han var kultur- og likestillingsminister i Erna Solbergs regjering fra 24. januar 2020 til 14. oktober 2021.'
                            }
                          />
                        </FieldLayout>
                      </Card.Content>
                    </Card>
                    <Spacer size={2} /> */}
                    {/* <Card color="secondary">
                      <Card.Header>
                        <Text variant="h2" kind="primary">
                          {'Metadata fields - readonly'}
                        </Text>
                      </Card.Header>
                      <Card.Content>
                        <FieldLayout>
                          <FieldLayout horizontal>
                            <TextField
                              name={'isbn'}
                              label={'isbn'}
                              value={isbn}
                              readonly
                              width="8em"
                              type="digits"
                              onChange={e => setIsbn(e.target.value)}
                            />
                            <TextField
                              name={'no'}
                              label={'no'}
                              value={no}
                              readonly
                              width="3em"
                              type="digits"
                              onChange={e => setNo(e.target.value)}
                            />
                            <YearPickerField
                              name={'year'}
                              label={'year'}
                              value={year}
                              readonly
                              width="6em"
                              onChange={setYear}
                            />
                          </FieldLayout>
                          <DatePickerField
                            name="salesDate"
                            label="sales date"
                            value={forSalesDate}
                            readonly
                            required
                            width="8em"
                            onChange={setForSalesDate}
                          />
                          <SingleValueListField<string>
                            label="Subtitle"
                            name="subtitle"
                            required={false}
                            values={[
                              'Subtitle-1',
                              'Subtitle-2 Subtitle-2 Subtitle-2 Subtitle-2 ',
                            ]}
                            readonly
                            getValueKey={(idx) => getSubtitleKey(idx)}
                            renderValue={(
                              idx,
                              value,
                              focusableId,
                              readonly,
                            ) => (
                              <TextField
                                focusableId={focusableId}
                                name={getAuthorKey(idx)}
                                value={value}
                                required={false}
                                readonly={readonly}
                                placeholder="Subtitle"
                                type="text"
                                onChange={e =>
                                  replaceSubtitle(idx, e.target.value)
                                }
                              />
                            )}
                            {...subtitlesOp}
                          />
                          <SingleValueListField<string>
                            label="Subtitle - none"
                            name="subtitle"
                            required={false}
                            values={[]}
                            readonly
                            getValueKey={(idx) => getSubtitleKey(idx)}
                            renderValue={(
                              idx,
                              value,
                              focusableId,
                              readonly,
                            ) => (
                              <TextField
                                focusableId={focusableId}
                                name={getAuthorKey(idx)}
                                value={value}
                                required={false}
                                readonly={readonly}
                                placeholder="Subtitle"
                                type="text"
                                onChange={e =>
                                  replaceSubtitle(idx, e.target.value)
                                }
                              />
                            )}
                            {...subtitlesOp}
                          />

                          <SingleValueListField<LinkedValue>
                            label="author"
                            name="author"
                            required={false}
                            values={authors}
                            readonly
                            getValueKey={(idx) => getAuthorKey(idx)}
                            renderValue={(
                              idx,
                              value,
                              focusableId,
                              readonly,
                            ) => (
                              <LinkedField
                                focusableId={focusableId}
                                name={getAuthorKey(idx)}
                                link={value}
                                readonly={readonly}
                                required={false}
                                onChange={val =>
                                  val
                                    ? replaceAuthor(idx, val)
                                    : deleteAuthor && deleteAuthor(idx)
                                }
                              />
                            )}
                            {...authorsOp}
                          />

                          <CodeListField
                            label="format"
                            value={format}
                            name="format"
                            options={productFormCodeList.codes}
                            readonly
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            onChange={setFormat}
                          />

                          <CodeListField
                            label="format - null"
                            value={null}
                            name="format"
                            options={productFormCodeList.codes}
                            readonly
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            onChange={setFormat}
                          />

                          <CodeListField
                            label="language"
                            value={languages}
                            cardinality="multiple"
                            name="languages"
                            options={languageCodeList.codes}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            onChange={setLanguages}
                          />

                          <CodeListField
                            label="language - none"
                            value={[]}
                            cardinality="multiple"
                            readonly
                            name="languages"
                            options={languageCodeList.codes}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            onChange={setLanguages}
                          />
                        </FieldLayout>
                      </Card.Content>
                    </Card>
                    <Spacer size={2} /> */}
                    <Card color="secondary">
                      <Card.Header>
                        <Text variant="h2" kind="primary">
                          {'Tool buttons'}
                        </Text>
                      </Card.Header>
                      <Card.Content>
                        <GridLayout>
                          <GridLayout.Item>
                            <TextArea
                              value={
                                'Abid Raja er en norsk politiker (V) og advokat. Høsten 2013 ble han valgt inn på Stortinget for Akershus Venstre. Han var kultur- og likestillingsminister i Erna Solbergs regjering fra 24. januar 2020 til 14. oktober 2021. Abid Raja er en norsk politiker (V) og advokat. Høsten 2013 ble han valgt inn på Stortinget for Akershus Venstre. Han var kultur- og likestillingsminister i Erna Solbergs regjering fra 24. januar 2020 til 14. oktober 2021.'
                              }
                              readonly></TextArea>
                          </GridLayout.Item>
                          <GridLayout.Item>
                            <TextArea
                              value={
                                'Abid Raja er en norsk politiker (V) og advokat. Høsten 2013 ble han valgt inn på Stortinget for Akershus Venstre. Han var kultur- og likestillingsminister i Erna Solbergs regjering fra 24. januar 2020 til 14. oktober 2021.'
                              }
                              readonly></TextArea>
                          </GridLayout.Item>
                        </GridLayout>
                      </Card.Content>
                    </Card>
                    <Spacer size={2} />
                    <Card color="secondary">
                      <Card.Header>
                        <Text variant="h2" kind="primary">
                          {'Localized formatting'}
                        </Text>
                      </Card.Header>
                      <Card.Content>
                        <LocalizedFormatting />
                      </Card.Content>
                    </Card>
                  </GridLayout.Item>
                </GridLayout>
              </Scene.Content>
              {/* {searchContextValue.linkedFieldContext ? (

            ) : null}
            {searchContextValue.linkedFieldContext ? (
              <Scene.RightPanel>
                <LinkedValueSearch />
              </Scene.RightPanel>
            ) : null} */}
            </Scene>
          </LocalizationProvider>
        </LinkedValueSearchContextProvider>
      </ThesaurusesGate>
    </CodeListsGate>
  );
};
